import { Fragment } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import esMessages from "locales/es";
import enMessages from "locales/en";
import deMessages from "locales/de";
import frMessages from "locales/fr";
import itMessages from "locales/it";
import plMessages from "locales/pl";
import ukMessages from "locales/uk";
import caMessages from "locales/ca";
import { getCurrentLanguageAndInstanceCode } from "services/instances";

const messages = {
  es: esMessages,
  en: enMessages,
  de: deMessages,
  fr: frMessages,
  it: itMessages,
  pl: plMessages,
  uk: ukMessages,
  ca: caMessages,
};

const ConnectedIntlProvider = ({ children }) => {
  const [lang] = getCurrentLanguageAndInstanceCode();

  return (
    /**
     * This key value is to force react to create a new instance an re-rendering everything
     * @see https://github.com/yahoo/react-intl/issues/243#issuecomment-166030664
     */
    <IntlProvider
      key={lang}
      locale={lang}
      textComponent={Fragment}
      messages={messages[lang]}
    >
      {children}
    </IntlProvider>
  );
};

ConnectedIntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ConnectedIntlProvider;
