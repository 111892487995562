import gql from "graphql-tag";

const allPatternsQuery = gql`
  query AllPatternsQuery($instanceIds: [ID], $saleable: Boolean) {
    patterns(products_Instances: $instanceIds, saleable: $saleable) {
      edges {
        node {
          id
          name
          altName
        }
      }
    }
  }
`;

export default allPatternsQuery;
