import classNames from "classnames/bind";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/new-product-label.scss";

const NewProductLabel = ({ isNotAbsolute = false }) => (
  <div
    className={classNames({
      "new-label": true,
      "new-label-absolute": !isNotAbsolute,
    })}
  >
    <span className="new-label--text">
      <FormattedMessage
        id="ProductCard.new"
        description="label for product if it's a new product"
        defaultMessage="New"
      />
    </span>
  </div>
);

NewProductLabel.propTypes = {
  isNotAbsolute: PropTypes.bool,
};

export default NewProductLabel;
