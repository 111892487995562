import { useQuery } from "react-apollo";
import VALUES_FILTERS_QUERY from "../query";
import {
  getCurrentInstanceId,
  getCurrentLanguageAndInstanceCode,
} from "services/instances";
import { useEffect } from "react";
import { useState } from "react";

const useGetValuesForFilter = () => {
  const [lang] = getCurrentLanguageAndInstanceCode();

  const [colorsFilterValues, setColorFilterValues] = useState([]);
  const [compositionsFilterValues, setCompositionsFilterValues] = useState([]);
  const [desingFilterValues, setDesingFilterValues] = useState([]);
  const [brandsFilterValues, setBrandsFilterValues] = useState([]);

  const { data } = useQuery(VALUES_FILTERS_QUERY, {
    variables: {
      intanceID: getCurrentInstanceId(),
      lang: lang,
    },
  });

  useEffect(() => {
    if (data) {
      const {
        filtersByInstance: { colors, compositions, design, brands },
      } = data;
      setColorFilterValues(colors);
      setCompositionsFilterValues(compositions);
      setDesingFilterValues(design);
      setBrandsFilterValues(brands);
    }
  }, [data]);

  return { colorsFilterValues, compositionsFilterValues, desingFilterValues, brandsFilterValues };
};

export default useGetValuesForFilter;
