import { defineMessages } from "react-intl";

export const BRAND_TYPE_MSG = defineMessages({
  COATING: {
    id: "WhyChooseUsPage.Brands.typeBrand.coating",
    description: "subtitle type brand coating for list of brands",
    defaultMessage: "Coating",
  },
  SUPPORT: {
    id: "WhyChooseUsPage.Brands.typeBrand.support",
    description: "subtitle type brand support for list of brands",
    defaultMessage: "Support",
  },
  BRAND: {
    id: "WhyChooseUsPage.Brands.typeBrand.brand",
    description: "subtitle type brand brand for list of brands",
    defaultMessage: "Brand",
  },
});
