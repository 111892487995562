import { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import officesByInstanceQuery from "../query";
import { getCurrentInstanceId } from "services/instances";

const useGetOfficesByInstance = () => {
  const [officesList, setOfficesList] = useState([]);

  const { data } = useQuery(officesByInstanceQuery, {
    variables: {
      orderdBy: "",
      instance: getCurrentInstanceId(),
    },
  });

  useEffect(() => {
    if (data) {
      const {
        offices: { edges },
      } = data;
      setOfficesList(edges);
    }
  }, [data]);

  return { officesList };
};

export default useGetOfficesByInstance;
