import {connect} from "react-redux";
import SearchByProduct from "../index";
import {setPatternId} from "modules/stores/WhereToBuyPage/actions";

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPatternId: patternId => dispatch(setPatternId(patternId))
  };
};

const SearchByProductContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByProduct);

export default SearchByProductContainer;