import PropTypes from "prop-types";
import "./styles/LocationInputs.scss";
import FormErrorMessage from "utils/components/FormErrorMessage";
import CityField from "./components/CityField";
import { CountryField } from "./components/CountryField";
import RegionField from "./components/RegionField";

const LocationInputs = ({ countryId, regionId }) => {
  let countryIdentifier =
    typeof countryId === "object" ? countryId.value : countryId;
  let regionIdentifier =
    typeof regionId === "object" ? regionId.value : regionId;

  return (
    <div className="bg-white d-flex flex-column">
      <div className="mt-3">
        <CountryField className="border border-dark rounded" />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="countryId" />
      </div>
      <div className="mt-3 border border-dark rounded">
        <RegionField countryId={countryIdentifier} />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="regionId" />
      </div>
      <div className="mt-3">
        <CityField isCustomStyles regionId={regionIdentifier} />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="cityId" />
      </div>
    </div>
  );
};

LocationInputs.propTypes = {
  field: PropTypes.object,
  countryId: PropTypes.object || PropTypes.string,
  regionId: PropTypes.object || PropTypes.string,
};

export default LocationInputs;
