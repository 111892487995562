import PropTypes from 'prop-types';
import "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoresGoogleMap/components/StoreInfoWindowContent/styles/StoreInfoWindowContent.scss";

const StoreInfoWindowContent = ({title, address, phone, email}) => (
    <div className="store-info-window">
      <h5 className="store-info-window__header">
        {title}
      </h5>
      <div className="store-info-window__body">
        {
          address &&
          <h6 className="">
            {address}
          </h6>
        }
        {
          phone &&
          <p className="mb-2">
            {phone}
          </p>
        }
        {
          email &&
          <p className="mb-0">
            <a 
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer">
              {email}
            </a>
          </p>
        }
      </div>
    </div>
);

StoreInfoWindowContent.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
};

export default StoreInfoWindowContent;
