import gql from "graphql-tag";

const singleFlatpageQuery = gql`
  query SingleFlatpageQuery($slug: String) {
    flatpage (slug: $slug) {
      id
      title
      content
    }
  }
`;

export default singleFlatpageQuery;