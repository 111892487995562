import { getInstanceLink } from "services/instances";
import {
  INSTANCE_LATAM,
  INSTANCE_MEXICO,
  INSTANCE_USA,
  INSTANCE_MARINE,
  INSTANCE_RESOURCES,
  INSTANCE_EUROPE,
} from "services/instances/constants";

export const ETHICAL_LINE_PDF_URL =
  "https://static.spradling.group/uploads/documents/None/file/CODIGO_ETICA.pdf";

export const INSTANCES_ITEMS = [
  {
    link: {
      href: getInstanceLink(INSTANCE_LATAM),
    },
    name: INSTANCE_LATAM,
  },
  {
    link: {
      href: getInstanceLink(INSTANCE_MEXICO),
    },
    name: "MÉXICO",
  },
  {
    link: {
      href: getInstanceLink(INSTANCE_USA),
    },
    name: INSTANCE_USA,
  },
  {
    link: {
      href: getInstanceLink(INSTANCE_MARINE),
    },
    name: INSTANCE_MARINE,
  },
  {
    link: {
      href: getInstanceLink(INSTANCE_RESOURCES),
    },
    name: INSTANCE_RESOURCES,
  },
  {
    link: {
      href: "https://spradling.eu/",
      target: "_blank",
    },
    name: INSTANCE_EUROPE,
  },
];
