import { FormattedMessage } from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import useGetOfficesByInstance from "./hooks/useGetOfficesByInstance";
import "./styles/CallUs.scss";

const CallUs = () => {
  const { officesList } = useGetOfficesByInstance();

  return (
    <div className="call-us">
      <div className="container">
        <TitleUnderlined
          hTag={H2_TAG}
          className="text-size-h1 text-weight-bold mb-5"
        >
          <FormattedMessage
            id="CallUs.title"
            description="Call us section title"
            defaultMessage="Llámanos"
          />
        </TitleUnderlined>

        <div className="row justify-content-start">
          {officesList.map(({ node }, index) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5 text-center text-md-left text-lg-left"
                key={index}
              >
                <div className="instance-node-card">
                  <h3 className="instance-node-card__name text-size-h5">
                    {node.name}
                  </h3>

                  <strong>
                    <p className="instance-node-card__phone mb-0 gray">
                      {`${node.city.name}, ${node.city.country.name}`}
                    </p>
                  </strong>

                  <p className="instance-node-card__phone mb-0 gray">
                    <strong className="mr-1">
                      <FormattedMessage
                        id="OfficesList.cardAddress"
                        description="Address in office card at offices list"
                        defaultMessage="Dirección"
                      />
                      :
                    </strong>
                    <p className="d-inline">{node.address}</p>
                  </p>

                  <p className="instance-node-card__phone mb-0 gray">
                    <strong className="mr-1">
                      <FormattedMessage
                        id="CallUs.phone"
                        description="Telephone label in call us section"
                        defaultMessage="Teléfono"
                      />
                      :
                    </strong>
                    <p className="d-inline">{node.phone}</p>
                  </p>

                  <p className="instance-node-card__email mb-0 gray">
                    <strong className="mr-1">
                      <FormattedMessage
                        id="CallUs.email"
                        description="Email label in call us section"
                        defaultMessage="Email"
                      />
                      :
                    </strong>
                    <a
                      className="a-inherit"
                      href={`mailto:${node.email}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {node.email}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CallUs;
