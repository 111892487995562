import {connect} from "react-redux";
import HeaderAuthBox from "../index";
import {closeAuthMenu, setAuthModalContext, toggleAuthModal} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer,
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAuthMenu: () => dispatch(closeAuthMenu()),
    toggleAuthModal: () => dispatch(toggleAuthModal()),
    setAuthModalContext: context => dispatch(setAuthModalContext(context))
  };
};

const HeaderAuthBoxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderAuthBox);

export default HeaderAuthBoxContainer;
