import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "react-apollo";
import Select from "react-select";
import servicesAndPatternsQuery from "./query";
import { getCurrentInstanceId } from "services/instances";
import { customStyles } from "components/AuthModal/components/SignUpForm/components/CityField/styles";

const placeholders = defineMessages({
  loading: {
    id: "ServicesAndPatternsField.loading",
    description: "Placeholder for autocomplete select when data is loading",
    defaultMessage: "loading services/products...",
  },
  choose: {
    id: "ServicesAndPatternsField.label",
    description: "Placeholder for autocomplete select when data is loaded",
    defaultMessage: "Select a service/product",
  },
});

const ServicesAndPatternsField = ({ name, value, errors, handleOnChange }) => {
  const intl = useIntl();
  const { loading, error, data } = useQuery(servicesAndPatternsQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
    },
  });
  const placeholder = intl.formatMessage(
    !loading ? placeholders.choose : placeholders.loading
  );

  const handleProcessedData = ({ patterns, services }) => [
    {
      label: "Services",
      options: services.edges.map(
        ({ node: { id, name: label, slug: value, __typename } }) => ({
          id,
          label,
          value,
          __typename,
        })
      ),
    },
    {
      label: "Patterns",
      options: patterns.edges.map(
        ({ node: { id, name: label, slug: value, __typename } }) => ({
          id,
          label,
          value,
          __typename,
        })
      ),
    },
  ];

  if (error) return null;

  return (
    <>
      <Select
        styles={customStyles}
        id={name}
        name={name}
        placeholder={placeholder}
        isLoading={loading}
        value={value}
        options={data && handleProcessedData(data)}
        onChange={(value) => handleOnChange(name, value)}
      />
      <span className="form-error-message form-error-message--white">
        {errors && errors[name]?.value}
      </span>
    </>
  );
};

ServicesAndPatternsField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default ServicesAndPatternsField;
