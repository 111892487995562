import gql from "graphql-tag";

const relatedPostsQuery = gql`
  query RelatedPostsQuery ($postId: String, $first: Int) {
    posts(relatedWith: $postId, first: $first) {
      edges {
        node {
          id
          title
          slug
          listingImageThumbSmall
          instances {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default relatedPostsQuery;
