import PropTypes from 'prop-types';
import {useQuery} from "react-apollo";
import {singlePatternQuery} from "modules/products/ProductDetailsPage/queries";
import {ANGLO_SAXON_UNITS} from "modules/products/ProductDetailsPage/constants/unitSystems"
import ProductCard from "modules/products/ProductListPage/components/ProductList/components/ProductCard"
import {getCurrentInstanceId} from "services/instances";

function DetailPattern({patternSlug}) {
  const {loading, data} = useQuery(singlePatternQuery, {
    variables: {
      slug: patternSlug,
      instanceIds: [getCurrentInstanceId()],
      unitSystem: ANGLO_SAXON_UNITS
    }
  });

  return (
    <ProductCard 
      loading={loading} 
      pattern={data?.pattern}/>
  )
}

DetailPattern.propTypes = {
  patternSlug: PropTypes.string.isRequired
};

export default DetailPattern