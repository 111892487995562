import {connect} from "react-redux";
import ProductHeaderActions from "../index";
import {setCartId} from "components/Header/actions"

const mapStateToProps = (state) => {
  return {
    cartId: state.headerReducer.cartId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartId: cartId => dispatch(setCartId(cartId))
  };
};

const ProductHeaderActionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductHeaderActions);

export default ProductHeaderActionsContainer;