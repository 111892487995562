import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import "./styles/DetailedStoreBox.scss";
import {Query} from "react-apollo";
import singleStoreQuery from "./query";
import BusinessHours from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/components/BusinessHours";
import LoadingMessage from "utils/components/LoadingMessage";

export default function DetailedStoreBox({store, onClose}) {
  return (
    <div className="detailed-store-box">
      <div className="mb-4">
        <button
            type="button"
            onClick={onClose}
            className="btn btn-outline-dark">
          <i className="fa fa-chevron-left"/>&nbsp;
          <FormattedMessage
              id="DetailedStoreBox.goBack"
              description="go back button for the store details in the store map"
              defaultMessage="Volver"/>
        </button>
      </div>
      <div className="store-details mb-4">
        <h5>{store.name}</h5>
        <p className="gray">
          {store.address}<br/>
          {store.phoneNumbers}<br/>
          {
            store.website &&
            <>
              <span><a href={store.website} target="_blank" rel="noopener noreferrer">{store.website}</a></span>
            </>
          }
        </p>
      </div>
      <Query
          query={singleStoreQuery}
          variables={{
            id: store.id
          }}>
        {
          ({loading, data}) => {

            if (loading)
              return (
                  <div className="text-center">
                    <FormattedMessage
                        id="DetailedStoreBox.loading"
                        description="Loading message for DetailedStoreBox component"
                        defaultMessage="Cargando información..."/>
                  </div>
              );

            if (data) {
              const {store} = data;

              let sectorsByMarkets = [];
              if (store) {
                const {sectors} = store;
                sectorsByMarkets = sectors.edges.map(sector => ({
                  market: sector.node.market.name,
                  sector: sector.node,
                })).reduce((r, a) => {
                  r[a.market] = r[a.market] || [];
                  r[a.market].push(a);
                  return r;
                }, Object.create(null));
              }

              return (
                  <>
                    <BusinessHours
                        businessHours={store.businessHours.edges.map(businessHour => businessHour.node)}/>
                    <div className="store-sectors">
                      {
                        Object.keys(sectorsByMarkets).map((key, index) => (
                            <div
                                key={index}
                                className="store-sectors--market">
                              <h4>
                                {key}
                              </h4>
                              <hr/>
                              <p>
                                {
                                  sectorsByMarkets[key].map((sector, index) => (
                                      <span key={index}>{sector.sector.name}<br/></span>
                                  ))
                                }
                              </p>
                            </div>
                        ))
                      }
                    </div>
                  </>
              );
            }

            return <LoadingMessage/>
          }
        }
      </Query>
    </div>
  );
}

DetailedStoreBox.propTypes = {
  onClose:  PropTypes.func.isRequired,
  store:  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    phoneNumbers: PropTypes.string,
    website: PropTypes.string,
    sectors: PropTypes.shape({
      edges: PropTypes.array
    }),
    businessHours: PropTypes.shape({
      edges: PropTypes.array
    })
  }),
}