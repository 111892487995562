import IconCatalogue from 'modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/IconCatalogue';
import PropTypes from "prop-types";
import { getPatternData } from './constants/getPatternData';
import './styles/OrderDetailRow.scss';

const OrderDetailRow = ({ item }) => {
	const { patternColor, patternImage, patternType, patternStock } = getPatternData(item)

	return (
		<div className='d-flex py-3 py-md-0 flex-wrap align-items-center mt-3 px-4 border border-2'>
			<div className='col-12 col-md-3 d-flex flex-column align-items-center p-0'>
				<h4 className='col-12 text-center text-md-left p-0 h-100'>{patternColor}</h4>
			</div>
			<div className='col-12 col-md-3 p-0 p-md-4'>
				{
					item.pattern !== null
						? <div className='pl-4 pt-2 mx-auto container-image-row'>
							<IconCatalogue />
						</div>
						: <div className='mx-auto reference-image' style={{ backgroundImage: `url("${patternImage}")`
						}}>
						</div>
				}
			</div>
			<div className='col-12 mt-3 mt-md-0 text-center col-md-3'>
				<h6 className='fw-bold'>{patternType}
				</h6>
				<span>{patternStock}</span>
			</div>
			<h5 className='col-12 col-md-3 text-center'>1</h5>
		</div>
	)
};

OrderDetailRow.propTypes = {
  item: PropTypes.object
};

export default OrderDetailRow;
