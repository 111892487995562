import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl"
import classNames from "classnames";
import {Link} from "react-router-dom"
import {SAMPLES_CART} from "routes"
import {isMARINE, getTranslatedLink} from "services/instances";
import "./styles/PatternAddedToast.scss"

export default function PatternAddedToast({image, name}) {
  return (
      <div className="pattern-added-toast d-flex align-items-center p-3">
        <div className="pattern-added-toast__image mr-4">
          {image}
        </div>
        <div className="pattern-added-toast__content">
          <p>
            <FormattedMessage
                id="PatternAddedToast.text"
                defaultMessage="<strong>{patternName}</strong> fue agregado al carrito!"
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  patternName: name
                }}/>
          </p>
          <Link
              to={getTranslatedLink(SAMPLES_CART)}
              className={classNames({
                "btn btn-dark btn-sm": true,
                "btn-prussian-blue": isMARINE()
              })}>
            <FormattedMessage
                id="PatternAddedToast.btnLabel"
                defaultMessage="Ver el carrito"/>
          </Link>
        </div>
      </div>
  )
}

PatternAddedToast.propTypes = {
  name: PropTypes.string,
  image:  PropTypes.string
}