import { useState, useEffect } from 'react';

const useShakeAnimation = (currentCharacteristic) => {
  const [shakeAnimation, setShakeAnimation] = useState(false);

  useEffect(() => {
    if (currentCharacteristic && currentCharacteristic.showCharacteristicInfo) {
      setShakeAnimation(true);
      setTimeout(() => {
        setShakeAnimation(false);
      }, 1000);
    } 
  }, [currentCharacteristic])

  return { shakeAnimation }
};

export default useShakeAnimation;