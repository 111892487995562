import {connect} from "react-redux";
import AuthModal from "../index";
import {toggleAuthModal, setAuthModalContext} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: () => dispatch(toggleAuthModal()),
    setAuthModalContext: context => dispatch(setAuthModalContext(context))
  };
};

const AuthModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthModal);

export default AuthModalContainer;
