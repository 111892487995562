const EXPIRED_COOKIE = "temporal-cookie=stay_in_spradling; max-age=0; path=/";

const VALIDATION_INSTANCES_USA = (currentInstance) =>
  currentInstance === "sm" ||
  currentInstance === "rc" ||
  currentInstance === "us";
const VALIDATION_INSTANCE_MX = (currentInstance) => currentInstance === "mx";
const VALIDATION_INSTANCE_LA = (currentInstance) => currentInstance === "la";

export const HANDLE_SHOW_LOCATION_MODAL = (existCookie, currentInstance) => {
  if (!existCookie) {
    return true;
  }

  if (existCookie && VALIDATION_INSTANCES_USA(currentInstance)) {
    return false;
  }

  if (!existCookie && VALIDATION_INSTANCES_USA(currentInstance)) {
    return true;
  }

  if (
    existCookie &&
    (VALIDATION_INSTANCE_MX(currentInstance) ||
      VALIDATION_INSTANCE_LA(currentInstance))
  ) {
    document.cookie = EXPIRED_COOKIE;
    return true;
  }

  return false;
};
