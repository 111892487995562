import { FormattedMessage } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import { useQuery } from "@apollo/react-hooks";
import homeSlidersQuery from "modules/Home/components/HomeSlider/query";
import banner from "../utils/firstSection/banner.jpg";
import logo from "../utils/firstSection/logo.svg";
import { getCurrentInstanceId, isLangEs } from "services/instances";
import { HOME_SLIDER_TAG } from "modules/Home/components/HomeSlider/constants";
import nextArrow from "modules/about/EcosensePage/components/EcoSustainableReports/img/right-arrow.png";
import prevArrow from "modules/about/EcosensePage/components/EcoSustainableReports/img/left-chevron.png";
import { useMemo } from "react";
import useMediaQuery from "utils/hooks/useMediaQuery";

const sectionStyle = {
  backgroundImage: `url(${banner})`,
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

// eslint-disable-next-line react/prop-types
const FistElement = ({ isSpanish }) => (
  <div className="container-fluid p-0 text-center" style={sectionStyle}>
    <div className="row align-items-center h-100 mx-0">
      <div className="col-lg-12 col-md-12 col-sm-12 ">
        <img
          src={logo}
          alt="Logo"
          className="img-fluid mb-4"
          style={{ width: "300px", maxWidth: "80%", height: "auto" }}
        />
        {!isSpanish && (
          <p className="eco-banner-title text-white fw-normal mt-2 mb-2">
            <FormattedMessage
              id="EcosensePage.v2.Banner.title"
              defaultMessage="Our ongoing commitment:"
            />
          </p>
        )}
        <p className="eco-banner-subtitle">
          <FormattedMessage
            id="EcosensePage.v2.Banner.description"
            defaultMessage="SUSTAINABILITY AT SPRADLING {registeredTrademark}"
            values={{
              registeredTrademark: (
                <sup
                  style={{
                    fontSize: "1rem",
                    top: "-.9em",
                    "@media (minWidth: 768px)": {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                  ®
                </sup>
              ),
            }}
          />
        </p>
      </div>
    </div>
  </div>
);

export const FirstSection = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { data } = useQuery(homeSlidersQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      tag: HOME_SLIDER_TAG.SUSTAINABILITY_REPORTS,
    },
  });

  const slides = useMemo(() => {
    if (data?.homeSliders?.edges?.length) {
      return [data.homeSliders.edges[0].node, { id: "FIRST_ID_ELEMENT" }];
    }
    return [];
  }, [data]);

  return (
    <>
      <Carousel
        className="custom-indicators-sustainable-slider sustainable-reports--slider"
        touch
        dragging
        nextLabel=""
        prevLabel=""
        nextIcon={
          <img
            className="sustainable--slider-next-arrow"
            src={nextArrow}
            alt="next-slider-arrow"
            width={28}
          />
        }
        prevIcon={
          <img
            className="sustainable--slider-prev-arrow"
            src={prevArrow}
            alt="prev-slider-arrow"
            width={28}
          />
        }
      >
        {slides.map((slide, i) => (
          <Carousel.Item key={`key_${slide.id}`}>
            {i === 1 ? (
              <FistElement isSpanish={isLangEs} />
            ) : (
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="bottom-caroussel-bckg"
                  src={slide.imageThumb}
                  alt={`${slide.title}`}
                  style={{
                    maxHeight: "450px",
                    width: "auto",
                  }}
                />

                <div className="bottom-caroussel-text">
                  <div className="col-lg-12 col-md-12 col-sm-12 px-5">
                    <span
                      className="bottom-caroussel-title"
                      dangerouslySetInnerHTML={{ __html: slide.title }}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 bottom-caroussel-desc">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: slide.description,
                      }}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <a
                      className="btn btn-outline-white rounded-pill btn-lg"
                      style={{
                        bottom: "50px",
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        border: "2px solid white",
                        marginTop: "20px",
                      }}
                      href={
                        slide?.linkMobile && isMobile
                          ? slide?.linkMobile
                          : slide.link
                      }
                      target={slide.linkTarget}
                    >
                      {slide.buttonText}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
