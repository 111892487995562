import { PROSEAT_CHARACTERTISTICS } from "./characteristics"
import { FormattedMessage } from "react-intl";

// CHARACTERISTICS IMAGES
import antimicrobialConfort from "../components/CharacteristicDetails/img/antimicrobial-confort.jpg"
import foamWaterPour from "../components/CharacteristicDetails/img/foam-water-pour.jpg";
import ventilation from "../components/CharacteristicDetails/img/3d-ventilation.jpg";
import meshWhite from "../components/CharacteristicDetails/img/mesh-white.jpg";
import dielectricLogo from "../components/CharacteristicDetails/img/dielectric-logos.jpg";
import underCoverWhite from "../components/CharacteristicDetails/img//Undercover_White.jpg";

const CHARACTERISTIC_1_INFO = {
  id: PROSEAT_CHARACTERTISTICS[0].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[0].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[0].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic1"
      descriptionMessage="Foam Lamination with Film description"
      values={{
        br: <br />
      }}
    />
  ),
}

const CHARACTERISTIC_2_INFO = {
  id: PROSEAT_CHARACTERTISTICS[1].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[1].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[1].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic2"
      descriptionMessage="Clear Seat Film description"
    />
  ),
}

const CHARACTERISTIC_3_INFO = {
  id: PROSEAT_CHARACTERTISTICS[2].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[2].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[2].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic3"
      descriptionMessage="Antimicrobial Biocide Comfort Foam description"
      values={{
        br: <br />
      }}
    />
  ),
  img: {
    src: antimicrobialConfort,
    imgWidth: 130,
    imgHeight: 100,
    imgClassName: "antimicrobial-biocide-comfort-img"
  },
  hideBtnSewing: true
}

const CHARACTERISTIC_4_INFO = {
  id: PROSEAT_CHARACTERTISTICS[3].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[3].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[3].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic4"
      descriptionMessage="Antimicrobial Biocide Reticulated Foam description"
      values={{
        br: <br />
      }}
    />
  ),
  img: {
    src: foamWaterPour,
    imgWidth: 100,
    imgHeight: 130
  },
  hideBtnSewing: true
}

const CHARACTERISTIC_5_INFO = {
  id: PROSEAT_CHARACTERTISTICS[4].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[4].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[4].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic5"
      descriptionMessage="3D Ventilation Panel description"
    />
  ),
  img: {
    src: ventilation,
    imgWidth: 100,
    imgHeight: 100
  },
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_6_INFO = {
  id: PROSEAT_CHARACTERTISTICS[5].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[5].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[5].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic6"
      descriptionMessage="Marine Board or Rotocast Plastic description"
      values={{
        string: (...chunks) => <span>{`"${chunks}"`}</span>,
        br: <br />
      }}
    />
  ),
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_7_INFO = {
  id: PROSEAT_CHARACTERTISTICS[6].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[6].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[6].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic7"
      descriptionMessage="Ventilation Fabric description"
    />
  ),
  images: [
    {
      key: "ventilation-fabric--img-1",
      imageName: "Mesh White",
      src: meshWhite,
      imgWidth: 100,
      imgHeight: 100
    },
    {
      key: "ventilation-fabric--img-2",
      imageName: "Undercover White",
      src:  underCoverWhite,
      imgWidth: 100,
      imgHeight: 100
    }
  ],
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_8_INFO = {
  id: PROSEAT_CHARACTERTISTICS[7].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[7].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[7].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic8"
      descriptionMessage="Side Cushion Ventilation description"
    />
  ),
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_9_INFO = {
  id: PROSEAT_CHARACTERTISTICS[8].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[8].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[8].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic9"
      descriptionMessage="Marine Quality Vinyl description"
      values={{
        br: <br />
      }}
    />
  ),
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_10_INFO = {
  id: PROSEAT_CHARACTERTISTICS[9].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[9].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[9].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic10"
      descriptionMessage="UV Stable Thread description"
      values={{
        itemList: (...chunks) => <li className="ml-3">{chunks}</li>,
        centerText: (...chunks) => <p className="text-center p-0 m-0">{chunks}</p>,
        br: <br />
      }}
    />
  ),
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_11_INFO = {
  id: PROSEAT_CHARACTERTISTICS[10].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[10].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[10].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic11"
      descriptionMessage="Dielectric Logos description"
      values={{
        string: (...chunks) => <span>{`"${chunks}"`}</span>,
        br: <br />
      }}
    />
  ),
  img: {
    src: dielectricLogo,
    imgWidth: 100,
    imgHeight: 100
  },
  hideBtnPink: true,
  hideBtnSewing: true
}

const CHARACTERISTIC_12_INFO = {
  id: PROSEAT_CHARACTERTISTICS[11].key,
  characteristicNumber: PROSEAT_CHARACTERTISTICS[11].characteristicNumber,
  title: PROSEAT_CHARACTERTISTICS[11].title,
  description: (
    <FormattedMessage 
      id="Proseat.characteristic12"
      descriptionMessage="Dielectric Quilting Patterns description"
      values={{
        string: (...chunks) => <span>{`"${chunks}"`}</span>,
        br: <br />
      }}
    />
  ),
  hideBtnPink: true,
  hideBtnSewing: true
}

export const CHARACTERISTIC_GENERAL_INFO = {
  [CHARACTERISTIC_1_INFO.id]: CHARACTERISTIC_1_INFO,
  [CHARACTERISTIC_2_INFO.id]: CHARACTERISTIC_2_INFO,
  [CHARACTERISTIC_3_INFO.id]: CHARACTERISTIC_3_INFO,
  [CHARACTERISTIC_4_INFO.id]: CHARACTERISTIC_4_INFO,
  [CHARACTERISTIC_5_INFO.id]: CHARACTERISTIC_5_INFO,
  [CHARACTERISTIC_6_INFO.id]: CHARACTERISTIC_6_INFO,
  [CHARACTERISTIC_7_INFO.id]: CHARACTERISTIC_7_INFO,
  [CHARACTERISTIC_8_INFO.id]: CHARACTERISTIC_8_INFO,
  [CHARACTERISTIC_9_INFO.id]: CHARACTERISTIC_9_INFO,
  [CHARACTERISTIC_10_INFO.id]: CHARACTERISTIC_10_INFO,
  [CHARACTERISTIC_11_INFO.id]: CHARACTERISTIC_11_INFO,
  [CHARACTERISTIC_12_INFO.id]: CHARACTERISTIC_12_INFO
}

export const initialCharacteristicInfoState = {
  showCharacteristicInfo: false,
  characteristicDetail: CHARACTERISTIC_GENERAL_INFO[PROSEAT_CHARACTERTISTICS[0].key]
}