import { FormattedMessage } from "react-intl";
import water from "../../../utils/water.jpeg";

export const CIndex4 = () => {
  return (
    <div className="container-fluid upper-slider-container">
      <div className="slider-3-bg-container">
        <div className="slider3-bg-svg" />
      </div>
      <div className="row d-flex justify-content-center position-relative h-100">
        <div className="col-lg-9 col-md-10 col-sm-10">
          <div className="row d-flex justify-content-between px-3 slide-height align-items-center h-100">
            <div className="col-lg-6 col-sm-12">
              <div
                className="row d-flex justify-content-center mt-4"
                style={{ zIndex: 999 }}
              >
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-5 g-2 text-center px-5 px-lg-0 px-xl-5">
                  <span className="slider1-title1 mr-1">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.four.title.1"
                      defaultMessage="CONSERVING "
                    />
                  </span>
                  <span className="slider1-title2">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.four.title.2"
                      defaultMessage="WATER"
                    />
                  </span>
                </div>
                <div
                  className="col-sm-12 pt-0 mb-0 mt-3 mt-lg-2 mt-xl-5"
                  style={{ marginTop: -20 }}
                >
                  <div className="row">
                    <div className="col-sm-12 slider2-desc pe-lg-5">
                      <span>
                        <FormattedMessage
                          id="EcosensePage.v2.Evolving.ConservingWater.description"
                          defaultMessage="<strong>100%</strong> of wastewater is treated, and nearly <strong>85%</strong> of our
                          generated waste is repurposed each year."
                          values={{
                            strong: (...chunks) => <strong>{chunks}</strong>,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-lg-block">
              <div className="row justify-content-center align-items-center h-100 slider1-image-container">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={water}
                    className="img-fluid"
                    alt="water"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
