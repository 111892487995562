import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Fragment } from "react";
import { isRESOURCES, isUSA } from "services/instances";
import { SUBINSTANCES_USA } from "./constants";
import "./styles/HeaderSubInstancesUsa.scss";

const HeaderSubInstancesUsa = ({ isDesktop }) => (
  <div className="header-subinstances">
    <div
      className={classNames({
        container: true,
        "m-md-0 p-md-0": isDesktop && (isUSA() || isRESOURCES()),
        "mx-3": !isDesktop,
      })}
    >
      <div className="row justify-content-start align-items-center">
        {SUBINSTANCES_USA.map(({ instance, to, is_actived, icon }, i) => (
          <Fragment key={`key_${instance}`}>
            <a
              href={to}
              className={classNames({
                "d-flex align-items-center header-subinstances-menu-button": true,
                "header-subinstances-menu-button--active": is_actived,
              })}
            >
              {instance}
              <img
                className="header-subinstances-icon ml-2"
                src={icon}
                alt={instance}
                title={instance}
              />
            </a>
            {i < SUBINSTANCES_USA.length - 1 && <span className="separator" />}
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

HeaderSubInstancesUsa.propTypes = {
  isDesktop: PropTypes.bool,
};

export default HeaderSubInstancesUsa;
