import PropTypes from "prop-types";
import ProductColorsList from "modules/products/ProductListPage/components/ProductList/components/ProductColorsList";

export default function ProductsWithColors({ patterns, loading }) {
  return (
    <>
      {loading || !patterns || patterns.length === 0
        ? [1, 2, 3, 4, 5, 6].map((item) => (
            <div key={item} className="mb-5">
              <ProductColorsList />
            </div>
          ))
        : patterns.map(({ node }) => (
            <div key={node.id} className="mb-5">
              <ProductColorsList pattern={node} />
            </div>
          ))}
    </>
  );
}

ProductsWithColors.propTypes = {
  patterns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

ProductsWithColors.defaultProps = {
  loading: false,
};
