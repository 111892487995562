import _ from "lodash";
import FiltersBox from "modules/products/ProductListPage/components/FiltersBar/components/FiltersBox";
import { FormattedMessage } from "react-intl";
import { Query } from "react-apollo";
import marketsSimpleQuery from "components/Header/components/HeaderMainMenu/query";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import { getCurrentInstanceId } from "services/instances";
import CheckboxFilter from "modules/products/ProductListPage/components/FiltersBar/components/CheckboxFilter";
import { QUERY_PARAM_SECTOR } from "modules/products/ProductListPage/constants";
import LoadingMessage from "utils/components/LoadingMessage";

export default function SectorsFilter() {
  return (
    <FiltersBox
      title={
        <FormattedMessage
          id="SectorsFilter.title"
          description="Sectors filter box title"
          defaultMessage="Sectores"
        />
      }
    >
      <Query
        query={marketsSimpleQuery}
        fetchPolicy={FETCH_POLICY_CACHE_AND_NETWORK}
        variables={{
          instanceIds: [getCurrentInstanceId()],
          saleable: true,
        }}
      >
        {({ data }) => {
          if (data) {
            // TODO implement this same simplified lodash filtering method in the header products menu
            const { markets } = data;

            const marketsWithPatterns = _.filter(
              markets.edges,
              (edge) =>
                _.sumBy(
                  edge.node.sectors.edges,
                  (edge) => edge.node.patternSet.totalCount
                ) > 0
            );

            const optGroups = marketsWithPatterns.map((market) => ({
              title: market.node.name,
              options: _.filter(
                market.node.sectors.edges,
                (edge) => edge.node.patternSet.totalCount > 0
              ).map((sector) => [
                sector.node.id,
                sector.node.name,
                QUERY_PARAM_SECTOR,
              ]),
            }));

            return <CheckboxFilter optGroups={optGroups} />;
          }

          return <LoadingMessage />;
        }}
      </Query>
    </FiltersBox>
  );
}
