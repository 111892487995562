import PropTypes from 'prop-types';
import TranslatedLink from "routes/components/TranslatedLink";
import {BRAND_DETAILS} from "routes"
import BrandCell from './components/BrandCell';

const RelatedBrandCell = ({ brand: {
  title,
  slug: brandSlug,
  logo,
  isVisible
}}) => (
  <>
    { isVisible
      ? <TranslatedLink 
          name={BRAND_DETAILS}
          params={{brandSlug}}>
            <BrandCell title={title} logo={logo}/>
        </TranslatedLink>
      : <BrandCell title={title} logo={logo}/>
    }
  </>
)

RelatedBrandCell.propTypes = {
  brand: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    logo: PropTypes.string,
    isVisible: PropTypes.bool,
  }).isRequired
};

export default RelatedBrandCell