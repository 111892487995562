import { useEffect, useRef } from "react";
import { useState } from "react";

export const useSlide = (testimonialsItems, isDesktop) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [numSlidersperPage, setNumSlidersperPage] = useState(3);
  const flickingRef = useRef(null);


  const handlePrev = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : 0));
    if (currentSlide > 0) {
      flickingRef?.current?.prev();
    }
  };

  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev < Math.ceil(testimonialsItems.length / (numSlidersperPage)) - 1 ? prev + 1 : prev
    );
  };

  const handleHoveredItem = (value) => {
    setHoveredItem(value);
  }

  const handleDotClick = (index) => {
    flickingRef?.current?.moveTo(index)
  };

  useEffect(() => {
    setNumSlidersperPage(isDesktop ? 3 : 1)
  }, [isDesktop])

  return { 
    hoveredItem,
    currentSlide,
    numSlidersperPage,
    handleNext,
    handlePrev,
    handleHoveredItem,
    handleDotClick,
    flickingRef,
    setCurrentSlide
   }
}