import { apolloClient } from "apollo/client";
import NoMatch from "app/components/NoMatch";
import RedirectToInstance from "app/components/RedirectToInstance";
import RenderRoutes from "app/components/RenderRoutes";
import classNames from "classnames/bind";
import { ApolloProvider } from "react-apollo";
import TagManager from "react-gtm-module";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { reduxStore } from "redux/store";
import {
  getCurrentInstanceId,
  isLATAM,
  isMARINE,
  isMEXICO,
  isRESOURCES,
} from "services/instances";
import useHubSpotTracking from "utils/hooks/useHubSpotTracking";
import useMediaQuery from "utils/hooks/useMediaQuery";
import {
  GOOGLE_TAG_MANAGER_BY_INSTANCE,
  INSTANCE_TRACKING_CODE_HUBSPOT,
} from "./constants";
import "./styles/SPGlobalApp.scss";
import CollectionContextProvider from "modules/products/QuickshipPage/paramSlugPage/context/CollectionContextProvider";
import "./styles/SPGlobalApp.scss";
import { useEffect } from "react";

function SPGlobalApp() {
  useHubSpotTracking({
    hubId: INSTANCE_TRACKING_CODE_HUBSPOT[getCurrentInstanceId()],
  });

  const isDesktop = useMediaQuery("(min-width: 960px)");
  const hideHeaderCartSection = isLATAM() || isMEXICO() || isMARINE();

  useEffect(() => {
    TagManager.initialize(
      GOOGLE_TAG_MANAGER_BY_INSTANCE[getCurrentInstanceId()]
    );
  }, []);

  return (
    <div
      className={classNames({
        "spglobal-app": true,
        "spglobal-app--no-header-cart": hideHeaderCartSection,
        "spglobal-app--sm-rc": isDesktop && (isMARINE() || isRESOURCES()),
      })}
    >
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={reduxStore}>
          <CollectionContextProvider>
            <BrowserRouter>
              <Switch>
                {/* WARNING: for SPRADLING EUROPE redirect to old/current site */}
                {process.env.REACT_APP_ENV === "production" && (
                  <Route
                    exact
                    path="/:language([a-z]{2})-eu*"
                    component={SPEurope}
                  />
                )}
                <Route exact path="/" component={RedirectToInstance} />
                <Route
                  path="/:language([a-z]{2})-:instance([a-z]{2})"
                  component={RenderRoutes}
                />
                <Route component={NoMatch} />
              </Switch>
            </BrowserRouter>
          </CollectionContextProvider>
        </ReduxProvider>
      </ApolloProvider>
    </div>
  );
}

// WARNING: for SPRADLING EUROPE redirect to old/current site
function SPEurope() {
  window.location.replace("https://spradling.eu");
  return null;
}

export default SPGlobalApp;
