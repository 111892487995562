import gql from "graphql-tag";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

const projectsQuery = gql`
    query ProjectsQuery (
        $first: Int,
        $last: Int,
        $afterCursor: String,
        $beforeCursor: String,
        $instanceIds: [ID],
    ) {
        projects(
            first: $first,
            last: $last,
            after: $afterCursor,
            before: $beforeCursor,
            instances: $instanceIds,
            sector_Market_Instances: $instanceIds
        ) {
            totalCount
            pageInfo {
                ...PageInfoFragment
            }
            edges {
                node {
                    id
                    name
                    slug
                    client
                    sector {
                        id
                        name
                        market {
                            id
                            name
                        }
                    }
                    listingImageThumbSmall
                }
            }
        }
    }
    ${pageInfoFragment}
`;

export default projectsQuery;