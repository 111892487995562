import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

const patternsQuery = gql`
  query PatternsQuery(
    $first: Int
    $productFirst: Int
    $last: Int
    $afterCursor: String
    $beforeCursor: String
    $orderBy: String
    $instanceIds: [ID]
    $predominantColor: String
    $sectorIds: [ID]
    $design: String
    $composition: String
    $outdoor: Boolean
    $topcoat: ID
    $backingDescription: ID
    $search: String
    $noBiocides: Boolean
    $formaldehydeFree: Boolean
    $noFlameRetardants: Boolean
    $antifungal: Boolean
    $prop65Compliant: Boolean
    $polycarbonateResinSystem: Boolean
    $ecosense: Boolean
    $softside: Boolean
    $prostock: Boolean
    $compassHb: Boolean
    $intagliodesigns: Boolean
    $ipaCleanable: Boolean
    $bleachCleanable: Boolean
    $ab1998Compliant: Boolean
    $denimDyeResistant: Boolean
    $isAntistatic: Boolean
    $heatSealable: Boolean
    $sulfideStainResistant: Boolean
    $hydrolysis: Boolean
    $mildewResistant: Boolean
    $isAntibacterial: Boolean
    $hasNotHeavyMetals: Boolean
    $brandsIds: [ID]
    $saleable: Boolean
    $petFriendly: Boolean
    $slugs: String
    $sustainabilityProp65: Boolean,
    $sustainabilityReach: Boolean,
    $sustainabilityRohs: Boolean,
    $sustainabilityFrFreeIn: String,
    $sustainabilityMpFreeIn: String,
    $sustainabilityCytotoxicityIn: String,
    $sustainabilitySkinSafeSensitizationIn: String,
    $sustainabilityPhthalateFreeProductIn: String,
    $sustainabilitySkinSafeIrritationIn: String,
    $sustainabilityBackingProfile: Boolean,
    $sustainabilityBiobasedPvcBioAttributed: Boolean,
    $sustainabilityGreenguard: Boolean,
    $sustainabilityIndoorAirQuality: Boolean,
    $sustainabilityGlobalRecycledStandard: Boolean,
    $sustainabilityAPlusEmissions: Boolean,
    $sustainabilityAntimicrobialIn: String,
    $sustainabilityEpd: Boolean,
    $sustainabilityLeed: Boolean,
    $sustainabilityWell: Boolean,
  ) {
    patterns(
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
      orderBy: $orderBy
      search: $search
      products_Instances: $instanceIds
      products_PredominantColors_Contains: $predominantColor
      sectors_In: $sectorIds
      productcategorization_Design_Contains: $design
      productcategorization_Outdoor: $outdoor
      general_Topcoat_Id: $topcoat
      general_Composition_In: $composition
      backing_Description_Id: $backingDescription
      otherattributes_NoBiocides: $noBiocides
      otherattributes_FormaldehydeFree: $formaldehydeFree
      otherattributes_NoFlameRetardants: $noFlameRetardants
      otherattributes_Antifungal: $antifungal
      otherattributes_Prop65Compliant: $prop65Compliant
      otherattributes_PolycarbonateResinSystem: $polycarbonateResinSystem
      otherattributes_Ecosense: $ecosense
      otherattributes_Softside: $softside
      otherattributes_Prostock: $prostock
      otherattributes_CompassHb: $compassHb
      otherattributes_Intagliodesigns: $intagliodesigns
      otherattributes_IpaCleanable: $ipaCleanable
      otherattributes_BleachCleanable: $bleachCleanable
      otherattributes_Ab1998Compliant: $ab1998Compliant
      otherattributes_DenimDyeResistant: $denimDyeResistant
      otherattributes_IsAntistatic: $isAntistatic
      otherattributes_HeatSealable: $heatSealable
      otherattributes_SulfideStainResistant: $sulfideStainResistant
      otherattributes_Hydrolysis: $hydrolysis
      otherattributes_MildewResistant: $mildewResistant
      otherattributes_IsAntibacterial: $isAntibacterial
      otherattributes_HasNotHeavyMetals: $hasNotHeavyMetals
      otherattributes_PetFriendly: $petFriendly
      brands_In: $brandsIds
      saleable: $saleable
      slug_In: $slugs
      sustainability_Prop65: $sustainabilityProp65,
      sustainability_Reach: $sustainabilityReach,
      sustainability_Rohs: $sustainabilityRohs,
      sustainability_FrFree_In: $sustainabilityFrFreeIn,
      sustainability_MpFree_In: $sustainabilityMpFreeIn,
      sustainability_Cytotoxicity_In: $sustainabilityCytotoxicityIn,
      sustainability_SkinSafeSensitization_In: $sustainabilitySkinSafeSensitizationIn,
      sustainability_SkinSafeIrritation_In: $sustainabilitySkinSafeIrritationIn,
      sustainability_BackingProfile: $sustainabilityBackingProfile,
      sustainability_PhthalateFreeProduct_In: $sustainabilityPhthalateFreeProductIn,
      sustainability_BiobasedPvcBioAttributed: $sustainabilityBiobasedPvcBioAttributed,
      sustainability_Greenguard: $sustainabilityGreenguard,
      sustainability_IndoorAirQuality: $sustainabilityIndoorAirQuality,
      sustainability_GlobalRecycledStandard: $sustainabilityGlobalRecycledStandard,
      sustainability_APlusEmissions: $sustainabilityAPlusEmissions,
      sustainability_Epd: $sustainabilityEpd,
      sustainability_Leed: $sustainabilityLeed,
      sustainability_Well: $sustainabilityWell,
      sustainability_Antimicrobial_In: $sustainabilityAntimicrobialIn
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...SinglePatternFragment
          imageThumbSmall
          products(
            first: $productFirst
            instances: $instanceIds
            predominantColors_Contains: $predominantColor
          ) {
            totalCount
            edges {
              node {
                id
                code
                color
                predominantColors
                imageThumbSmall
                isNew
              }
            }
          }
        }
      }
    }
  }
  ${singlePatternFragment}
  ${pageInfoFragment}
`;

export default patternsQuery;
