import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import singlePatternPDFQuery from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadPatternPDFButton/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import { isMARINE, isUSA } from "services/instances";
import {
  ANGLO_SAXON_UNITS,
  INTERNATIONAL_UNITS,
} from "modules/products/ProductDetailsPage/constants/unitSystems";
import { createDownloadLog } from "modules/products/ProductDetailsPage/components/ProductSidebar/mutations";
import DownloadProtectModal from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadProtectModal";
import meQuery from "utils/queries/MeQuery";

export default function DownloadPatternPDFButton({
  authReducer: { isAuthenticated },
}) {
  const pattern = useContext(PatternContext);
  const [isOpen, setIsOpen] = useState(false);
  const { data: userData } = useQuery(meQuery);
  const [logDownload] = useMutation(createDownloadLog, {
    onError(error) {
      console.warn(error);
    },
  });
  const { loading, error, data } = useQuery(singlePatternPDFQuery, {
    variables: {
      id: pattern.id,
      unitSystem:
        isUSA() || isMARINE() ? ANGLO_SAXON_UNITS : INTERNATIONAL_UNITS,
    },
  });

  /**
   * Handle link click to calls registerSpecSheetDownloadMutation mutation
   */
  function handleClick() {
    logDownload({
      variables: {
        input: {
          email: userData && userData.me.email,
          patternId: pattern.id,
          resourceType: "specs-sheet",
          resourceUrl: data.pattern.pdfUrl,
        },
      },
    });
  }

  if (error || loading || !data.pattern || !data.pattern.pdfUrl) return null;

  return (
    <>
      {isAuthenticated ? (
        <a
          className="btn btn-outline-dark btn-lg btn-block"
          href={data.pattern.pdfUrl}
          rel="noopener noreferrer"
          target="_blank"
          onClick={handleClick}
        >
          <FormattedMessage
            id="DownloadPatternPDFButton.label"
            description="Product details download pdf button label"
            defaultMessage="Descargar PDF"
          />
          &nbsp;
          <i className="fa fa-file-pdf-o" />
        </a>
      ) : (
        <button
          className="btn btn-outline-dark btn-lg btn-block"
          onClick={() => setIsOpen(true)}
        >
          <FormattedMessage
            id="DownloadPatternPDFButton.label"
            description="Product details download pdf button label"
            defaultMessage="Descargar PDF"
          />
          &nbsp;
          <i className="fa fa-file-pdf-o" />
        </button>
      )}
      <DownloadProtectModal
        title={
          <FormattedMessage
            id="DownloadPatternPDFButton.downloadModalTitle"
            defaultMessage="Imprimir detalles de producto"
          />
        }
        subtitle={
          <FormattedMessage
            id="DownloadPatternPDFButton.downloadModalSubtitle"
            defaultMessage="Queremos mantenernos en contacto. Déjanos tus datos y descarga la versión para imprimir de ésta referencia"
          />
        }
        id="download-specs-sheet-form"
        isOpen={isOpen}
        patternId={pattern.id}
        resourceType="specs-sheet"
        resourceUrl={data.pattern.pdfUrl}
        toggle={() => setIsOpen(!isOpen)}
      />
    </>
  );
}

DownloadPatternPDFButton.propTypes = {
  authReducer: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
};
