import {FormattedMessage} from "react-intl";
import {Query} from "react-apollo";
import faqsQuery from "modules/contact/PQRPage/components/FAQ/query";
import {getCurrentInstanceId} from "services/instances";
import FAQItem from "modules/contact/PQRPage/components/FAQ/components/FAQItem";
import LoadingMessage from "utils/components/LoadingMessage";

const FAQ = () => (
    <div className="faq-section">
      <h2 className="text-center text-lg-left mb-4 mb-lg-5">
        <FormattedMessage
            id="FAQ.title"
            description="title of FAQ list"
            defaultMessage="Preguntas Frecuentes"/>
      </h2>
      <Query
          query={faqsQuery}
          variables={{
            instances: [getCurrentInstanceId()]
          }}>
        {({data}) => {

          if (data) {
            const {faqs} = data;

            return (
                <div className="faq-list">
                  {
                    faqs.edges.map((item, index) => (
                        <FAQItem
                            key={index}
                            question={item.node.question}
                            answer={item.node.answer}/>
                    ))
                  }
                </div>
            );
          }

          return <LoadingMessage/>
        }}
      </Query>
    </div>
);

export default FAQ;
