import { useState } from "react";
import PropTypes from "prop-types";
import iconSearch from "./img/icon-search.svg";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "../../constants/breakpoints";
import GlossarySectionWrapper from "./components/GlossarySectionWrapper";
import useShakeAnimation from "./hooks/useShakeAnimation";
import classNames from "classnames";
import "./styles/ProseatGlossary.scss";

const GlossaryText = () => <span className="proseat-glossary--text ml-2 pt-1">Glossary</span>

const ProseatGlossary = ({ currentCharacteristic }) => {
  const isDesktop = useMediaQuery(LG);
  const [showGlossary, setShowGlossary] = useState(false);
  const [glossaryText, setGlossaryText] = useState(false);
  const { shakeAnimation } = useShakeAnimation(currentCharacteristic);

  const handleToggleGlossary = () => setShowGlossary(!showGlossary);
  const handleChangeShowGlossaryText = (show) => setGlossaryText(show);
  
  return (
    <div className={classNames({
      "proseat-icon--container": !showGlossary,
      "proseat-icon--container-without-sticky": showGlossary,
      "col-12 d-flex mb-4 mb-xl-0": true
      })}>
      <div
        className={classNames({
          "icon-search text-white d-flex align-items-center justify-content-center justify-content-xl-start cursor-pointer": true,
          "icon-search--animation-shake": shakeAnimation && !showGlossary
        })}
        onClick={handleToggleGlossary}
        onMouseOver={() => handleChangeShowGlossaryText(true)}
        onMouseLeave={() => handleChangeShowGlossaryText(false)}
        >
        <img 
          src={iconSearch}
          alt="icon-search"
          className="ml-xl-4"
          width={isDesktop ? 28 : 22} 
          height={isDesktop ? 28 : 22}
        />
        { glossaryText && isDesktop && <GlossaryText /> }
        { !isDesktop && <GlossaryText /> }
      </div>

      {currentCharacteristic?.showCharacteristicInfo && isDesktop && 
        <div className="speech-bubble col-3">
          click here to see related terms...
        </div>}

      <GlossarySectionWrapper 
        showGlossary={showGlossary}
        currentCharacteristic={currentCharacteristic}
        handleToggleGlossary={handleToggleGlossary}
      />
    </div>
  )
}

ProseatGlossary.propTypes = {
  currentCharacteristic: PropTypes.object.isRequired,
};

export default ProseatGlossary;