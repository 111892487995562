import { FormattedMessage } from "react-intl";
import parking from "../../../utils/parking.jpeg";

export const CIndex1 = () => {
  return (
    <div className="container-fluid upper-slider-container position-relative">
      <div className="slider-1-bg-container">
        <div className="slider1-bg-svg" />
      </div>
      <div className="row d-flex justify-content-center position-relative align-items-center h-100">
        <div className="col-lg-9 col-md-10 col-sm-10">
          <div className="row d-flex justify-content-between px-3 align-items-center">
            <div className="col-lg-6 col-sm-12 mt-4 mt-md-5">
              <div
                className="row text-center"
                style={{ zIndex: 999 }}
              >
                <div className="col-sm-12 mt-2 d-flex justify-content-center g-2">
                  <span className="slider1-title1 mr-1">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.one.title.1"
                      defaultMessage="CARBON"
                    />
                  </span>
                  <span className="slider1-title2">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.one.title.2"
                      defaultMessage="NEUTRALITY"
                    />
                  </span>
                </div>
                <div className="col-sm-12">
                  <p className="slider1-desc">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.one.description"
                      defaultMessage="Both of our factories are certified carbon neutral since"
                    />
                  </p>
                </div>
                <div className="col-sm-12">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-5 col-lg-5 col-md-5 col-sm-5 d-flex flex-column justify-content-center align-items-center">
                      <span className="slider1-year">2014</span>
                      <span className="slider1-country">Costa Rica</span>
                    </div>

                    {/* vertical line */}
                    <div className="col-2 col-lg-2 col-md-2 col-sm-2  d-flex justify-content-center align-items-center">
                      <span className="slider1-line">|</span>
                    </div>

                    <div className="col-5 col-lg-5 col-md-5 col-sm-5 d-flex flex-column justify-content-center align-items-center">
                      <span className="slider1-year">2019</span>
                      <span className="slider1-country">Colombia</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-lg-block">
              <div className="row justify-content-center align-items-center h-100 slider1-image-container">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={parking}
                    className="img-fluid"
                    alt="plastic"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "100%", // Asegura que la imagen no supere la altura máxima del contenedor
                      objectFit: "cover", // Para asegurarte de que la imagen se ajuste al contenedor cuadrado
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
