import gql from "graphql-tag";

const singlePatternApprovedCleanersQuery = gql`
  query SinglePatternPDFQuery($id: ID!) {
    pattern(id: $id) {
      id
      approvedCleanersPdfUrl
    }
  }
`;

export default singlePatternApprovedCleanersQuery;
