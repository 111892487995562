import { Redirect } from "react-router-dom";
import useGoToIpLocation from "modules/Home/components/MessageGeolocation/hooks/useGoToIpLocation";
import "./styles/redirect-to-instance.scss";
import { EUROPE_INSTANCE_PAGE } from "modules/Home/components/MessageGeolocation/consts";
import { useEffect } from "react";

export const RedirectToInstance = () => {
  const { locationInstance } = useGoToIpLocation({ onlyInstance: false });

  useEffect(() => {
    /**
     * WARNING: this controls render from spradling.group/
     * PATH_BY_COUNTRY_CODE /modules/Home/components/MessageGeolocation/consts.js
     * component controls render from spradling.group/es-la
     *  */
    if (locationInstance && locationInstance.includes("https"))
      window.location.replace(EUROPE_INSTANCE_PAGE);
  }, [locationInstance]);

  return (
    <div className="hided-welcome-spinner">
      {!locationInstance || locationInstance.includes("https") ? (
        <div className="spinner-border" />
      ) : (
        <Redirect to={locationInstance[0]} />
      )}
    </div>
  );
};

export default RedirectToInstance;
