import PropTypes from "prop-types";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import QueryParams from "services/browser-history/QueryParams";
import arrayify from "services/browser-history/arrayify";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

export default function CheckboxFilter({ optGroups }) {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new QueryParams(location.search);

  /**
   * Handle onChange event of the checkboxes
   * @param event {Object}
   * @param queryParam {String}
   */
  function handleOnChange(event, queryParam) {
    const { value } = event.target;
    let values = arrayify(queryParams.find(queryParam));
    values = _.xor(values, [value]); // toggle the clicked one
    queryParams.update({
      [queryParam]: values,
    });
    queryParams.resetPagination();
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString,
    });
  }

  return (
    <>
      {optGroups.map((group, index) => (
        <div key={index} className="mb-3">
          {group.title && (
            <>
              <h6>{group.title}</h6>
              <hr />
            </>
          )}
          {group.options.map((option, index) => {
            const [key, value, queryParam] = option;
            const name = slugify(key, { lower: true });
            const uuid = uuidv4();

            return (
              <div key={index} className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={uuid}
                  name={name}
                  value={key}
                  checked={arrayify(queryParams.find(queryParam)).includes(key)}
                  onChange={(event) => handleOnChange(event, queryParam)}
                />
                <label className="form-check-label" htmlFor={uuid}>
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
}

CheckboxFilter.propTypes = {
  optGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.array,
    })
  ).isRequired,
};
