import PropTypes from "prop-types";
import { ModalBody, Modal, ModalHeader } from "reactstrap";

const SectionModal = ({
  headerImage,
  headerTitle,
  show,
  handleClose,
  image,
  mobileImage,
  title,
  content,
}) => {
  const handleClosing = () => {
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  return (
    <Modal
      isOpen={show}
      toggle={handleClosing}
      size="lg"
      fullscreen="md-down"
      centered
      scrollable
    >
      <ModalHeader
        className="px-3 bio-section-modal--header border-0"
        close={
          <button
            className="bio-section-modal--btn-close"
            onClick={handleClosing}
          ></button>
        }
      />
      <ModalBody className="p-0">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-lg-7 clearfix offset-lg-3 d-flex align-items-end mb-4 px-sm-1 px-4">
              <img
                src={headerImage}
                alt="..."
                className="float-start ml-md-5 ml-lg-0  mr-md-3"
              />
              <span
                className="modal-header-title text-start pr-md-4 pr-lg-0"
                style={{ color: "#76787B" }}
              >
                {headerTitle}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center px-5">
              {window.innerWidth > 768 ? (
                <img
                  src={image}
                  alt="Wood"
                  className="img-fluid"
                  style={{ height: "320px", width: "auto", objectFit: "cover" }}
                />
              ) : (
                <img
                  src={mobileImage}
                  alt="Wood"
                  className="img-fluid"
                  style={{ height: " 100%", width: "100%", objectFit: "cover" }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <h2 className="modal-title d-flex justify-content-center">
                {title}
              </h2>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12 p-4">
              <p className="modal-text">{content}</p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

SectionModal.propTypes = {
  headerImage: PropTypes.string,
  headerTitle: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default SectionModal;
