import {connect} from "react-redux";
import DownloadZippedImagesButton from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const DownloadZippedImagesButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadZippedImagesButton);

export default DownloadZippedImagesButtonContainer;