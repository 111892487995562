import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import "./styles/FiltersBar.scss";
import CheckboxFilter from "modules/products/ProductListPage/components/FiltersBar/components/CheckboxFilter";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  QUERY_PARAM_COMPOSITION,
  QUERY_PARAM_DESIGN,
  QUERY_PARAM_PREDOMINANT_COLOR,
  QUERY_PARAM_SEARCH,
} from "modules/products/ProductListPage/components/FiltersBar/constants/query_params";
import { Field, Form, Formik } from "formik";
import QueryParams from "services/browser-history/QueryParams";
import SectorsFilter from "modules/products/ProductListPage/components/FiltersBar/components/SectorsFilter";
import FiltersBox from "modules/products/ProductListPage/components/FiltersBar/components/FiltersBox";
import CharacteristicsFilter from "modules/products/ProductListPage/components/FiltersBar/components/CharacteristicsFilter";
import Navigation from "react-sticky-nav";
import { isRESOURCES } from "services/instances";
import useGetValuesForFilter from "./hooks/useGetValuesForFilter";
import { QUERY_PARAM_BRAND } from "../../constants";

const GROUP_QUICK_SHIP_PARAM = "?group=quickship"; // WARNING: hotfix for USA GROUP_QUICK_SHIP_PARAM
let timeout;

export default function FiltersBar() {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const queryParams = new QueryParams(location.search);

  const {
    colorsFilterValues,
    compositionsFilterValues,
    desingFilterValues,
    brandsFilterValues,
  } = useGetValuesForFilter();

  /**
   * Toggle open class to prevent z-index problem with the always-visible content in mobile devices
   */
  useEffect(() => {
    const stickyNav = document.getElementsByClassName("sticky-filters-bar")[0];
    if (isOpen) {
      clearTimeout(timeout);
      stickyNav.classList.add("sticky-filters-bar--open");
    } else
      timeout = setTimeout(() => {
        stickyNav.classList.remove("sticky-filters-bar--open");
      }, 250);
  }, [isOpen]);

  /**
   * Handle clean filters button click
   */
  function handleClearFiltersButtonClick() {
    history.push({
      pathname: location.pathname,
    });
  }

  /**
   * Toggle the filters bar visibility on mobile devices
   * @param event {Object}
   */
  function toggleFiltersBar() {
    setIsOpen(!isOpen);
  }

  /**
   * Handles search form submit
   * @param values {Object}
   */
  function handleSubmit(values) {
    const { query } = values;
    queryParams.update({
      [QUERY_PARAM_SEARCH]: query,
    });
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString,
    });
  }

  return (
    <Navigation className="sticky-filters-bar" disabled={true}>
      <Formik
        initialValues={{
          query: "",
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form id="filter-product-form" className="filters-bar-search-form">
            <div className="form-group">
              <div className="input-group mb-3">
                <FormattedMessage
                  id="FiltersBar.queryFieldLabel"
                  description="Query field label in the search form at FiltersBar component"
                  defaultMessage="Buscar por nombre"
                >
                  {(placeholder) => (
                    <Field
                      type="text"
                      name="query"
                      className="form-control"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
                <div className="input-group-append">
                  <button type="submit" className="input-group-text">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <hr />
      <button
        className="d-md-none btn btn-outline-dark mb-3 filter-mobile-button"
        onClick={toggleFiltersBar}
      >
        <i className="fa fa-chevron-left" />
        &nbsp;
        <FormattedMessage
          id="ProductListPage.filterButtonLabel"
          description="Filter button label in Product List Page"
          defaultMessage="Filtrar"
        />
      </button>
      <div
        className={classNames({
          "filters-bar": true,
          "filters-bar--open": isOpen,
        })}
      >
        <div className="row mb-3 justify-content-between align-items-center">
          <div
            onClick={handleClearFiltersButtonClick}
            className="col-7 col-md-auto order-md-1 filters-bar__remove-all"
          >
            <FormattedMessage
              id="FiltersBar.removeAllButton"
              description="Remove all button of Filters bar"
              defaultMessage="Remover todo"
            />
            <i className="fa fa-times-circle-o" />
          </div>
          <div className="col-auto order-md-0">
            <h2 className="h4 text-family-base mb-0 filters-bar-title">
              <FormattedMessage
                id="FiltersBar.title"
                description="Title of Filters bar"
                defaultMessage="Filtrar"
              />
            </h2>
            <button
              className="d-md-none btn btn-outline-dark filter-mobile-button"
              onClick={toggleFiltersBar}
            >
              <FormattedMessage
                id="FiltersBar.closeButtonLabel"
                description="Close button label in filters bar component"
                defaultMessage="Volver"
              />
              &nbsp;
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </div>
        <div className="filters-bar__content">
          <SectorsFilter />
          <FiltersBox
            title={
              <FormattedMessage
                id="FiltersBar.designFilterTitle"
                description="Title for design filter section at filters bar"
                defaultMessage="Diseño"
              />
            }
          >
            <CheckboxFilter
              optGroups={[
                {
                  title: null,
                  options: desingFilterValues.map(({ title, value }) => {
                    return [value, title, QUERY_PARAM_DESIGN];
                  }),
                },
              ]}
            />
          </FiltersBox>
          <FiltersBox
            title={
              <FormattedMessage
                id="FiltersBar.compositionFilterTitle"
                description="Title for composition filter section at filters bar"
                defaultMessage="Composición"
              />
            }
          >
            <CheckboxFilter
              optGroups={[
                {
                  title: null,
                  options: compositionsFilterValues.map(({ title, value }) => {
                    return [value, title, QUERY_PARAM_COMPOSITION];
                  }),
                },
              ]}
            />
          </FiltersBox>
          {/* WARNING: hotfix for USA GROUP_QUICK_SHIP_PARAM */}
          {location?.search !== GROUP_QUICK_SHIP_PARAM && (
            <FiltersBox
              title={
                <FormattedMessage
                  id="FiltersBar.predominantColorsTitle"
                  description="Title for predominant colors filter section at filters bar"
                  defaultMessage="Color"
                />
              }
            >
              <CheckboxFilter
                optGroups={[
                  {
                    title: null,
                    options: colorsFilterValues.map(({ title, value }) => {
                      return [value, title, QUERY_PARAM_PREDOMINANT_COLOR];
                    }),
                  },
                ]}
              />
            </FiltersBox>
          )}

          {!isRESOURCES() && <CharacteristicsFilter />}

          {!isRESOURCES() && (
            <FiltersBox
              title={
                <FormattedMessage
                  id="BrandsFilter.title"
                  description="Brands filter box title"
                  defaultMessage="Brands"
                />
              }
            >
              <CheckboxFilter
                optGroups={[
                  {
                    title: null,
                    options: brandsFilterValues.map(({ title, value }) => {
                      return [value, title, QUERY_PARAM_BRAND];
                    }),
                  },
                ]}
              />
            </FiltersBox>
          )}

          {/* <FiltersBox
            title={
              <FormattedMessage
                id="FiltersBar.sustainabilityFilterTitle"
                description="Title for sustainability filter section at filters bar"
                defaultMessage="Sustainability"
              />
            }
          >
            <CheckboxFilter
              optGroups={
                SUSTAINABILITY.map((group) => {
                  return {
                    title: group.title,
                    options: group.options.map((option) => {
                      const translateOptions = <FormattedMessage {...option} />

                      return [option.value, translateOptions, QUERY_PARAM_SUSTAINAILITY_SEARCH];
                    }),
                  }
                })
              }
            />
          </FiltersBox> */}
        </div>
      </div>
    </Navigation>
  );
}
