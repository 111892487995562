import gql from "graphql-tag";

const servicesQuery = gql`
  query Services($orderBy: String) {
    services(orderBy: $orderBy) {
      edges {
        node {
          id
          name
          slug
          description
          imageThumbMedium
          imageAlt
        }
      }
    }
  }
`;

export default servicesQuery;
