import gql from "graphql-tag";

const addToCartMutation = gql`
  mutation AddToCartMutation($input: AddToCartInput!) {
    addToCart(input: $input) {
      item {
        id
        quantity
        unitPrice
        objectId
        product {
          id
          color
          imageThumbSmall
          stock
          pattern {
            id
            name
          }
        }
        pattern {
          id
          name
          catalogStockNumber
        }
        cart {
          id
          created
          instance {
            id
            name
          }
        }
      }
    }
  }
`;

export default addToCartMutation;
