import { Suspense } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import "./styles/ProductLine.scss";
import Skeleton from "react-loading-skeleton";
import { MARKET_DETAILS } from "routes";
import { Link } from "react-router-dom";
import { isMARINE, getTranslatedLink } from "services/instances";

export default function ProductLine({ productLine }) {
  const to = productLine
    ? getTranslatedLink(MARKET_DETAILS, { marketSlug: productLine.slug })
    : "";

  return (
    <div className="product-line">
      <div className="product-line__photo mb-4">
        {productLine && (
          <Link to={to}>
            <Suspense fallback={<Skeleton width={100} />}>
              <img
                src={productLine.imageThumbMedium}
                alt={productLine.name}
                title={productLine.name}
                className="img-fluid"
              />
            </Suspense>
          </Link>
        )}
      </div>
      <h3 className="product-line__title text-weight-bold text-size-h4">
        <Link to={to}>
          {productLine ? `${productLine.name}` : <Skeleton width={120} />}
        </Link>
      </h3>
      <p className="product-line__description text-left text-size-small gray-light pl-4 pr-4">
        {productLine ? `${productLine.description}` : <Skeleton count={3} />}
      </p>
      <div className="product-line__button-container text-center">
        {productLine ? (
          <Link
            to={to}
            className={classNames({
              "btn btn-outline-dark": true,
              "btn-outline-prussian-blue": isMARINE(),
            })}
          >
            <FormattedMessage
              id="ProductLine.seeMoreButton"
              description="See more button label at product line component"
              defaultMessage="Quiero saber más"
            />
          </Link>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
    </div>
  );
}

ProductLine.defaultProps = {
  productLine: null,
};

ProductLine.propTypes = {
  productLine: PropTypes.shape({
    imageThumbMedium: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};
