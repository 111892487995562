import {connect} from "react-redux";
import HeaderSearchCircleButton from "../index";
import {closeSearchBar, openSearchBar} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearchBar: () => dispatch(openSearchBar()),
    closeSearchBar: () => dispatch(closeSearchBar())
  };
};

const HeaderSearchCircleButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearchCircleButton);

export default HeaderSearchCircleButtonContainer;
