import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import classNames from "classnames/bind";
import countriesQuery from "components/AuthModal/components/SignUpForm/components/CountriesField/query";
import { Field, useFormikContext } from "formik";
import { COUNTRIES_BY_INSTANCE } from "./consts";
import { getCurrentInstanceId, isEUROPE, isLATAM } from "services/instances";

export const CountryField = ({ className, countryByZipcode }) => {
  const [countries, setCountries] = useState(null);
  const { setFieldValue } = useFormikContext();
  const { loading, data, fetchMore } = useQuery(countriesQuery, {
    variables: {
      first: 100,
    },
  });
  const memoizedFetchMore = useCallback(fetchMore, [fetchMore]);

  /**
   * Fetch more if there's next page
   */
  useEffect(() => {
    if (data && data.countries) {
      setCountries(data.countries);
      if (data.countries.pageInfo.hasNextPage)
        memoizedFetchMore({
          variables: {
            after: data.countries.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.countries.edges;
            const pageInfo = fetchMoreResult.countries.pageInfo;

            return newEdges.length
              ? {
                  ...previousResult,
                  // Put the new patterns at the end of the list and update `pageInfo`
                  // so we have the new `endCursor` and `hasNextPage` values
                  countries: {
                    ...previousResult.countries,
                    edges: [...previousResult.countries.edges, ...newEdges],
                    pageInfo,
                  },
                }
              : previousResult;
          },
        });
    }

    if (countries && countryByZipcode) {
      const filteredCountry = countries.edges.filter(
        ({ node: { code2 } }) => code2 === countryByZipcode
      )[0]?.node;

      if (filteredCountry) setFieldValue("countryId", filteredCountry.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, memoizedFetchMore, countryByZipcode]);

  return (
    <Field
      component="select"
      id="countryId"
      name="countryId"
      className={classNames({
        "form-control": true,
        [`${className}`]: className !== undefined,
      })}
    >
      {(props) => {
        const { field } = props;
        let selectOptions = [];
        const defaultOption = (
          <option key={`key_${field.value.label}`} value={field.value.value}>
            {field.value.label}
          </option>
        );

        const options =
          !loading && !isLATAM() && !isEUROPE()
            ? COUNTRIES_BY_INSTANCE[getCurrentInstanceId()].map(
                ({ label, value }) => (
                  <option key={`key_${label}`} value={value}>
                    {label}
                  </option>
                )
              )
            : (countries &&
                COUNTRIES_BY_INSTANCE[getCurrentInstanceId()](countries).map(
                  ({ node: { name: label, id: value } }) => (
                    <option key={`key_${label}`} value={value}>
                      {label}
                    </option>
                  )
                )) ||
              [];

        selectOptions = [
          defaultOption,
          options.filter((option) => option.key !== defaultOption.key),
        ];

        return (
          <select
            className="bg-white py-2 px-2 rounded border border-dark col-12"
            value={field.value}
            {...field}
          >
            {defaultOption.key === "key_undefined"
              ? selectOptions.filter(
                  (option) => option.key !== defaultOption.key
                )
              : selectOptions.map((option) => option)}
          </select>
        );
      }}
    </Field>
  );
};

CountryField.propTypes = {
  className: PropTypes.string,
  countryByZipcode: PropTypes.string,
  field: PropTypes.object,
};
