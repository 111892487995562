import {
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_TOKEN_EXPIRATION_KEY,
} from "services/auth/contants/index";
import {
  LOCAL_STORAGE_CART_ID,
  LOCAL_STORAGE_CONTACT_CREATED_KEY,
} from "services/auth/contants";

export function getToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function setToken(token) {
  return localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

export function removeToken() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function getTokenExpirationDate() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);
}

export function setTokenExpirationDate(token) {
  return localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY, token);
}

export function removeTokenExpirationDate() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);
}

export function setContactCreated(value) {
  localStorage.setItem(LOCAL_STORAGE_CONTACT_CREATED_KEY, value);
}

export function isContactCreated() {
  return localStorage.getItem(LOCAL_STORAGE_CONTACT_CREATED_KEY) !== null;
}

export function getCartIdLocalStorage() {
  return localStorage.getItem(LOCAL_STORAGE_CART_ID);
}

export function setCartIdLocalStorage(cartId) {
  return localStorage.setItem(LOCAL_STORAGE_CART_ID, cartId);
}

export function removeCartIdLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_CART_ID);
}

/**
 * Returns the INITIAL VALUE of the id of the cart from local storage
 * VERY IMPORTANT! DO NOT USE THIS METHOD TO GET THE CURRENT VALUE, USE cartId FROM headerReducer INSTEAD.
 * @returns {string}
 */
export function getCartId() {
  return localStorage.getItem(LOCAL_STORAGE_CART_ID);
}

/**
 * Returns true if there is a Token in the local storage.
 * DO NOT USE THIS METHOD TO DETERMINE IF A USER IS AUTHENTICATED INSIDE A COMPONENT, USE isAuthenticated FROM authReducer INSTEAD.
 * @returns {boolean} isAuthenticated
 */
export function isAuthenticated() {
  return getToken() !== null;
}
