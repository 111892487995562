import classNames from 'classnames';
import tokenAuth from 'components/AuthModal/components/SignInForm/mutation';
import CityField from 'components/AuthModal/components/SignUpForm/components/CityField';
import CountriesField from 'components/AuthModal/components/SignUpForm/components/CountriesField';
import RegionField from 'components/AuthModal/components/SignUpForm/components/RegionField';
import { createProfileMutation } from 'components/AuthModal/components/SignUpForm/mutations';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import CompanyTypeField from 'modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField';
import PropTypes from "prop-types";
import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { authLogin } from 'services/auth';
import { isMARINE } from 'services/instances';
import FormErrorMessage from 'utils/components/FormErrorMessage';
import PrivacyPolicyCheckbox from 'utils/components/PrivacyPolicyCheckbox';
import TitleUnderlined from 'utils/components/TitleUnderlined';
import useLocationByZipcode from 'utils/hooks/useLocationByZipcode';
import useToggle from 'utils/hooks/useToggable';
import { getYupValidationSchema, initialValues } from './yupSchema';

const FormUserInformationToRequestSamples = ({
  callbackShowFormUserInformation,
  userInfo
}) => {
  const [graphQLErrors, setGraphQLErrors] = useState(null);

  const intl = useIntl()
  const [success, setSuccess] = useToggle();
  const [isPrivacyPolicyAccepted, togglePrivacyPolicyAccepted] = useToggle();
  const { locationByZipcode, handleLocationByZipcode } = useLocationByZipcode();

  const [createProfile] = useMutation(createProfileMutation, {
    onError(error) {
      setGraphQLErrors(error.graphQLErrors);
    },
  });
  const [login] = useMutation(tokenAuth);

  const validationSchema = getYupValidationSchema(intl)

  function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    
    let createProfileInput = _.pick(values, [
      "phone",
      "company",
      "companyType",
      "position",
      "cityId",
      "address",
      "zipCode",
    ]);

    const createProfilePromise = createProfile({
      variables: {
        input: {
          userId: userInfo?.id,
          ...createProfileInput
        },
      },
    });

    createProfilePromise.then(() => {
      setSuccess(true);
      login({
        variables: {
          input: {
            email: userInfo?.email,
            password: userInfo?.password,
          },
        },
      }).then((response) => {
        const { tokenAuth } = response.data;
        authLogin(tokenAuth.token);
        setSubmitting(false);
      });
    })
}

  useEffect(() => {
    if (success) callbackShowFormUserInformation(false)
  }, [success, callbackShowFormUserInformation])

  return (
    <div>
      <TitleUnderlined
        align="center">
        <FormattedMessage
          id="SamplesCart.FormUserInformationToCreateProfile"
          defaultMessage="Complete these fields to continue" />
      </TitleUnderlined>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <Form id="sign-up-form" className="form">
              <div className="form-row">
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="company">
                    <FormattedMessage
                      id="SignUpForm.company"
                      description="Business field of the Sign Up form"
                      defaultMessage="Empresa"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="company"
                    id="company"
                    className="form-control"
                  />
                  <FormErrorMessage name="company" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="position">
                    <FormattedMessage
                      id="SignUpForm.position"
                      description="Position field of the Sign Up form"
                      defaultMessage="Cargo"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="position"
                    id="position"
                    className="form-control"
                  />
                  <FormErrorMessage name="position" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="companyType">
                    <FormattedMessage
                      id="SignUpForm.companyType"
                      description="Company type field of the Sign Up form"
                      defaultMessage="Tipo de compañía"
                    />
                    *
                  </label>
                  <CompanyTypeField />
                  <FormErrorMessage name="companyType" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="countryId">
                    <FormattedMessage
                      id="SignUpForm.countryId"
                      description="Country field of the Sign Up form"
                      defaultMessage="País"
                    />
                    *
                  </label>
                  <CountriesField
                    countryByZipcode={locationByZipcode?.country}
                  />
                  <FormErrorMessage name="countryId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="zipCode">
                    <FormattedMessage
                      id="SignUpForm.zipCode"
                      description="Zip code field of the Sign Up form"
                      defaultMessage="Código postal"
                    />
                    *
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    className="form-control"
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("zipCode", value);
                    }}
                    onBlur={() => handleLocationByZipcode(values.zipCode)}
                  />
                  <FormErrorMessage name="zipCode" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="regionId">
                    <FormattedMessage
                      id="SignUpForm.regionId"
                      description="Region field of the Sign Up form"
                      defaultMessage="Región"
                    />
                    *
                  </label>
                  <RegionField
                    countryId={values.countryId}
                    regionByZipcode={locationByZipcode?.state}
                  />
                  <FormErrorMessage name="regionId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="cityId">
                    <FormattedMessage
                      id="SignUpForm.city"
                      description="City field of the Sign Up form"
                      defaultMessage="Ciudad"
                    />
                    *
                  </label>
                  <CityField
                    regionId={values.regionId}
                    cityByZipcode={locationByZipcode?.city}
                  />
                  <FormErrorMessage name="cityId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="address">
                    <FormattedMessage
                      id="SignUpForm.address"
                      description="Address field of the Sign Up form"
                      defaultMessage="Dirección"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                  />
                  <FormErrorMessage name="address" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="phone">
                    <FormattedMessage
                      id="SignUpForm.phone"
                      description="Phone field of the Sign Up form"
                      defaultMessage="Teléfono"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                  />
                  <FormErrorMessage name="phone" />
                </div>
              </div>
              <div className="text-center sign-up-form__privacy-policy">
                <PrivacyPolicyCheckbox onChange={togglePrivacyPolicyAccepted} />
              </div>
              <div className="text-center">
                {graphQLErrors &&
                  graphQLErrors.map((error, index) => (
                    <div
                      key={index}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {error.message}
                    </div>
                  ))}
              </div>
              <div className="text-center">
                <button
                  disabled={
                    isSubmitting ||
                    !isPrivacyPolicyAccepted
                  }
                  type="submit"
                  className={classNames({
                    "btn btn-dark": true,
                    "btn-prussian-blue": isMARINE(),
                  })}
                >
                  {isSubmitting ? (
                    <FormattedMessage
                      id="SamplesCart.FormUserInformationToCreateProfileButtonLoading"
                      description="Submit button of the form with user information"
                      defaultMessage="Continuing..."
                    />
                  ) : (
                    <FormattedMessage
                      id="SamplesCart.FormUserInformationToCreateProfileButton"
                      description="Submit button of the form with user information"
                      defaultMessage="Continue"
                    />
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  )
}

FormUserInformationToRequestSamples.propTypes = {
  callbackShowFormUserInformation: PropTypes.func.isRequired,
  userInfo: PropTypes.object
};

export default FormUserInformationToRequestSamples