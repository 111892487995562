import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SignUpForm from "components/AuthModal/components/SignUpForm";
import SignInFormContainer from "components/AuthModal/components/SignInForm/container";
import {AUTH_MODAL_SIGN_IN_CONTEXT, AUTH_MODAL_SIGN_UP_CONTEXT} from "components/AuthModal/constants";
import './styles/AuthModal.scss';

function AuthModal({
  headerReducer,
  isAuthenticated,
  setAuthModalContext,
  toggleAuthModal
}) {
  const {authModalIsOpen, authModalContext} = headerReducer;

  function toggleContext() {
    setAuthModalContext(authModalContext === AUTH_MODAL_SIGN_IN_CONTEXT ?
      AUTH_MODAL_SIGN_UP_CONTEXT : AUTH_MODAL_SIGN_IN_CONTEXT
    );
  }

  return (
    <Modal
      size={authModalContext === AUTH_MODAL_SIGN_IN_CONTEXT ? "md" : "xl"}
      toggle={toggleAuthModal}
      isOpen={authModalIsOpen}>
      <ModalHeader
          tag="div"
          toggle={toggleAuthModal}>
        {
          authModalContext === AUTH_MODAL_SIGN_IN_CONTEXT ?
            <>
              <h5 className="d-block text-center text-family-alt text-size-h1">
                <FormattedMessage
                  id="AuthModal.headerTitleSignInV2"
                  description="Auth modal header title"
                  defaultMessage="Ingresar"/>
              </h5>
            </>
            :
            <>
              <h5 className="d-block text-center text-family-alt text-size-h1">
                <FormattedMessage
                  id="AuthModal.headerTitleSignUpV2"
                  description="Auth modal header title"
                  defaultMessage="Registrarse"/>
              </h5>
              <p className="mb-0 gray text-center">
                <FormattedMessage
                  id="AuthModal.headerDescriptionSignUpV2"
                  description="Auth modal header description"
                  defaultMessage="Diligenciando el siguiente formulario podrás crear tu cuenta de usuario y con ésta tienes la posibilidad de solicitar muestras, cotizaciones y catálogos de forma rápida, adicional puedes revisar el historial de todas tus solicitudes."/>
              </p>
            </>
        }
      </ModalHeader>
      <ModalBody>
        {
          authModalContext === AUTH_MODAL_SIGN_IN_CONTEXT ?
            <SignInFormContainer
              onAfterSubmit={toggleAuthModal}/>
            :
            <SignUpForm
              loginAfterSubmit
              onAfterSubmit={toggleAuthModal}/>
        }
      </ModalBody>
      {
        !isAuthenticated &&
        <ModalFooter className="auth-modal__footer">
          {
            authModalContext === AUTH_MODAL_SIGN_IN_CONTEXT ?
              <>
                <FormattedMessage
                  id="AuthModal.footerLabelSignIn"
                  description="Auth modal footer label"
                  defaultMessage="¿No tienes cuenta?"/>&nbsp;
                <strong className="text-underline cursor-pointer" onClick={toggleContext}>
                  <FormattedMessage
                    id="AuthModal.footerLinkSignIn"
                    description="Auth modal footer link"
                    defaultMessage="Regístrate aquí"/>
                </strong>
              </>
              :
              <>
                <FormattedMessage
                  id="AuthModal.footerLabelSignUp"
                  description="Auth modal footer label"
                  defaultMessage="¿Ya tienes cuenta?"/>&nbsp;
                <strong className="text-underline cursor-pointer" onClick={toggleContext}>
                  <FormattedMessage
                    id="AuthModal.footerLinkSignUp"
                    description="Auth modal footer link"
                    defaultMessage="Ingresa aquí"/>
                </strong>
              </>
          }
        </ModalFooter>
      }
    </Modal>
  )
}

AuthModal.propTypes = {
  headerReducer: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  setAuthModalContext: PropTypes.func,
  toggleAuthModal: PropTypes.func,
};

export default AuthModal;
