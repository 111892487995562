/* eslint-disable react/prop-types */
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import wood from "../../utils/wood.png";
import botle from "../../utils/botle.jpg";
import SectionModal from "./components/SectionModal";
import "./styles/BioSection.scss";

// MODAL 1
import headerModalOneImg from "modules/about/EcosensePage/imgs/modals/modal1_headerImage.svg";
import headerModalOneImgEs from "modules/about/EcosensePage/imgs/modals/modal1_headerImage_es.svg";
import imgModalOneDesktop from "modules/about/EcosensePage/imgs/modals/modal_1_wood.svg";
import imgModalOneDesktopEs from "modules/about/EcosensePage/imgs/modals/modal_1_wood_es.svg";
import imgModalOneMobile from "modules/about/EcosensePage/imgs/modals/modal_1_wood_mobile.svg";
import imgModalOneMobileEs from "modules/about/EcosensePage/imgs/modals/modal_1_wood_mobile_es.svg";
// MODAL 2
import headerModalTwoImg from "modules/about/EcosensePage/imgs/modals/modal2_headerImage.svg";
import imgModalTwoDesktop from "modules/about/EcosensePage/imgs/modals/modal_2_pet.svg";
import imgModalTwoDesktopEs from "modules/about/EcosensePage/imgs/modals/modal_2_pet_es.svg";
import imgModalTwoMobile from "modules/about/EcosensePage/imgs/modals/modal_2_pet_mobile.svg";
import imgModalTwoMobileEs from "modules/about/EcosensePage/imgs/modals/modal_2_pet_mobile_es.svg";
import { PRODUCT_DETAILS } from "routes";
import TranslatedLink from "routes/components/TranslatedLink";
import { isMARINE, isRESOURCES, isUSA } from "services/instances";
import classNames from "classnames";

const RESOURCES_FOR_BIO_MODAL = (isInstanceOfUsa) => ({
  headerModalImg: !isInstanceOfUsa ? headerModalOneImgEs : headerModalOneImg,
  imgModalDesktop: !isInstanceOfUsa ? imgModalOneDesktopEs : imgModalOneDesktop,
  imgModalMobile: !isInstanceOfUsa ? imgModalOneMobileEs : imgModalOneMobile,
});

const RESOURCES_FOR_PET_MODAL = (isInstanceOfUsa) => ({
  headerModalImg: headerModalTwoImg,
  imgModalDesktop: !isInstanceOfUsa ? imgModalTwoDesktopEs : imgModalTwoDesktop,
  imgModalMobile: !isInstanceOfUsa ? imgModalTwoMobileEs : imgModalTwoMobile,
});

export const BioSection = () => {
  const [showBio, setShowBio] = useState(false);
  const [showPet, setShowPet] = useState(false);
  const isInstanceOfUsa = isRESOURCES() || isMARINE() || isUSA();

  const handleCloseBio = () => setShowBio(false);

  const handleShowBio = () => setShowBio(true);

  const handleClosePet = () => setShowPet(false);

  const handleShowPet = () => setShowPet(true);

  return (
    <div style={{ width: "100%" }}>
      <div className="row bio-container">
        <div className="bio-container-fst-color" />
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div style={{ position: "relative", zIndex: 1 }}>
            <div className="row bio-title">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-9 col-md-12 col-sm-12">
                    <p className="bio-content bio-text bio-text-title">
                      <FormattedMessage
                        id="EcosensePage.v2.Purpose.title"
                        defaultMessage="The path to create a new coated fabric made with purpose"
                      />
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-5">
                  <div className="col-lg-9 col-md-12 col-sm-12">
                    <p className="bio-content_text bio-text">
                      <FormattedMessage
                        id="EcosensePage.v2.Purpose.description"
                        defaultMessage="Advancing sustainable manufacturing, we're committed to
                        engineering products with environmentally concious
                        attributes, including bio-attribution for a reduced
                        environmental footprint and backings made with RPET
                        bottles for a second life."
                      />
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="row d-flex justify-content-center px-5">
                      <div className="col-lg-5 col-md-6 col-sm-12 mb-5 mb-md-0">
                        <div className="row d-flex h-100">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="">
                                  <img
                                    src={wood}
                                    className="card-img-top"
                                    alt="wood"
                                    style={{
                                      height: "auto",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row py-4 bio-card-container bio-card-container-wood">
                              <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
                                <div className="">
                                  <p className={classNames({
                                    "bio-content": true,
                                    "px-md-5 px-xxl-0": !isInstanceOfUsa,
                                    "px-lg-3 px-xl-0 bio-section--bio-attr-title": isInstanceOfUsa
                                    })}>
                                    <FormattedMessage
                                      id="EcosensePage.v2.Purpose.bio.title"
                                      defaultMessage="Bio-attribution for the Planet"
                                    />
                                  </p>
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark rounded-pill mb-2 fw-medium"
                                    onClick={handleShowBio}
                                  >
                                    <FormattedMessage
                                      id="EcosensePage.v2.Purpose.bio.btn"
                                      defaultMessage="Check out the process here"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="row d-flex h-100">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="">
                                  <img
                                    src={botle}
                                    className="card-img-top"
                                    alt="wood"
                                    style={{
                                      height: "auto",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row bio-card-container py-4">
                              <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
                                <div className="">
                                  <p className={classNames({
                                    "bio-content": true,
                                    "px-md-5 px-lg-3 px-xl-0": isInstanceOfUsa
                                    })}>
                                    <FormattedMessage
                                      id="EcosensePage.v2.Purpose.plastics.title"
                                      defaultMessage="Giving New Life to Plastics"
                                    />
                                  </p>
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark rounded-pill mb-2 fw-medium giving-new-life-btn"
                                    onClick={handleShowPet}
                                  >
                                    <FormattedMessage
                                      id="EcosensePage.v2.Purpose.plastics.btn"
                                      defaultMessage="Learn more about the origin"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isInstanceOfUsa && (
                  <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                      <TranslatedLink
                        name={PRODUCT_DETAILS}
                        params={{
                          productSlug: "masiala",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-dark rounded-pill px-5"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            cursor: "pointer",
                            fontSize: 18,
                          }}
                        >
                          <FormattedMessage
                            id="EcosensePage.v2.Purpose.exploreCollection.btn"
                            defaultMessage="Explore the collection now!"
                          />
                        </button>
                      </TranslatedLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SectionModal
        headerImage={RESOURCES_FOR_BIO_MODAL(isInstanceOfUsa).headerModalImg}
        headerTitle={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.bio.Modal.title"
            defaultMessage="of the polymer is crafted using bio-attributed components sourced from the forestry industry"
          />
        }
        show={showBio}
        handleClose={handleCloseBio}
        image={RESOURCES_FOR_BIO_MODAL(isInstanceOfUsa).imgModalDesktop}
        mobileImage={RESOURCES_FOR_BIO_MODAL(isInstanceOfUsa).imgModalMobile}
        title={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.bio.Modal.subtitle"
            defaultMessage="Bio-attribution for the Planet"
          />
        }
        content={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.bio.Modal.subtitle.content"
            defaultMessage="This infusion not only distinguishes our products but also signifies our commitment to continuous sustainable innovation. The components sourced from organic materials, which are non-consumable by humans, ensure that our sourcing does not jeopardize human food supplies."
          />
        }
      />

      <SectionModal
        headerImage={RESOURCES_FOR_PET_MODAL(isInstanceOfUsa).headerModalImg}
        headerTitle={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.plastics.Modal.title"
            defaultMessage="recycled backing made from PET bottles"
          />
        }
        show={showPet}
        handleClose={handleClosePet}
        image={RESOURCES_FOR_PET_MODAL(isInstanceOfUsa).imgModalDesktop}
        mobileImage={RESOURCES_FOR_PET_MODAL(isInstanceOfUsa).imgModalMobile}
        title={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.plastics.Modal.subtitle"
            defaultMessage="Giving New Life to Plastics"
          />
        }
        content={
          <FormattedMessage
            id="EcosensePage.v2.Purpose.plastics.Modal.subtitle.content"
            defaultMessage="Around 250 billion PET (Polyethylene terephthalate) bottles end up in landfill globally each year. On average these bottles take between 450 and 700 years to decompose. That is why we have created the Hi-Loft<sup>²™️</sup> RPET Backing  contributing for each roll to emit 12.5 kilograms less of CO<sub>2</sub> and eliminate 6.2 kilograms of  landfill waste."
            values={{
              sup: (...chunks) => <sup>{chunks}</sup>,
              sub: (...chunks) => <sub>{chunks}</sub>,
            }}
          />
        }
      />
    </div>
  );
};
