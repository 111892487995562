import { PROSEAT_SLUG } from "modules/proseat/ProseatPage/constants/proseatSlug";

/**
 * Returns an array of available and sorted documents based on the provided document categories and isMARINE flag.
 *
 * @param {Array} documentCategories - An array of document categories.
 * @param {boolean} isMARINE - A flag indicating whether the documents are for the MARINE category.
 * @returns {Array} - An array of available and sorted documents.
 */
export const availablesAndSortedDocuments = (documentCategories, isMARINE) => {
  let sortedDocuments = []

  const proseatDocumentCategory = documentCategories.filter((category) => category.node.slug === PROSEAT_SLUG)

  const otherDocumentCategories = documentCategories.filter((category) => category.node.slug !== PROSEAT_SLUG && category.node.documents.totalCount > 0)

  if (isMARINE) sortedDocuments = [...proseatDocumentCategory, ...otherDocumentCategories]
  else sortedDocuments = otherDocumentCategories 

  return sortedDocuments 
}