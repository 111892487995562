import { FormattedMessage } from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import SubHeroContent from "utils/components/SubHeroContent";
import { H1_TAG } from "utils/components/TitleUnderlined/constants";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import StoreFinderContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/container";
import SEO from "services/seo/components/SEO";
import { WHERE_TO_BUY_SCENE } from "services/seo/constants/scenes";

const WhereToBuyPage = () => (
  <>
    <ScrollTopOnMount />
    <SubHeroContent theme="green" hideShape>
      <div className="container text-center pt-4 pb-4 pt-lg-5 pb-lg-5">
        <TitleUnderlined
          hTag={H1_TAG}
          className="text-size-h1 text-weight-bold"
          color="white"
        >
          <FormattedMessage
            id="WhereToBuyPage.title"
            description="Hero title in Where to Buy page"
            defaultMessage="Encuentra nuestros distribuidores"
          />
        </TitleUnderlined>
        <p>
          <FormattedMessage
            id="WhereToBuyPage.content"
            description="Hero content in Where to Buy page"
            defaultMessage="Queremos ayudarte a encontrar los distribuidores más cercanos a tu ubicación para que puedas adquirir nuestros productos."
          />
        </p>
      </div>
    </SubHeroContent>
    <StoreFinderContainer />
    <SEO scene={WHERE_TO_BUY_SCENE} />
  </>
);

export default WhereToBuyPage;
