import { FormattedMessage } from "react-intl";

export const EmbracingSection = () => {
  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-9 col-md-12 col-sm-12 px-4">
          <p className="text-td-ads mb-5 mt-5">
            <h4 style={{ fontWeight: "bold", fontSize: 24 }}>
              <FormattedMessage
                id="EcosensePage.v2.Embracing.title"
                defaultMessage="Embracing the UN's 2030 Agenda for Sustainable Development"
              />
            </h4>
          </p>

          <p style={{ fontSize: 18 }} className="mb-5">
            <FormattedMessage
              id="EcosensePage.v2.Embracing.description"
              defaultMessage="Caring for our future isn't good marketing. It's an emergency we
              need to face together with tangible action and real results. In line
              with the objectives set forth by the United Nations, we are
              dedicated to realizing the Sustainable Development Goals (SDGs). Our
              commitment is focused on eleven key areas, with eight directly
              linked to SDGs and three indirectly contributing to their
              achievement."
            />
          </p>
        </div>
      </div>
    </div>
  );
};
