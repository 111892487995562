import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink, from} from "apollo-link";
import {createUploadLink} from 'apollo-upload-client';
import {TokenRefreshLink} from 'apollo-link-token-refresh';
import {getToken, getTokenExpirationDate} from "services/auth/localStorage";
import {refreshToken} from "services/auth/mutations";
import {authLogin, authLogout} from "services/auth";
import {getCurrentLanguageAndInstanceCode} from "services/instances";

const httpLink = createUploadLink({
  uri: new URL('graphql/', process.env.REACT_APP_SERVER_URL).href
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const [lang] = getCurrentLanguageAndInstanceCode();
  operation.setContext({
    headers: {
      Authorization: getToken() ? `JWT ${getToken()}` : null,
      'Accept-Language': lang
    }
  });
  return forward(operation);
});

const tokenRefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    // Indicates the current state of access token expiration.
    // If token not yet expired or user doesn't have a token (guest)
    // true should be returned
    if (!getToken()) return true;
    if (!getTokenExpirationDate()) return false;  // Just in case

    const tokenExpirationDate = new Date(getTokenExpirationDate());
    const now = new Date();
    const differenceInSeconds = (tokenExpirationDate.getTime() - now.getTime()) / 1000;
    return differenceInSeconds >= 1000;
  },
  fetchAccessToken: () => {
    const token = getToken();
    const body = {
      operationName: "RefreshToken",
      query: refreshToken,
      variables: {
        input: {
          token
        }
      }
    };
    return fetch(`${process.env.REACT_APP_SERVER_URL}/graphql/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  accessTokenField: 'refreshToken',
  handleFetch: refreshToken => {
    authLogin(refreshToken.token).then();
  },
  handleError: err => {
    // full control over handling token fetch Error
    console.error(err);
    authLogout();
    // TODO: Must be redirect to "/" ?
  }
});

export const apolloClient = new ApolloClient({
  link: from([middlewareLink, tokenRefreshLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id
  })
});
