import {combineReducers, createStore} from "redux";
import {headerReducer} from "components/Header/reducer";
import {locationReducer} from "modules/stores/WhereToBuyPage/reducer";
import {authReducer} from "services/auth/reducer";

const combinedReducers = combineReducers({
  headerReducer,
  locationReducer,
  authReducer
});

export const reduxStore = createStore(
  combinedReducers
);
