import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import GlossarySection from "./components/GlossarySection";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";

export const GlossarySectionWrapper = ({ currentCharacteristic, showGlossary, handleToggleGlossary }) => {
  const isDesktop = useMediaQuery(LG);

  return (
    !isDesktop
      ? <Modal
        size="xl"
        isOpen={showGlossary}
        modalClassName="glossary-section--modal-mobile"
      >
        <GlossarySection 
          currentCharacteristic={currentCharacteristic} 
          handleCloseGlossary={handleToggleGlossary} />
      </Modal>
      : showGlossary && isDesktop &&
        <GlossarySection 
          currentCharacteristic={currentCharacteristic} 
          handleCloseGlossary={handleToggleGlossary} />
  )
}

GlossarySectionWrapper.propTypes = {
  currentCharacteristic: PropTypes.object,
  showGlossary: PropTypes.bool,
  handleToggleGlossary: PropTypes.func
}

export default GlossarySectionWrapper;