import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/ProductsPageTitle.scss";
import { useQuery } from "@apollo/react-hooks";
import marketsSimpleQuery from "components/Header/components/HeaderMainMenu/query";
import { getCurrentInstanceId } from "services/instances";
import { useLocation } from "react-router-dom";
import QueryParams from "services/browser-history/QueryParams";
import {
  QUERY_PARAM_GROUPS_VALUES,
  QUERY_PARAM_GROUP,
  QUERY_PARAM_SECTOR,
} from "modules/products/ProductListPage/constants";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

const PageTitle = ({ children, description = "" }) => (
  <>
    <h1
      className={classNames({
        "products-page-title": true,
        "end-border": !description,
        "pb-0": description,
      })}
    >
      {children}
    </h1>
    {description && (
      <p className="end-border">
        <span
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </p>
    )}
  </>
);

//  TODO: refactory using componentization
export default function ProductsPageTitle() {
  const location = useLocation();
  const queryParams = useMemo(
    () => new QueryParams(location.search),
    [location.search]
  );
  const filterGroup = queryParams.find(QUERY_PARAM_GROUP);
  const sectorId = queryParams.find(QUERY_PARAM_SECTOR);
  const [sectors, setSectors] = useState(null);
  const [sectorName, setSectorName] = useState(null);
  const { loading } = useQuery(marketsSimpleQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      saleable: true,
    },
    onCompleted(data) {
      let sectors = {};
      const {
        markets: { edges },
      } = data;
      edges.forEach(({ node }) => {
        const {
          sectors: { edges },
        } = node;
        edges.forEach(({ node }) => {
          sectors[node.id] = node.name;
        });
      });
      setSectors(sectors);
    },
  });

  /**
   * Check for sectors and query params change to make this component render the right thing
   */
  useEffect(() => {
    if (sectors && queryParams)
      // Don't use sectorId constant here because having it in deps array
      // causes double execution of this effect
      setSectorName(sectors[queryParams.find(QUERY_PARAM_SECTOR)]);
  }, [sectors, queryParams]);

  if (sectorId && loading)
    return (
      <PageTitle>
        <Skeleton width={150} />
      </PageTitle>
    );
  else if (!loading && sectorId && sectorName)
    return <PageTitle>{sectorName}</PageTitle>;
  else if (!loading && filterGroup)
    return (
      <PageTitle
        description={QUERY_PARAM_GROUPS_VALUES[filterGroup]?.description}
      >
        {QUERY_PARAM_GROUPS_VALUES[filterGroup].title}
      </PageTitle>
    );
  else
    return (
      <PageTitle>
        <FormattedMessage
          id="ProductListPage.title"
          description="Products list page title"
          defaultMessage="Catálogo de productos y características"
        />
      </PageTitle>
    );
}

PageTitle.propTypes = {
  children: PropTypes.element,
  description: PropTypes.string,
};
