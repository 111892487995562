import gql from "graphql-tag";

export const verifyToken = gql`
mutation VerifyToken ($input: VerifyInput!){
  verifyToken(input: $input) {
    payload
  }
}`;

export const refreshToken = "mutation RefreshToken ($input: RefreshInput!){refreshToken(input: $input) {payload token}}";

