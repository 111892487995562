import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import TitleUnderlined from "utils/components/TitleUnderlined";
import TranslatedLink from "routes/components/TranslatedLink";
import { ABOUT_WE_ARE_SPRADLING } from "routes";
import SubHeroContent from "utils/components/SubHeroContent";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import { isMARINE } from "services/instances";
import useMediaQuery from "utils/hooks/useMediaQuery";

const HOME_ABOUT_DESCRIPTION_HOME = {
  desktop: (
    <FormattedMessage
      id="HomeAbout.description"
      description="Text in homepage, about section"
      defaultMessage="Somos líderes mundiales en la fabricación y comercialización de telas y pisos recubiertos. Contamos con dos plantas de producción certificadas con diversos sellos sostenibles que validan nuestra experiencia y respaldo, ubicadas en Colombia y Costa Rica.​{br}{br}Desde 1959, hemos inspirado al mundo a través del diseño y la innovación con el compromiso de cuidar el medio ambiente y las comunidades de las que hacemos parte. Nuestros productos están presentes en más de 80 países y han sido creados específicamente para los sectores de Contract, Residencial, Náutica, Transporte, Zapatería, Marroquinería, Protección y Vestuario, y Pisos."
      values={{ br: <br /> }}
    />
  ),
  mobile: (
    <FormattedMessage
      id="HomeAbout.description.mobile"
      description="Text in homepage, about section"
      defaultMessage="Somos líderes mundiales en la fabricación y comercialización de telas y pisos recubiertos. Contamos con dos plantas de producción certificadas con diversos sellos sostenibles que validan nuestra experiencia y respaldo, ubicadas en Colombia y Costa Rica.{br}{br}Desde 1959, hemos inspirado al mundo a través del diseño y la innovación con el compromiso de cuidar el medio ambiente y las comunidades de las que hacemos parte.{br}{br}Nuestros productos están presentes en más de 80 países y han sido creados específicamente para los sectores de Contract, Residencial, Náutica, Transporte, Zapatería, Marroquinería, Protección y Vestuario, y Pisos."
      values={{ br: <br /> }}
    />
  ),
};

const HomeAbout = ({
  className = "py-4 py-lg-5",
  classNameMainCol = "pt-3",
  showButton = true,
  subHeroTheme = "white",
  description,
}) => {
  const isMobile = useMediaQuery("(max-width: 995px)");
  const descriptionInfo = !description
    ? !isMobile
      ? HOME_ABOUT_DESCRIPTION_HOME.desktop
      : HOME_ABOUT_DESCRIPTION_HOME.mobile
    : description;

  return (
    <SubHeroContent theme={subHeroTheme} hideShape>
      <div
        className={classNames({
          "container text-center": true,
          [className]: className !== undefined,
        })}
      >
        <div
          className={classNames({
            "row justify-content-center": true,
            [classNameMainCol]: classNameMainCol !== undefined,
          })}
        >
          <div className="col-12 col-lg-10">
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="HomeAbout.title"
                description="Title in homepage, about section"
                defaultMessage="Somos Spradling"
              />
            </TitleUnderlined>
            <p className="mb-4 mb-lg-3">{descriptionInfo}</p>
            {showButton && (
              <TranslatedLink
                name={ABOUT_WE_ARE_SPRADLING}
                className={classNames({
                  "btn btn-dark mb-3": true,
                  "btn-prussian-blue": isMARINE(),
                })}
              >
                <FormattedMessage
                  id="HomeAbout.discoverMore"
                  description="Button 'Discover more' in homepage, about section"
                  defaultMessage="Descubre más"
                />
              </TranslatedLink>
            )}
          </div>
        </div>
      </div>
    </SubHeroContent>
  );
};

HomeAbout.propTypes = {
  className: PropTypes.string,
  classNameMainCol: PropTypes.string,
  description: PropTypes.element,
  showButton: PropTypes.bool,
  subHeroTheme: PropTypes.string,
};

export default HomeAbout;
