import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import OrderDetailRow from "./components/OrderDetailRow";
import "./styles/OrderDetails.scss";

const OrderDetails = ({
  patternsToShow,
  handleGoBack,
  dateUpdated,
  orderId,
}) => {
  return (
    <>
      <li
        className="col-12 mb-3 cursor-pointer nav-link pl-0 pr-0 colored-link gray p-0"
        onClick={handleGoBack}
      >
        <small>
          <i className="sp-strong-chevron-left mr-2" />
        </small>
        <FormattedMessage id="RecordsPage.seeOrdersList" />
      </li>
      <h1 className="text-center mt-5">
        <FormattedMessage id="RecordsPage.ordersDetail" />
      </h1>
      <div className="d-flex align-items-center mt-5">
        <h4 className="my-auto">
          {orderId} - {dateUpdated}
        </h4>
      </div>
      {patternsToShow.map(({ node }, index) => {
        return <OrderDetailRow item={node} key={index} />;
      })}
    </>
  );
};

OrderDetails.propTypes = {
  patternsToShow: PropTypes.array,
  handleGoBack: PropTypes.func,
  orderId: PropTypes.string,
  dateUpdated: PropTypes.string,
};

export default OrderDetails;
