import { Suspense } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { graphql } from "react-apollo";
import classNames from "classnames/bind";
import featuredProjectQuery from "modules/Home/components/HomeFeaturedProject/query";
import TitleUnderlined from "utils/components/TitleUnderlined";
import SeeMoreButton from "utils/components/SeeMoreButton";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import { PROJECT_DETAILS, PROJECTS_LIST } from "routes";
import {
  isMARINE,
  getCurrentInstanceId,
  getTranslatedLink,
} from "services/instances";
import "./styles/HomeFeaturedProject.scss";

let HomeFeaturedProject = ({
  featuredProjectQuery: { loading, error, projects },
}) => {
  if (error || (!loading && projects.edges.length === 0)) return null;

  let project = null;
  if (!loading && projects.edges.length > 0) project = projects.edges[0].node;

  return (
    <div
      className={classNames({
        "home-featured-project": true,
        "home-featured-project--marine": isMARINE(),
      })}
    >
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-md-6">
            <div className="home-featured-project-image">
              {!loading && (
                <Suspense fallback={<Skeleton width={720} height={312} />}>
                  <img
                    className="w-100"
                    src={project.heroImageThumbMedium}
                    alt={project.name}
                  />
                </Suspense>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6 pt-4 pb-4 padding-xl">
            <div className="pl-md-4 h-100">
              <div className="row no-gutters justify-content-between">
                <div className="col-auto">
                  <TitleUnderlined
                    hTag={H2_TAG}
                    className="text-size-h3 text-size-lg-h2 text-size-xl-h2"
                    color="white"
                    align="left"
                  >
                    <FormattedMessage
                      id="HomeFeaturedProject.title"
                      description="Title for homepage creations section"
                      defaultMessage="Nuestras creaciones"
                    />
                  </TitleUnderlined>
                </div>
                <div className="col-auto text-size-extra-small text-size-lg-small">
                  <SeeMoreButton
                    href={getTranslatedLink(PROJECTS_LIST, {})}
                    className="home-featured-project-see-all-button text-size-xl-h6"
                  >
                    <FormattedMessage
                      id="HomeFeaturedProject.seeAll"
                      description="See all button label in home creations section"
                      defaultMessage="Ver todas"
                    />
                  </SeeMoreButton>
                </div>
              </div>
              <div className="project-details text-size-small text-size-lg-base">
                <h3 className="project-details__name text-weight-bold text-size-h6 text-size-lg-h6 mb-3">
                  {!loading ? `${project.name}` : <Skeleton width={80} />}
                </h3>
                <p className="project-details__client mb-0 text-margin-xl text-size-xl-h6">
                  <strong>
                    <FormattedMessage
                      id="HomeFeaturedProject.client"
                      description="Bold client in home creations section"
                      defaultMessage="Cliente"
                    />
                    :
                  </strong>
                  &nbsp;
                  <span className="text-weight-light">
                    {!loading ? `${project.client}` : <Skeleton width={80} />}
                  </span>
                </p>
                <p className="project-details__sector mb-3 text-size-xl-h6">
                  <strong>
                    <FormattedMessage
                      id="HomeFeaturedProject.sector"
                      description="Bold sector in home creations section"
                      defaultMessage="Sector"
                    />
                    :
                  </strong>
                  &nbsp;
                  <span className="text-weight-light">
                    {!loading ? (
                      `${project.sector.name}`
                    ) : (
                      <Skeleton width={80} />
                    )}
                  </span>
                </p>
                <p className="project-details__description text-weight-light text-margin-xl text-size-xl-h6">
                  {!loading ? `${project.description}` : <Skeleton count={5} />}
                </p>
                <p className="text-size-extra-small text-size-lg-small text-margin-xl">
                  {!loading ? (
                    <SeeMoreButton className="text-size-xl-h6"
                      href={getTranslatedLink(PROJECT_DETAILS, {
                        projectSlug: project.slug,
                      })}
                    >
                      <FormattedMessage
                        id="HomeFeaturedProject.seeMore"
                        description="See more button label in home creations section"
                        defaultMessage="Quiero saber más"
                      />
                    </SeeMoreButton>
                  ) : (
                    <Skeleton />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeFeaturedProject.propTypes = {
  featuredProjectQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    projects: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

// TODO: use graphql hooks
export default graphql(featuredProjectQuery, {
  name: "featuredProjectQuery",
  options: () => {
    return {
      variables: {
        instanceIds: [getCurrentInstanceId()],
        first: 1,
      },
    };
  },
})(HomeFeaturedProject);
