
export const PINK_STAIN = "Pink Stain"
export const SEWING_RECOMMENDATION = "Sewing Recommendations"

export const AVAILABLES_PDF = {
  [PINK_STAIN]: {
    pdfUrl: "https://misc.assets.spradling.group/proseat/Pink-Stain-TB-SIM-2016.pdf"
  },
  [SEWING_RECOMMENDATION]: {
    pdfUrl: "https://misc.assets.spradling.group/proseat/General_Sewing.pdf"
  }
}