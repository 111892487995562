import gql from "graphql-tag";

const officesByInstanceQuery = gql`
  query OfficesByInstanceQuery($orderBy: String, $instance: ID) {
    offices(orderBy: $orderBy, instance: $instance) {
      edges {
        node {
          id
          name
          address
          business
          latitude
          longitude
          phone
          email
          city {
            id
            name
            region {
              id
              name
            }
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default officesByInstanceQuery;
