import PropTypes from "prop-types";
import HeroTitle from "utils/components/HeroTitle";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import singleMarketQuery from "modules/product-lines/SingleMarketPage/query";
import { Query } from "react-apollo";
import SubHeroContent from "utils/components/SubHeroContent";
import "./styles/SingleMarketPage.scss";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import SEO from "services/seo/components/SEO";
import { getCurrentInstanceId, isMARINE } from "services/instances";
import SectorCard from "modules/product-lines/SingleMarketPage/components/SectorCard";
import LoadingMessage from "utils/components/LoadingMessage";

export default function SingleMarketPage({ match }) {
  return (
    <div className="single-market-page">
      <ScrollTopOnMount />
      <Query
        query={singleMarketQuery}
        fetchPolicy={FETCH_POLICY_CACHE_AND_NETWORK}
        variables={{
          slug: match.params.marketSlug,
          instanceIds: [getCurrentInstanceId()],
        }}
      >
        {({ loading, data }) => {
          if (loading)
            return (
              <>
                <HeroTitle
                  title={<Skeleton width={200} />}
                  backgroundImage=""
                />
                <SubHeroContent theme={!isMARINE() ? "green" : "prussian-blue"}>
                  <div className="container text-center pt-4 pb-4 pt-lg-5 pb-lg-5">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10">
                        <Skeleton
                          className="text-size mb-4 mb-lg-3"
                          count={3}
                        />
                      </div>
                    </div>
                  </div>
                </SubHeroContent>
              </>
            );

          if (data) {
            const { market } = data;
            const { pageTitle: title, metaDescriptions: description } = market;
            const headerData = { title, description };
            const sectors =
              market &&
              _.filter(
                market.sectors.edges,
                (edge) => edge.node.patternSet.totalCount > 0
              );

            return (
              <>
                <HeroTitle
                  title={market.name}
                  backgroundImage={market.imageThumbLarge}
                />
                <SubHeroContent theme={!isMARINE() ? "green" : "prussian-blue"}>
                  <div className="container text-center pt-4 pb-4 pt-lg-5 pb-lg-5">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10">
                        <p className="text-size mb-4 mb-lg-3">
                          {market.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </SubHeroContent>
                <div className="single-market-page__sectors">
                  {sectors.map((sector, index) => {
                    const reversed = index % 2 === 1;

                    return (
                      <SectorCard
                        key={index}
                        sector={sector}
                        reversed={reversed}
                        match={match}
                      />
                    );
                  })}
                </div>
                <SEO data={headerData} />
              </>
            );
          }

          return <LoadingMessage />;
        }}
      </Query>
    </div>
  );
}

SingleMarketPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      marketSlug: PropTypes.string,
    }),
  }),
};
