import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Skeleton from 'react-loading-skeleton';

const DocumentItem = ({document, loading}) => (
    <>
      <div className="document pt-3 pb-3">
        <h2 className="text-family-base h5 font-weight-bold mb-3">{loading ? <Skeleton width="50%"/> : document.name}</h2>
        <p className="gray mb-3">{loading ? <Skeleton count={3}/> : document.description}</p>
        <p className="mb-0">
          {
            loading ?
                <Skeleton width="20%"/>
                :
                <a
                    href={document.file}
                    className="gray text-weight-medium"
                    target="_blank"
                    rel="noopener noreferrer">
                  <FormattedMessage
                      id="DocumentItem.downloadButton"
                      description="Download pdf button in Document list"
                      defaultMessage="Descargar PDF"/>&nbsp;
                  <i className="fa fa-file-pdf-o"/>
                </a>
          }
        </p>
      </div>
      <hr/>
    </>
);

DocumentItem.defaultProps = {
  loading: false
};

DocumentItem.propTypes = {
  document: PropTypes.object,
  loading: PropTypes.bool
};

export default DocumentItem;