import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StoreFinderBoxLayout from "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoreFinderBoxLayout";
import {FormattedMessage} from "react-intl";
import PatternSelect
  from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchByProduct/components/PatternSelect";
import {isMARINE} from "services/instances";

export default function SearchByProduct({onClickNext, onClickPrevious, setPatternId}) {
  const [btnIsDisabled, setBtnIsDisabled] = useState(true)

  /**
   * Handles change event on the <PatternSelect/>
   * @param patternId
   */
  function handleSelectChange(patternId) {
    setBtnIsDisabled(false)
    setPatternId(patternId)
  }

  /**
   * Handles click on the submit button to go to next step
   * @param event {Object}
   */
  function handleBtnClick() {
    if (btnIsDisabled) return
    onClickNext()
  }

  return (
      <StoreFinderBoxLayout
          title={
            <FormattedMessage
                id="SearchByProduct.title"
                defaultMessage="Distribuidores por producto"/>
          }
          icon="fabric"
          goBack={onClickPrevious}>
        <p className="mb-1">
          <FormattedMessage
              id="SearchByProduct.inputLabel"
              defaultMessage="¿Qué producto estás buscando?"/>
        </p>
        <div className="row no-gutters justify-content-between">
          <div className="col-12 col-md-9 mb-3 mb-md-0">
            <PatternSelect
                onChange={handleSelectChange}/>
          </div>
          <div className="col-auto">
            <button
                type="button"
                className={classNames({
                  "btn btn-dark": true,
                  "btn-prussian-blue": isMARINE()
                })}
                onClick={handleBtnClick}
                disabled={btnIsDisabled}>
              <FormattedMessage
                  id="SearchByProduct.submitBtnLabel"
                  defaultMessage="Buscar"/>
            </button>
          </div>
        </div>
      </StoreFinderBoxLayout>
  )
}

SearchByProduct.propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func,
  setPatternId: PropTypes.func,
}