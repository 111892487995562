export const CURRENT_INSTANCE_LABEL = {
  la: "Latam",
  mx: "México",
  us: "International",
  sm: "Marine & RV",
  rc: "Resources",
  eu: "Europe",
};

export const SOUTH_AMERICA_COUNTRIES_CODES = [
  "CO",
  "AR",
  "UY",
  "PY",
  "EC",
  "BO",
  "VE",
  "BR",
  "CL",
];
export const LATAM_COUNTRIES_CODES = [
  "AI", // [ISLAND] -> Anguilla
  "AG", // [ISLAND] -> Antigua and Barbuda
  "AR", // [COUNTRY] -> Argentina
  "AW", // [ISLAND] -> Aruba
  "BS", // [ISLAND] -> Bahamas
  "BB", // [ISLAND] -> Barbados
  "BZ", // [COUNTRY] -> Belize
  "BM", // [ISLAND] -> Bermuda
  "BO", // [COUNTRY] -> Bolivia
  "BQ", // [ISLAND] -> Bonaire, Saint Eustatius and Saba
  "BR", // [COUNTRY] -> Brasil
  "VG", // [ISLAND] -> British Virgin Islands
  "KY", // [ISLAND] -> Cayman Islands
  "CL", // [COUNTRY] -> Chile
  "CO", // [COUNTRY] -> Colombia
  "CR", // [COUNTRY] -> Costa Rica
  "CU", // [COUNTRY] -> Cuba
  "CW", // [ISLAND] -> Curacao
  "DM", // [ISLAND] -> Dominica
  "DO", // [COUNTRY] -> Dominican Republic
  "EC", // [COUNTRY] -> Ecuador
  "SV", // [COUNTRY] -> El Salvador
  "FK", // [ISLAND] -> Falkland Islands
  "GF", // [ISLAND] -> French Guiana
  "GL", // [ISLAND] -> Greenland
  "GD", // [ISLAND] -> Grenada
  "GP", // [ISLAND] -> Guadeloupe
  "GT", // [COUNTRY] -> Guatemala
  "GY", // [COUNTRY] -> Guyana
  "HT", // [COUNTRY] -> Haiti
  "HN", // [COUNTRY] -> Honduras
  "JM", // [ISLAND] -> Jamaica
  "MQ", // [ISLAND] -> Martinique
  "MS", // [ISLAND] -> Montserrat
  "AN", // [ISLAND] -> Netherlands Antilles
  "NI", // [COUNTRY] -> Nicaragua
  "PA", // [COUNTRY] -> Panama
  "PY", // [COUNTRY] -> Paraguay
  "PE", // [COUNTRY] -> Peru
  "BL", // [ISLAND] -> Saint Barthelemy
  "KN", // [ISLAND] -> Saint Kitts and Nevis
  "LC", // [ISLAND] -> Saint Lucia
  "MF", // [ISLAND] -> Saint Martin
  "PM", // [ISLAND] -> Saint Pierre and Miquelon
  "VC", // [ISLAND] -> Saint Vincent and the Grenadines
  "SX", // [ISLAND] -> Sint Maarten
  "SR", // [COUNTRY] -> Suriname
  "TT", // [COUNTRY] -> Trinidad and Tobago
  "TC", // [ISLAND] -> Turks and Caicos Islands
  "UY", // [COUNTRY] -> Uruguay
  "VI", // [ISLAND] -> U.S. Virgin Islands
  "VE", // [COUNTRY] -> Venezuela
];
export const NORTH_AMERICA_COUNTRIES_CODES = [
  "US",
  "CA",
  "AU",
  "HK",
  "PR", // [COUNTRY] -> Puerto Rico
];
export const EUROPE_COUNTRIES_CODES = ["ES", "GB", "DE", "FR", "IT", "PT"];

const EUROPE_COUNTRIES_WITH_LANG = {
  es: "ca-eu",
  gb: "uk-eu",
  de: "de-eu",
  fr: "fr-eu",
  it: "it-eu",
  pl: "pl-eu",
};

export const EUROPE_INSTANCE_PAGE = "https://spradling.eu";

export const PATH_BY_COUNTRY_CODE = (countryCode, onlyInstance = false) => {
  if (LATAM_COUNTRIES_CODES.indexOf(countryCode) > -1)
    return !onlyInstance ? ["es-la"] : ["la"];
  else if (["MX"].indexOf(countryCode) > -1)
    return !onlyInstance ? ["es-mx"] : ["mx"];
  else if (NORTH_AMERICA_COUNTRIES_CODES.indexOf(countryCode) > -1)
    return !onlyInstance ? ["en-us"] : ["us", "sm", "rc"];
  else if (EUROPE_COUNTRIES_CODES.indexOf(countryCode) > -1) {
    if (process.env.REACT_APP_ENV !== "production") {
      return !onlyInstance
        ? [EUROPE_COUNTRIES_WITH_LANG[countryCode.toLowerCase()]]
        : ["eu"];
    } else {
      /**
       * WARNING: this control render from spradling.group/es-la
       * RedirectToInstance component controls render from spradling.group/
       *  */
      return !onlyInstance ? EUROPE_INSTANCE_PAGE : [""];
    }
  } else return !onlyInstance ? "en-us" : "us";
};
