import PropTypes from "prop-types";
import closeIcon from "./img/close-icon.svg";
import "./styles/HeaderSection.scss";

const HeaderSection = ({ details, hideCharacteristicInfo }) => {
  return (  
    <header 
      className="characteristic-detail--header d-flex justify-content-between overflow-hidden">
      <div className="characteristic-detail--number d-flex justify-content-center align-items-center rounded-circle">
        <p className="p-0 m-0 mt-3 ml-2 font-weight-bold">
          {details.characteristicDetail.characteristicNumber}
        </p>
      </div>
      <div className="px-4 py-3">
        <img
          src={closeIcon} alt="close-icon-image"
          className="cursor-pointer"
          onClick={hideCharacteristicInfo}
        />
      </div>
    </header>
  )
}

HeaderSection.propTypes = {
  details: PropTypes.shape({
    showCharacteristicInfo: PropTypes.bool,
    characteristicDetail: PropTypes.shape({
      id: PropTypes.string,
      characteristicNumber: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.array,
      img: PropTypes.string,
      imgWidth: PropTypes.string,
      imgHeight: PropTypes.string
    })
  }),
  hideCharacteristicInfo: PropTypes.func
}

export default HeaderSection;