import "./styles/AddressBookInputs.scss";
import { Field } from "formik";
import FormErrorMessage from "utils/components/FormErrorMessage";

const AddressBookInputs = () => (
  <div className="bg-white d-flex flex-column">
    <Field
      name="zipCode"
      type="text"
      placeholder="Zip code"
      className="mt-3 rounded border border-dark py-2 px-2"
    />
    <div className="mt-1">
      <FormErrorMessage name="zipCode" />
    </div>
    <Field
      name="primaryAddress"
      type="text"
      placeholder="Primary address"
      className="mt-3 rounded border border-dark py-2 px-2"
    />
    <div className="mt-1">
      <FormErrorMessage name="primaryAddress" />
    </div>
  </div>
);

export default AddressBookInputs;
