import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import AsyncSelect from "react-select/async";
import {
  citiesQuery,
  citiesByCountryIdQuery,
} from "components/AuthModal/components/SignUpForm/components/CityField/query";
import { apolloClient } from "apollo/client";
import { defineMessages, useIntl } from "react-intl";
import { customStyles } from "./styles";

const placeholders = defineMessages({
  withoutCountry: {
    id: "CityField.withoutCountryV2",
    description:
      "Placeholder for autocomplete select when there's no country selected for CityField component",
    defaultMessage: "Primero selecciona una región",
  },
  withoutCountryV2: {
    id: "CityField.withoutCountryV3",
    description: "Default of Country field of the Contact form",
    defaultMessage: "Selecciona un pais",
  },
  withCountry: {
    id: "CityField.withCountry",
    description:
      "Placeholder for autocomplete select when there's a country selected for CityField component",
    defaultMessage: "Escribe tu ciudad",
  },
});

export default function CityField({
  regionId,
  searchByCountryId = false,
  isCustomStyles = false,
  cityByZipcode,
}) {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const [marchedCity, setMarchedCity] = useState(null);
  const placeholder = intl.formatMessage(
    regionId
      ? placeholders.withCountry
      : searchByCountryId
      ? placeholders.withoutCountryV2
      : placeholders.withoutCountry
  );

  useEffect(() => {
    const setRegionByZipCode = async () => {
      const city = await loadOptions(cityByZipcode);
      /**
       * WARNING:
       * Haciendo test con el codigo postal 110111 de bogota no funciona
       * ya que en la db el nombre es Bogota D.C y google maps retorna el Bogotá
       * por lo cual no hace match el nombre en la query de getRegions
       */
      if (city.length) {
        const [firstCity] = city;
        setMarchedCity(firstCity);
        setFieldValue("cityId", firstCity.value);
      }
    };

    if (regionId && cityByZipcode) {
      setRegionByZipCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, cityByZipcode]);

  // The functionality on this component should be able to run with useLazyQuery but that's not working because:
  // 1. It doesn't return a promise https://github.com/apollographql/react-apollo/issues/3371
  // 2. onCompleted doesn't works https://github.com/apollographql/react-apollo/issues/2177
  // TODO lets refactor this after they make it works
  // const [fetchCities, {loading, error, data}] = useLazyQuery(citiesQuery);

  /**
   * Load options through the GraphQL api
   * @param value {String}
   * @returns {Promise<Array>|Promise<any>}
   */
  function loadOptions(value) {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: !searchByCountryId ? citiesQuery : citiesByCountryIdQuery,
          variables: {
            regionId,
            query: value,
          },
        })
        .then((response) => {
          const {
            data: { cities },
          } = response;
          resolve(
            cities.edges.map((edge) => ({
              label: edge.node.name,
              value: edge.node.id,
            }))
          );
        });

      // This is the code supposed to work with using the hook
      /*fetchCities({
        variables: {
          regionId,
          query: value
        },
        fetchPolicy: "no-cache",
        onCompleted: data => {
          console.log(data);
          resolve([
            {
              label: "fuck yeah"
            }
          ])
        }
      });*/
    });
  }

  const handleOnChange = (name, value) => {
    setMarchedCity(null);
    setFieldValue(name, value);
  };

  return (
    <Field name="cityId">
      {({ field }) => (
          <AsyncSelect
          {...field}
          styles={(searchByCountryId || isCustomStyles) && customStyles}
          placeholder={placeholder}
          loadOptions={loadOptions}
          label={
            !marchedCity?.label
              ? field.value && field.value.label
              : marchedCity.label
          }
          value={
            !marchedCity?.value
              ? field.value && field.value.value
              : marchedCity
          }
          isDisabled={!regionId}
          onChange={(option) => handleOnChange(field.name, option.value)}
        />
      )}
    </Field>
  );
}

CityField.propTypes = {
  regionId: PropTypes.string,
  searchByCountryId: PropTypes.bool,
  isCustomStyles: PropTypes.bool,
  cityByZipcode: PropTypes.string,
};
