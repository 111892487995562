import {apolloClient} from "apollo/client";
import {setTokenExpirationDate, removeTokenExpirationDate, setToken, removeToken} from "services/auth/localStorage";
import {reduxStore} from "redux/store";
import {login, logout} from "services/auth/actions";
import {verifyToken} from "services/auth/mutations";

export const authLogin = async (token) => {
  const verifyTokenResponse = await apolloClient.mutate({
    mutation: verifyToken,
    variables: {
      input: {
        token
      }
    }
  });
  const {payload} = verifyTokenResponse.data.verifyToken;
  let tokenExpiresTime = null;
  if (payload) {
    // Convert exp time in minutes
    tokenExpiresTime = (payload.exp - payload.orig_iat) / 60;
    const expirationDate = new Date((new Date()).getTime() + tokenExpiresTime*60000);
    setToken(token);
    setTokenExpirationDate(expirationDate);
    reduxStore.dispatch(login());
  }
};

/**
 * Remove all tokens from the local storage
 */
export const authLogout = () => {
  removeToken();
  removeTokenExpirationDate();
  reduxStore.dispatch(logout());
};