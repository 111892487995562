import PropTypes from 'prop-types';
import "./styles/PlantDescription.scss";
import { FormattedMessage } from 'react-intl';

const PlantDescription = ({ description }) => {
  const { plantDescription, metricDescription, weArePresentsIn } = description;

  return (
    <div className='container pt-5' style={{ height: "fit-content" }}>
      <p>{plantDescription}</p>
      <p className='plant-description--metric'>
        {metricDescription}
      </p>
      <h2 className='text-center mt-5'>
        <FormattedMessage 
          id='CompanyPlants.plantDescription.weArePresentsIn'
          defaultMessage='We are present in:'
        />
      </h2>
      <div className='mt-5 d-flex align-items-center justify-content-center' style={{ height: "fit-content" }}>
        <img src={weArePresentsIn.src} className='img-fluid' alt='gif-image' />
      </div>
    </div>
  );
};

PlantDescription.propTypes = {
  description: PropTypes.object.isRequired,
};

export default PlantDescription;