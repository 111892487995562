import { defineMessages } from "react-intl";

export const NO_BIOCIDES = "NO BIOCIDES";
export const FORMALDEHYDE_FREE = "FORMALDEHYDE FREE";
export const NO_FLAME_RETARDANTS = "NO FLAME RETARDANTS";
export const ANTIFUNGAL = "ANTIFUNGAL";
export const PROP_65_COMPLIANT = "PROP 65 COMPLIANT";
export const POLYCARBONATE_RESIN_SYSTEM = "POLYCARBONATE RESIN SYSTEM";
export const ECOSENSE = "ECOSENSE";
export const SOFTSIDE = "SOFTSIDE";
export const PROSTOCK = "PROSTOCK";
export const COMPASS_HB = "COMPASS HB";
export const INTAGLIODESIGNS = "INTAGLIODESIGNS";
export const IPA_CLEANABLE = "IPA_CLEANABLE";
export const BLEACH_CLEANABLE = "BLEACH_CLEANABLE";
export const AB_1998_COMPLIANT = "AB_1998_COMPLIANT";
export const DENIM_DYE_RESISTANT = "DENIM_DYE_RESISTANT";
export const ANTISTATIC = "ANTISTATIC";
export const HEAT_SEALABLE = "HEAT_SEALABLE";
export const SULFIDE_STAIN_RESISTANT = "SULFIDE_STAIN_RESISTANT";
export const HYDROLYSIS = "HIDROLYSISNT";
export const MILDEW_RESISTANT = "MILDEW_RESISTANT";
export const ANTIBACTERIAL = "ANTIBACTERIAL";
export const HAS_NOT_HEAVY_METALS = "HAS_NOT_HEAVY_METALS";
export const PET_FRIENDLY = "PET_FRIENDLY";

const CHARACTERISTICS = defineMessages({
  [NO_BIOCIDES]: {
    id: `characteristics.${NO_BIOCIDES}`,
    defaultMessage: "No contiene biocidas",
  },
  [FORMALDEHYDE_FREE]: {
    id: `characteristics.${FORMALDEHYDE_FREE}`,
    defaultMessage: "Libre de formaldehído",
  },
  [NO_FLAME_RETARDANTS]: {
    id: `characteristics.${NO_FLAME_RETARDANTS}`,
    defaultMessage: "No contiene retardantes a la llama",
  },
  [ANTIFUNGAL]: {
    id: `characteristics.${ANTIFUNGAL}`,
    defaultMessage: "Antihongos",
  },
  [PROP_65_COMPLIANT]: {
    id: `characteristics.${PROP_65_COMPLIANT}`,
    defaultMessage: "Cumplimiento con proposición 65",
  },
  [POLYCARBONATE_RESIN_SYSTEM]: {
    id: `characteristics.${POLYCARBONATE_RESIN_SYSTEM}`,
    defaultMessage: "Sistema de resina a partir de policarbonato",
  },
  [ECOSENSE]: {
    id: `characteristics.${ECOSENSE}`,
    defaultMessage: "EcoSense",
  },
  [SOFTSIDE]: {
    id: `characteristics.${SOFTSIDE}`,
    defaultMessage: "Softside",
  },
  [PROSTOCK]: {
    id: `characteristics.${PROSTOCK}`,
    defaultMessage: "Prostock",
  },
  [COMPASS_HB]: {
    id: `characteristics.${COMPASS_HB}`,
    defaultMessage: "Compass HB",
  },
  [INTAGLIODESIGNS]: {
    id: `characteristics.${INTAGLIODESIGNS}`,
    defaultMessage: "Intagliodesigns",
  },
  [IPA_CLEANABLE]: {
    id: `characteristics.${IPA_CLEANABLE}`,
    defaultMessage: "Limpiable con Alcohol (10%)",
  },
  [BLEACH_CLEANABLE]: {
    id: `characteristics.${BLEACH_CLEANABLE}`,
    defaultMessage: "Limpiable con blanqueador",
  },
  [AB_1998_COMPLIANT]: {
    id: `characteristics.${AB_1998_COMPLIANT}`,
    defaultMessage: "Cumple con AB 1998",
  },
  [DENIM_DYE_RESISTANT]: {
    id: `characteristics.${DENIM_DYE_RESISTANT}`,
    defaultMessage: "Resistente al tinte",
  },
  [ANTISTATIC]: {
    id: `characteristics.${ANTISTATIC}`,
    defaultMessage: "Anti estático",
  },
  [HEAT_SEALABLE]: {
    id: `characteristics.${HEAT_SEALABLE}`,
    defaultMessage: "Termosellable",
  },
  [SULFIDE_STAIN_RESISTANT]: {
    id: `characteristics.${SULFIDE_STAIN_RESISTANT}`,
    defaultMessage: "Resistente a las manchas de sulfuro",
  },
  [HYDROLYSIS]: {
    id: `characteristics.${HYDROLYSIS}`,
    defaultMessage: "Hidrólisis",
  },
  [MILDEW_RESISTANT]: {
    id: `characteristics.${MILDEW_RESISTANT}`,
    defaultMessage: "Resistente al moho",
  },
  [ANTIBACTERIAL]: {
    id: `characteristics.${ANTIBACTERIAL}`,
    defaultMessage: "Antibacterial",
  },
  [HAS_NOT_HEAVY_METALS]: {
    id: `characteristics.${HAS_NOT_HEAVY_METALS}`,
    defaultMessage: "No contiene metales pesados",
  },
  [PET_FRIENDLY]: {
    id: `characteristics.${PET_FRIENDLY}`,
    defaultMessage: "Pet Friendly",
  },
});

export default CHARACTERISTICS;
