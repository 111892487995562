import PropTypes from "prop-types";
import ProductCard from "modules/products/ProductListPage/components/ProductList/components/ProductCard";

export default function ProductsGrid({ patterns, loading = false }) {
  return (
    <div className="products-grid row small-gutters regular-gutters-md">
      {loading || !patterns
        ? [1, 2, 3, 4, 5, 6].map((item) => (
            <div
              key={item}
              className="col-6 col-xl-4 mb-small-gutter mb-regular-gutter-md"
            >
              <ProductCard loading />
            </div>
          ))
        : patterns.map(({ node }) => (
            <div
              key={node.id}
              className="col-6 col-xl-4 mb-small-gutter mb-regular-gutter-md"
            >
              <ProductCard pattern={node} />
            </div>
          ))}
    </div>
  );
}

ProductsGrid.propTypes = {
  patterns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};
