import { SUSTAINABILITY_ATTRIBUTES } from "./attributesByGroups"

export const deleteGuionFromAttributes = (attr) => {
  if (
    typeof attr.value === "string" && 
    attr.title !== SUSTAINABILITY_ATTRIBUTES["attributes"].epd
    ) {
    let valueWithoutGuion = attr.value.split('_').join(' ')
    attr.value = valueWithoutGuion
  }
}

export const handleValidationEpdAttr = (attr) => {
  if (attr.title === SUSTAINABILITY_ATTRIBUTES["attributes"].epd) {
    if (attr.certificate !== "") {
      attr.value = attr.certificate
    }
    return true
  }
  return false
}

export const handleValidationBackingAttr = (attr) => {
  if (attr.title === SUSTAINABILITY_ATTRIBUTES["attributes"].backingProfile) {
    return true
  }
}