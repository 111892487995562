import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player";
import { Button } from "reactstrap";
import { getCurrentLanguageAndInstanceCode } from "services/instances";
import plantColombiaPoster from "./img/poster-colombia.png";
import plantCostaRicaPoster from "./img/poster-costa-rica.png";
import playIcon from "./img/play-icon-video-plant.png";
import classNames from "classnames";
import { COLOMBIA_PLANT, COSTA_RICA_PLANT } from "../Banner/constants/bannerItems";
import "./styles/OurEssenceSection.scss";

const PLANTS_VIDEO_BY_LANG = {
  es: "https://youtu.be/B4h_Oio4Ar8",
  en: "https://youtu.be/Iz9WqgUukV0",
};

const POSTER_PLANT = {
  [COLOMBIA_PLANT]: plantColombiaPoster,
  [COSTA_RICA_PLANT]: plantCostaRicaPoster
}

const LINK_VACANCIES_PLANT = {
  [COLOMBIA_PLANT]: 'https://vacantes.spradling.group/es/proquinalcolombia',
  [COSTA_RICA_PLANT]: 'https://vacantes.spradling.group/es/proquinalcostarica'
}

const OurEssenceSection = ({ plantSlug }) => {
  const [lang] = getCurrentLanguageAndInstanceCode();
  const [playVideo, setPlayVideo] = useState(false);

  const handleVideoClick = () => {
    setPlayVideo(true);
  };

  const showVideo = {
    display: playVideo ? "block" : "none"
  }

  return (
    <div className='container text-center mb-5 pt-2'>
      <h1>
        <FormattedMessage 
          id="CompanyPlants.ouseEssenceSection.title.ourEssence"
          defaultMessage="Our Essence"
        />
      </h1>
      <div className='our-essence--video-container mt-5 d-flex align-items-center justify-content-center bg-light'>
        <div className={classNames({
          "border-dark our-essence--poster-container": true,
          "d-none": playVideo
          })}>
          <img
            className="img-fluid w-100 h-100"
            src={POSTER_PLANT[plantSlug]}
            alt="image-poster"
          />
          <img 
            className="our-essence--poster-icon-play"
            src={playIcon} 
            alt="play-icon-image" 
            onClick={handleVideoClick} 
          />
        </div>
        <ReactPlayer
          loop
          width="100%"
          height="100%"
          controls={true}
          playing={playVideo}
          url={PLANTS_VIDEO_BY_LANG[lang]}
          style={showVideo}
        />
      </div>
      <div className='text-center mb-5 mt-5'>
        <a href={LINK_VACANCIES_PLANT[plantSlug]} target="_blank" rel="noreferrer" className='btn_work_with_us text-white py-3 pr-5 pl-5 rounded-pill text-decoration-none'>
          <FormattedMessage 
            id="CompanyPlants.ouseEssenceSection.textBtn.workWithUs"
            defaultMessage="Work with us"
          />
        </a>
      </div>
    </div>
  );
};
    
OurEssenceSection.propTypes = {
  plantSlug: PropTypes.string
}

export default OurEssenceSection;