import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import { FormattedMessage } from "react-intl";
import HeroTitle from "utils/components/HeroTitle";
import TitleUnderlined from "utils/components/TitleUnderlined";
import SubHeroContent from "utils/components/SubHeroContent";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import heroBg from "./img/hero-background-por-que-escogernos.jpg";
import heroBgResources from "./img/hero-choose-us-SIR.jpg";
import FullWidthArticles from "utils/components/FullWidthArticles";
import {
  DESIGNS,
  TITLES,
  RESOURCES_DESIGNS,
} from "modules/about/WhyChooseUsPage/constants/designs";
import { isMARINE, isRESOURCES } from "services/instances";
import SEO from "services/seo/components/SEO";
import { WHY_CHOOSE_US_SCENE } from "services/seo/constants/scenes";

const WhyChooseUsPage = () => (
  <div className="why-choose-us">
    <ScrollTopOnMount />
    <HeroTitle
      title={
        <FormattedMessage
          {...(!isRESOURCES() ? TITLES.global : TITLES.resources)}
        />
      }
      backgroundImage={!isRESOURCES() ? heroBg : heroBgResources}
    />
    <SubHeroContent theme={!isMARINE() ? "red" : "prussian-blue"}>
      <div className="container text-center pt-4 pb-4 pt-lg-5 pb-lg-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="WhyChooseUsPage.subHeroTitle"
                description="Why choose us page sub header title"
                defaultMessage="¿Por qué escogernos?"
              />
            </TitleUnderlined>
            <p className="mb-4 mb-lg-3">
              {!isRESOURCES() ? (
                <FormattedMessage
                  id="WhyChooseUsPage.subHeroContent"
                  description="Why choose us page sub header content"
                  defaultMessage="Nuestro compromiso es ofrecer productos que respondan a las exigencias técnicas de los mercados, haciendo más atractivos los espacios, con una alta calidad para que perduren en el tiempo. El diseño, nuestras tecnologías y los tejidos que desarrollamos para que soporten nuestras telas nuestras telas nos hacen diferentes y uno de los líderes de la industria."
                />
              ) : (
                <FormattedMessage
                  id="WhyChooseUsPage.subHeroContent.resources"
                  description="Why choose us resources page sub header content"
                  defaultMessage="Spradling International, Inc<sup>®</sup> is your One-Stop-Shop for world-class coated fabrics and value-added services."
                  values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </SubHeroContent>
    <TitleUnderlined
      hTag={H2_TAG}
      className="text-size-h1 text-weight-bold mb-4 pt-4 pt-lg-5 mb-lg-5"
    >
      <FormattedMessage
        id="WhyChooseUsPage.design"
        description="Why choose us page 'design' title"
        defaultMessage="Diseño"
      />
    </TitleUnderlined>
    <FullWidthArticles
      articles={!isRESOURCES() ? DESIGNS : RESOURCES_DESIGNS}
    />
    {!isRESOURCES() && (
      <div className="container text-center p-4 pt-lg-5">
        <TitleUnderlined
          hTag={H2_TAG}
          className="text-size-h1 text-weight-bold"
        >
          <FormattedMessage
            id="WhyChooseUsPage.whyUseCoatedFabrics"
            description="Why choose us page 'Why use Coated Fabrics?' title"
            defaultMessage="¿Por qué usar Tejidos Recubiertos?"
          />
        </TitleUnderlined>
        <div className="mb-4 mb-lg-3">
          <FormattedMessage
            id="WhyChooseUsPage.whyUseCoatedFabricsContentV2"
            description="Why choose us page 'Why use Coated Fabrics?' content"
            defaultMessage={
              "<p>Son impermeables</p>" +
              "<p>Fácil de limpiar, fácil de cuidar</p>" +
              "<p>Resistentes al degaste, bajas temperaturas y rayos UV</p>" +
              "<p>Ayudan a cumplir regulaciones gracias a sus propiedades ignífugas.</p>" +
              "<p>Proporcionan una barrera a las manchas, bacterias y hongos</p>" +
              "<p>Son un material rentable</p>" +
              "<p>Versatilidad de uso y apariencia</p>"
            }
            values={{
              p: (...chunks) => <p>{chunks}</p>,
            }}
          />
        </div>
      </div>
    )}
    <SEO scene={WHY_CHOOSE_US_SCENE} />
  </div>
);

export default WhyChooseUsPage;
