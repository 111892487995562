import gql from "graphql-tag";

const faqsQuery = gql`
    query FAQsQuery($instances: [ID]){
        faqs(instances: $instances) {
            edges {
                node {
                    id
                    question
                    answer
                }
            }
        }
    }
`;

export default faqsQuery;