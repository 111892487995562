import roll from './img/roll.svg';
import './styles/QuickshipBanner.scss';
import quickshipArrowDown from './img/quickshipArrowDown.svg';
import { MockHowitWorksList } from './Constants/MockHowitWorksList';
import { HowitWorksList } from './Components/HowitWorksList';
import imgLeftDesktop from './img/imgLeft.png';
import imgLeftMobilefrom from './img/imgLeft_Mobile.png';
import imgRightDesktop from './img/imgRight.png'
import imgRightMobile from './img/imgRight_Mobile.png'
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const QuickshipBanner = () => {

  const { language } = useParams();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div className='main-container-banner-home-quickship-page'>
      <div className='quickship--banner-container d-flex'>
        {/* Columna 1 */}
        <div className='quickship--banner-left-container responsive-banner-quickship d-flex flex-column'>
            <div className='conatiner--img--home-baner'>
              <img src={isMobile ? imgLeftMobilefrom : imgLeftDesktop} alt="img left" />
            </div>
          <div className='container--andtitle-descriptcion'>
              <h6 className='quickship--banner-about-title mb-3'>
                {language === 'es' ? 'Sobre Quickship': 'About Quickship'}
              </h6>
              <p className='quickship--banner-description md:ml-5'>
                {language === 'es' ? 
                <>
                  En el mundo empresarial de hoy, donde todo avanza rápidamente, el tiempo es crucial. Por eso, Spradling ha seleccionado una amplia gama de nuestros patrones más populares en nuestro programa Quickship.
                </>
                : 
                <>
                  In today’s fast-paced business world, time is crucial. That’s why Spradling has curated a comprehensive selection of our most popular patterns in our Quickship program.
                </>
                }
              </p>
              <div className='text-center md-pt-5'>
                <img
                  src={roll}
                  alt='roll-image'
                  className='img-fluid mx-auto'
                  width={169}
                  height={60}
                />
              </div>
              <p className='quickship--banner-description md:px-5 pt-2 md-pt-5 mt-1 md:mt-3'>
              <div>
                {language === 'es' ? (
                  <>
                    Mantenemos un inventario de más de <strong>500 yardas</strong> por SKU, asegurando que puedas cumplir con los pedidos de manera rápida. El programa Quickship de Spradling está diseñado para ofrecer envíos acelerados a los clientes que necesitan entregas en un plazo ajustado.
                  </>
                ) : (
                  <>
                    We maintain an inventory of over <strong>500 yards</strong> per SKU, ensuring you can fulfill orders promptly. Spradling’s Quickship program is designed to provide expedited shipping for customers needing delivery on a tight schedule.
                  </>
                )}
              </div>
              </p>
          </div>

          <div className='quickship--banner-btn-arrow-down d-flex flex-column justify-content-center align-items-center'>
            <a
              href='#section1'
              className='d-flex flex-column justify-content-center align-items-center'
            >
              {
                !isMobile && 
                <img
                  className='animationImgArrowDown'
                  src={quickshipArrowDown}
                  alt='quickshipArrowDown'
                  width={50}
                />
              }
              <p className={`${isMobile && 'btn-Mobile-home-quickship-page'}`}>
                {language === 'es' ? 'Ver colores': 'View colors'}
              </p>
            </a>
          </div>
        </div>

        {/* Columna 2 */}
        <div className='quickship--banner-right-container responsive-banner-quickship'>
          <div className='p-0'>
            <h6 className='quickship--banner-how-works-title px-5 mb-3'>
              {language === 'es' ? 'Cómo funciona': 'How it works'}
            </h6>
            {MockHowitWorksList?.map((item, index) => {
              
              if (!item) return null;
              
              return (
                <HowitWorksList
                  key={index}
                  img={item.img}
                  alt={item.alt}
                  text={item.text}
                  textEn={item.textEn}
                  lang={language}
                />
              )
            }
            )}
          </div>
          <div className='conatiner--img--home-baner'>
            <img src={isMobile ? imgRightMobile : imgRightDesktop} alt="img left" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickshipBanner;
