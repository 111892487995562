import {connect} from "react-redux";
import HeaderMainMenu from "../index";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const HeaderMainMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderMainMenu);

export default HeaderMainMenuContainer;