import { FormattedMessage } from "react-intl"

export const getPatternData = (item) => {
  let patternColor = item.pattern !== null ? item.pattern.color : item.product.color
	let patternImage = item.pattern !== null ? item.pattern.imageThumbSmall : item.product.imageThumbSmall
  let patternType = item.pattern !== null ? <FormattedMessage id="RequestedSampleCard.sampleCard" /> : item.product.color
  let patternStock = item.product !== null ? item.product.stock : null

  return {
    patternColor,
    patternImage,
    patternType,
    patternStock
  }
}