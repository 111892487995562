import Slider from "react-slick";
import slider1 from "./imgs/slider-1.jpg";
import slider4 from "./imgs/slider-4.jpg";
import slider5 from "./imgs/slider-5.jpg";
import "./styles/hero-slider.scss";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";

const WE_ARE_SPRADLING_SLIDER_OPTIONS = {
  dots: true,
  infinite: true,
  lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
};

const WE_ARE_SPRADLING_SLIDERS = [
  { id: "slider1", img: slider1 },
  { id: "slider4", img: slider4 },
  { id: "slider5", img: slider5 },
];

const HeroSlider = () => (
  <section className="overflow-hidden">
    <Slider {...WE_ARE_SPRADLING_SLIDER_OPTIONS}>
      {WE_ARE_SPRADLING_SLIDERS.map(({ id, img }) => (
        <Fragment key={`key_${id}`} className="position-relative">
          <div
            key={`key_${id}`}
            className="main--hero-slide"
            style={{ backgroundImage: `url(${img})` }}
          />
          <div className="hero-title__content">
            <div className="container">
              <h1 className="hero-title-heading">
                <FormattedMessage
                  id="HomeAbout.HeroSlider.title"
                  defaultMessage="Spradling tapicerías de alta calidad"
                />
              </h1>
            </div>
          </div>
        </Fragment>
      ))}
    </Slider>
  </section>
);

export default HeroSlider;
