import gql from "graphql-tag";

const singlePostQuery = gql`
  query PostQuery ($slug: String) {
    post(slug: $slug) {
      id
      title
      content
      tags
    }
  }
`;

export default singlePostQuery;
