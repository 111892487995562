import {connect} from "react-redux";
import LocationsList from "../index";
import {setHighlightedStoreId, setNearbyStores} from "modules/stores/WhereToBuyPage/actions";

const getPatternId = state => state.locationReducer.patternId
const getCityId = state => state.locationReducer.cityId
const getNearbyStores = state => state.locationReducer.nearbyStores
const getStoreId = state => state.locationReducer.highlightedStoreId

const mapStateToProps = (state) => {
  return {
    patternId: getPatternId(state),
    cityId: getCityId(state),
    // TODO use reselect to filter by city
    nearbyStores: getNearbyStores(state),
    highlightedStoreId: getStoreId(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNearbyStores: nearbyStores => dispatch(setNearbyStores(nearbyStores)),
    setHighlightedStoreId: highlightedStoreId => dispatch(setHighlightedStoreId(highlightedStoreId))
  };
};

const LocationsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationsList);

export default LocationsListContainer;