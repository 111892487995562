import PropTypes from "prop-types";
import "./styles/HeaderBurger.scss";

const HeaderBurger = ({headerReducer, closeMobileMenu, openMobileMenu}) => {
  /**
   * Handles click on the burger button
   * @param {Object} event
   */
  const onClick = () => {
    if (headerReducer.mobileMenuIsOpen)
      closeMobileMenu();
    else
      openMobileMenu();
  };

  return (
      <div
          className="header-burger"
          onClick={onClick}>
        <div className="burger-lines">
          {
            [1, 2, 3].map(number =>
                <span key={number} className="burger-lines__line"/>
            )
          }
        </div>
      </div>
  )
};

HeaderBurger.propTypes = {
  headerReducer:  PropTypes.shape({
    mobileMenuIsOpen: PropTypes.bool
  }),
  closeMobileMenu: PropTypes.func,
  openMobileMenu: PropTypes.func
};


export default HeaderBurger;