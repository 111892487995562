import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useLazyQuery } from "@apollo/react-hooks";
import { SAMPLES_CART } from "routes";
import cartQuery from "components/Header/components/HeaderCartButton/query";
import {
  getCurrentInstanceId,
  getTranslatedLink,
  isMARINE,
  isRESOURCES,
  isUSA,
} from "services/instances";
import HeaderSubInstancesUsa from "../HeaderSubInstancesUsa";
import useMediaQuery from "utils/hooks/useMediaQuery";

export default function HeaderCartButton({ cartId, setCartId, clearCartId }) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isHeaderSubInstancesUsa = isUSA() || isMARINE() || isRESOURCES();
  const [fetchCart, { called, loading, error, data }] = useLazyQuery(
    cartQuery,
    {
      variables: {
        id: cartId,
        instanceId: getCurrentInstanceId(),
      },
    }
  );
  // WARNING: function handleLocalStorageCardId to remove from storage if cart doesn't have instance
  const handleLocalStorageCard = (data) => {
    const {
      cart: { id, instance },
    } = data;

    if (!instance) {
      clearCartId();
    } else {
      memoizedSetCartId(id);
    }
  };

  const memoizedFetchCart = useCallback(fetchCart, [fetchCart]);
  const memoizedSetCartId = useCallback(setCartId, [setCartId]);
  const memoizedHandleLocalStorageCardId = useCallback(handleLocalStorageCard, [
    handleLocalStorageCard,
  ]);

  /**
   * Fetch the cart when the cartId from headerReducer changes
   */
  useEffect(() => {
    if (cartId) memoizedFetchCart();
  }, [cartId, memoizedFetchCart]);

  /**
   * Sets the cart id in redux when the fetchCart function gets called and completes the query
   */
  useEffect(() => {
    if (called && !loading && !error && data && data.cart) {
      memoizedHandleLocalStorageCardId(data);
    }
  }, [called, loading, error, data, memoizedHandleLocalStorageCardId]);

  return (
    <div className="spglobal-header__cart">
      <div className="container">
        <div className="row">
          {isDesktop && isHeaderSubInstancesUsa && (
            <div className="col col-md-9">
              <HeaderSubInstancesUsa isDesktop={isDesktop} />
            </div>
          )}
          <div className="col my-1 text-right">
            <Link
              className="spglobal-header__cart--button"
              to={getTranslatedLink(SAMPLES_CART)}
            >
              <i className="fa fa-shopping-cart" />
              &nbsp;
              <FormattedMessage
                id="HeaderCartButton.title"
                defaultMessage="Mi carrito de muestras"
              />
              <span>
                &nbsp; (
                {loading
                  ? "..."
                  : data && data.cart
                  ? data.cart.items.edges.length
                  : "0"}
                )
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HeaderCartButton.propTypes = {
  cartId: PropTypes.string,
  setCartId: PropTypes.func,
  clearCartId: PropTypes.func,
};
