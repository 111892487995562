import gql from "graphql-tag";

const singleVacantQuery = gql`
  query SingleVacantQuery($slug: String) {
      vacant (slug: $slug) {
          id
          name
          description
          city {
              id
              name
              country {
                  id
                  name
              }
          }
      }
  }
`;

export default singleVacantQuery;