/* eslint-disable react/prop-types */
import { useState } from "react";
import { FormattedMessage } from "react-intl";
// PROCESS
import ISO50001 from "../imgs/tabs/process/1.svg";
import ISO14001 from "../imgs/tabs/process/2.svg";
import ISO45001 from "../imgs/tabs/process/3.svg";
import carbonNeutral from "../imgs/tabs/process/4.svg";
// COMPLIANCES
import reach from "../imgs/tabs/compliances/1.svg";
import rohs from "../imgs/tabs/compliances/2.svg";
import prop65 from "../imgs/tabs/compliances/3.svg";
// CERTIFICATIONS
import greenguard from "../imgs/tabs/certifications/1.svg";
import indoorAirQuality from "../imgs/tabs/certifications/2.svg";
import globalRecycledStandard from "../imgs/tabs/certifications/3.svg";
import fsc from "../imgs/tabs/certifications/4.svg";
// PROCESSES
import basc from "../imgs/tabs/processes/1.png";
import med from "../imgs/tabs/processes/2.svg";
import aeo from "../imgs/tabs/processes/3.png";
import iatf from "../imgs/tabs/processes/4.svg";
import iso9001 from "../imgs/tabs/processes/5.svg";
import uca from "../imgs/tabs/processes/6.png";
// SEALS
import leed from "../imgs/tabs/seals/1.svg";
import well from "../imgs/tabs/seals/2.svg";

const tabOptions = [
  <FormattedMessage
    key="EcosensePage.v2.Certifications.process.title"
    id="EcosensePage.v2.Certifications.process.title"
    defaultMessage="Process Certifications"
  />,
  <FormattedMessage
    key="EcosensePage.v2.Certifications.compliances.title"
    id="EcosensePage.v2.Certifications.compliances.title"
    defaultMessage="Compliances"
  />,
  <FormattedMessage
    key="EcosensePage.v2.Certifications.product.title"
    id="EcosensePage.v2.Certifications.product.title"
    defaultMessage="Product Certifications"
  />,
  <FormattedMessage
    key="EcosensePage.v2.Certifications.manufacturing.title"
    id="EcosensePage.v2.Certifications.manufacturing.title"
    defaultMessage="Manufacturing Processes"
  />,
  <FormattedMessage
    key="EcosensePage.v2.Certifications.sealsWeContribute.nav"
    id="EcosensePage.v2.Certifications.sealsWeContribute.nav"
    defaultMessage="Certifications we Contribute to"
  />,
];

export const Certifications = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <div className="container-fluid tabs-container">
      <div className="row d-flex justify-content-center">
        <div className="col-10 col-lg-9 col-md-9 col-sm-12 d-flex flex-column justify-content-center">
          <div className="row my-5">
            <div className="col-12 text-center">
              <span className="tabs-title">
                <FormattedMessage
                  id="EcosensePage.v2.Certifications.title"
                  defaultMessage="Certifications of excellence"
                />
              </span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex overflow-auto flex-nowrap justify-content-between align-content-center align-items-center">
              {tabOptions.map((option, index) => (
                <div
                  key={`key_${option.key}`}
                  className={`tab-option lh-1 ${
                    selectedItem === index ? "active-tab" : ""
                  }`}
                  onClick={() => setSelectedItem(index)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          <div className="my-5">
            {selectedItem === 0 && <ProcessCertifications />}
            {selectedItem === 1 && <Compliances />}
            {selectedItem === 2 && <CertificationsContent />}
            {selectedItem === 3 && <Processes />}
            {selectedItem === 4 && <Seals />}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubTitle = ({ title }) => (
  <div className="row">
    <div className="col-12 text-center">
      <span className="tab-subtitle">{title}</span>
    </div>
  </div>
);

const CardItem = ({
  image,
  title,
  subtitle = "",
  content,
  classes,
  centered = false,
}) => {
  return (
    <div className={`${classes} tab-spacing`}>
      <div className="row">
        <div
          className="col-12 d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <img src={image} alt="ISO 50001" className="img-fluid" />
        </div>
      </div>
      {title && title != "" ? (
        <div className="row mt-3 mb-2">
          <div className="col-12 text-center">
            <span className="tab-card-title">{title}</span>
          </div>
        </div>
      ) : (
        <div className="row mt-3 mb-3">
          <span>&nbsp;</span>
        </div>
      )}
      {subtitle && subtitle != "" && (
        <div className="row mt-3 mb-2">
          <div className="col-12 text-center">
            <span className="tab-card-subtitle">{subtitle}</span>
          </div>
        </div>
      )}
      <div className={`row ${centered ? "tab-text-center" : ""}`}>
        <div className="col-12">
          <span className="tab-card-content">{content}</span>
        </div>
      </div>
    </div>
  );
};

const ProcessCertifications = () => {
  return (
    <div className="row">
      <div className="col-12">
        <SubTitle
          title={
            <FormattedMessage
              id="EcosensePage.v2.Certifications.process.title"
              defaultMessage="Process Certifications"
            />
          }
        />
        <div className="row d-flex justify-content-between">
          <CardItem
            image={ISO50001}
            title="ISO 50001"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.process.iso50001.content"
                defaultMessage="Enhancing our commitment to superior energy management, this certification promotes efficient, sustainable energy practices across our operations."
              />
            }
            classes="col-12 col-lg-5 col-md-5 col-sm-12 process_certifications"
          />
          <CardItem
            image={ISO14001}
            title="ISO 14001"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.process.iso14001.content"
                defaultMessage="Strengthening our environmental consciousness, this international standard validates our focused efforts to reduce ecological impact."
              />
            }
            classes="col-12 col-lg-5 col-md-5 col-sm-12 process_certifications"
          />
          <CardItem
            image={ISO45001}
            title="ISO 45001"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.process.iso45001.content"
                defaultMessage="Safeguarding employee wellbeing, this global standard verifies our pledge to maintain a safe and optimal working environment for all."
              />
            }
            classes="col-12 col-lg-5 col-md-5 col-sm-12 process_certifications"
          />
          <CardItem
            image={carbonNeutral}
            title="Carbon Neutral"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.process.carbonNeutral.content"
                defaultMessage="We support initiatives that generate clean energy and programs harnessing energy from unconventional sources. Additionally, we back projects aimed at reforesting forests to protect fauna and flora."
              />
            }
            classes="col-12 col-lg-5 col-md-5 col-sm-12 process_certifications"
          />
        </div>
      </div>
    </div>
  );
};

const Compliances = () => {
  return (
    <div className="row">
      <div className="col-12">
        <SubTitle
          title={
            <FormattedMessage
              id="EcosensePage.v2.Certifications.compliances.title"
              defaultMessage="Compliances"
            />
          }
        />
        <div className="row d-flex justify-content-between">
          <CardItem
            image={reach}
            title="REACH"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.compliances.REACH"
                defaultMessage="We ensure compliance with EU chemical safety regulations guaranteeing the safe handling and usage of chemicals in our products."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={rohs}
            title="ROHS"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.compliances.ROHS"
                defaultMessage="Our commitment to consumer safety demonstrates our adherence to regulations limiting hazardous substances."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={prop65}
            title="PROP 65"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.compliances.PROP65"
                defaultMessage="Adhering to California labeling regulations for safety and transparency, we provide clear warnings about exposure to harmful chemicals in our products."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
        </div>
      </div>
    </div>
  );
};

const CertificationsContent = () => {
  return (
    <div className="row">
      <div className="col-12">
        <SubTitle
          title={
            <FormattedMessage
              id="EcosensePage.v2.Certifications.product.title"
              defaultMessage="Product Certifications"
            />
          }
        />
        <div className="row d-flex justify-content-between">
          <CardItem
            image={greenguard}
            title="Greenguard"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.product.greenguard.content"
                defaultMessage="Our products contribute to healthier indoor environments by meeting stringent indoor air quality requirements."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={indoorAirQuality}
            title="Indoor Air Quality"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.product.indoorAirQuality.content"
                defaultMessage="Complying with rigorous air purity criteria, facilitating a healthier indoor environment."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={globalRecycledStandard}
            title="Global Recycled Standard"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.product.globalRecycledStandard.content"
                defaultMessage="We ensure that our products meet rigorous criteria for recycled content and environmental responsibility."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <div className="col-12">
            <SubTitle title="Packaging" />
          </div>
          <CardItem
            image={fsc}
            title="FSC"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.product.FSC.content"
                defaultMessage="This mark ensures that the materials we use like cardboard are sourced from responsibly managed forests, supporting conservation efforts and community engagement."
              />
            }
            classes="col-12 col-lg-12 col-md-12 col-sm-12"
            centered={true}
          />
        </div>
      </div>
    </div>
  );
};

const Processes = () => {
  return (
    <div className="row">
      <div className="col-12">
        <SubTitle
          title={
            <FormattedMessage
              id="EcosensePage.v2.Certifications.manufacturing.title"
              defaultMessage="Manufacturing Processes"
            />
          }
        />
        <div className="row d-flex justify-content-between">
          <CardItem
            image={basc}
            title="BASC"
            subtitle={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.BASC.subtitle"
                defaultMessage="(Business Alliance for Secure Commerce)"
              />
            }
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.BASC.content"
                defaultMessage="We demonstrate our dedication to secure international trade practices, while fostering legitimate trade relationships."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={med}
            title="MED"
            subtitle={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.MED.subtitle"
                defaultMessage="(Marine Equipment Directive)"
              />
            }
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.MED.content"
                defaultMessage="Our commitment to essential safety standards for marine equipment guarantees the quality and safety of our products for marine applications."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={aeo}
            title="AEO"
            subtitle={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.AEO.subtitle"
                defaultMessage="(Authorized Economic Operator)"
              />
            }
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.AEO.content"
                defaultMessage="Our Colombia facility, showcases our commitent to secure international trade and compliance with customs security standarts, facilitating smoother customs procedures."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={iatf}
            title="IATF 16949"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.IATF16949.content"
                defaultMessage="The certification symbolizes our dedication as an automotive industry supplier to deliver high-quality products and surpass industry standards."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={iso9001}
            title="ISO 9001"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.ISO9001.content"
                defaultMessage="We prioritize quality management and customer satisfaction reflecting our dedication to meeting customer requirements and operational excellence."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
          <CardItem
            image={uca}
            title="UCA"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.manufacturing.UCA.content"
                defaultMessage="The UCA Certification ensures that products meet the necessary standards for use in automobiles in the Spanish market."
              />
            }
            classes="col-12 col-lg-4 col-md-4 col-sm-12"
          />
        </div>
      </div>
    </div>
  );
};

const Seals = () => {
  return (
    <div className="row">
      <div className="col-12">
        <SubTitle
          title={
            <FormattedMessage
              id="EcosensePage.v2.Certifications.sealsWeContribute.title"
              defaultMessage="Seals we Contribute to"
            />
          }
        />
        <div className="row d-flex justify-content-between">
          <CardItem
            image={leed}
            title="LEED"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.sealsWeContribute.LEED.content"
                defaultMessage="Strengthening our environmental consciousness, this international  standard validates our focused efforts to reduce ecological impact."
              />
            }
            classes="col-12 col-lg-6 col-md-6 col-sm-12 px-4"
          />
          <CardItem
            image={well}
            title="WELL"
            content={
              <FormattedMessage
                id="EcosensePage.v2.Certifications.sealsWeContribute.WELL.content"
                defaultMessage="Enhancing our commitment to superior energy management, this certification  promotes efficient, sustainable energy practices across our operations."
              />
            }
            classes="col-12 col-lg-6 col-md-6 col-sm-12 px-4"
          />
        </div>
      </div>
    </div>
  );
};
