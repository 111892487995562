import gql from "graphql-tag";

export const createJobApplicationMutation = gql`
  mutation CreateJobApplicationMutation(
    $instanceId: ID!
    $file: Upload!
    $vacantId: ID
    $fullName: String!
    $email: String!
    $phone: String!
    $linkedIn: String!
    $cityId: ID!
  ) {
    createJobApplication(
      instanceId: $instanceId
      file: $file
      vacantId: $vacantId
      fullName: $fullName
      email: $email
      phone: $phone
      linkedIn: $linkedIn
      cityId: $cityId
    ) {
      jobApplication {
        id
        fullName
      }
    }
  }
`;
