const { useState } = require("react");
import {
  getCurrentLanguageAndInstanceCode,
  isMARINE,
  isMEXICO,
  isRESOURCES,
  isUSA,
} from "services/instances";

const useLocationByZipcode = () => {
  const enable = isMEXICO() || isUSA() || isMARINE() || isRESOURCES();
  const [locationByZipcode, setLocationByZipcode] = useState(null);

  const handleGetLocation = async (zipcode, callback) => {
    if (zipcode) {
      try {
        const [lang] = getCurrentLanguageAndInstanceCode();
        const { results } = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?language=${lang}&address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
        )
          .then((response) => response.json())
          .then((data) => data);

        const locationsByZipcode = handleTransformData(results);
        callback(locationsByZipcode);
      } catch (e) {
        console.log("ERROR: useLocationByZipcode: ", e);
      }
    }
  };

  const handleTransformData = (data) => {
    let newArray = {};
    if (data.length) {
      const { address_components } = data[0];

      address_components.map(({ long_name, short_name, types }) => {
        switch (types[0]) {
          case "postal_code":
            newArray.zipcode = long_name;
            break;
          case "locality":
            newArray.city = long_name;
            break;
          case "administrative_area_level_1":
            newArray.state = long_name;
            break;
          case "country":
            newArray.country = short_name;
            break;

          default:
            break;
        }
      });
    }
    return newArray;
  };

  const handleLocationByZipcode = (zipcode) => {
    if (enable) handleGetLocation(zipcode, setLocationByZipcode);
  };

  return { locationByZipcode, handleLocationByZipcode };
};

export default useLocationByZipcode;
