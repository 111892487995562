import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import ProductsList from "modules/products/ProductListPage/components/ProductList";
import './styles/ProductsListPage.scss'
import FiltersBar from "modules/products/ProductListPage/components/FiltersBar";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import {QUERY_PARAM_SECTOR} from "modules/products/ProductListPage/constants";
import SEO from "services/seo/components/SEO";
import {PRODUCTS_LIST_SCENE} from "services/seo/constants/scenes";
import QueryParams from "services/browser-history/QueryParams";
import ProductsPageTitle from "modules/products/ProductListPage/components/ProductsPageTitle";
import {Element} from "react-scroll";

export default function ProductListPage({match}) {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const sectorId = queryParams.find(QUERY_PARAM_SECTOR);

  return (
      <div className="product-list-page">
        <ScrollTopOnMount/>
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <ProductsPageTitle/>
            </div>
            <div className="col-12 col-md-4 col-xl-3">
              <FiltersBar/>
            </div>
            <div className="col-12 col-md-8 col-xl-9 pb-5">
              <Element name="productListElement">
                <ProductsList/>
              </Element>
            </div>
          </div>
        </div>
        {
          sectorId
              ?
              <SEO scene={`sector-${match.params.language}-${sectorId}`}/>
              :
              <SEO scene={PRODUCTS_LIST_SCENE}/>
        }
      </div>
  )
}


ProductListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      language: PropTypes.string
    })
  }),
};