import { FormattedMessage } from "react-intl";
import plastic from "../../../utils/plastic.jpeg";

export const CIndex3 = () => {
  return (
    <div className="container-fluid upper-slider-container">
      <div className="slider-3-bg-container">
        <div className="slider3-bg-svg" />
      </div>
      <div className="row d-flex justify-content-center position-relative h-100">
        <div className="col-lg-9 col-md-10 col-sm-10">
          <div className="row d-flex justify-content-between px-3 slide-height align-items-center h-100">
            <div className="col-lg-6 col-sm-12">
              <div
                className="row d-flex justify-content-center mt-4"
                style={{ zIndex: 999 }}
              >
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-5 g-2 text-center px-5">
                  <span className="slider1-title1 mr-1">
                    <FormattedMessage
                      id="EcosensePage.v2.Evolving.Carousel.three.title"
                      defaultMessage="RECYCLED <strong>FLOORING</strong>"
                      values={{
                        strong: (...chunks) => <strong>{chunks}</strong>
                      }}
                    />
                  </span>
                </div>
                <div className="col-sm-12 pb-0 mt-3">
                  <div className="row">
                    <div className="col-sm-12 slider2-desc pe-lg-5">
                      <span>
                        <FormattedMessage
                          id="EcosensePage.v2.Evolving.Carousel.three.description"
                          defaultMessage="Over <strong>280</strong> Tons of waste are recovered annually which is then
                      transformed into more than 100,000 square meters of
                      flooring. These sustainable materials find application
                      across various sectors, including the automotive and
                      healthcare industries."
                          values={{
                            strong: (...chunks) => <strong>{chunks}</strong>,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-lg-block">
              <div className="row justify-content-center align-items-center h-100 slider1-image-container">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={plastic}
                    className="img-fluid"
                    alt="plastic"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "100%", // Asegura que la imagen no supere la altura máxima del contenedor
                      objectFit: "cover", // Para asegurarte de que la imagen se ajuste al contenedor cuadrado
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
