import {defineMessages} from "react-intl";

const validationMessages = defineMessages({
  required: {
    id: "FormMessages.required",
    description: "Required message for form validation",
    defaultMessage: "Este campo es requerido"
  },
  email: {
    id: "FormMessages.invalidEmail",
    description: "Invalid email message for form validation",
    defaultMessage: "Dirección de correo inválida"
  },
  passwordMismatch: {
    id: "FormMessages.passwordMismatch",
    description: "Password mismatch message for form validation",
    defaultMessage: "Las contraseñas no coinciden, deben ser iguales."
  },
});

export default validationMessages;