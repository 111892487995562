import PropTypes from "prop-types";
import {Redirect} from "react-router";
import {graphql} from "react-apollo";
import {FormattedMessage} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {ABOUT_BRANDS} from "routes";
import TranslatedLink from "routes/components/TranslatedLink";
import HTMLContent from "utils/components/HTMLContent";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import HeroTitle from "utils/components/HeroTitle";
import SubHeroContent from "utils/components/SubHeroContent";
import singleBrandQuery from "modules/about/Brands/components/SingleBrandPage/query";
import {getTranslatedLink} from "services/instances";

const SingleBrandPage = ({singleBrandQuery: {loading, error, brand}}) => {
  if (error)
    return <Redirect to={getTranslatedLink(ABOUT_BRANDS, {})}/>;
  
  return (
    <div className="single-brand-page">
      <ScrollTopOnMount/>
      <HeroTitle
          title={!loading ? brand.title : <Skeleton width={200}/>}
          backgroundImage={!loading && brand.imageThumbLarge}/>
      <SubHeroContent theme="white">
        <div className="container mb-5">
          <div className="row pt-4 pb-3 pt-md-5 pb-md-4">
            <div className="col-auto">
              <ul className="nav justify-content-right">
                <li className="nav-item">
                  <TranslatedLink
                      name={ABOUT_BRANDS}
                      className="nav-link pl-0 pr-0 colored-link gray">
                    <small>
                      <i className="sp-strong-chevron-left"/>
                    </small>
                    &nbsp;&nbsp;&nbsp;
                    <FormattedMessage
                        id="WhyChooseUsPage.Brands.Brand.seeMoreBrands"
                        description="See more brands link text in single brand page"
                        defaultMessage="Ver más marcas"/>
                  </TranslatedLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                !loading ?
                  <HTMLContent html={brand.content}/>
                :
                  <Skeleton count={5}/>
              }
            </div>
          </div>
        </div>
      </SubHeroContent>
    </div>
  )
}

SingleBrandPage.propTypes = {
  singleBrandQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    brand: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      imageThumbLarge: PropTypes.string,
    })
  })
};

// TODO: use graphql hooks
export default graphql(
  singleBrandQuery,
  {
    name: "singleBrandQuery",
    options: props => {
      return {
        variables: {
          slug: props.match.params.brandSlug
        }
      }
    }
  }
)(SingleBrandPage);
