import PropTypes from "prop-types";
import useMediaQuery from "utils/hooks/useMediaQuery";
import SamplesListDesktop from "./components/SamplesListDesktop";
import SamplesListMobile from "./components/SamplesListMobile";

const SamplesList = ({
  samplesDestkop = [],
  samplesMobile = [],
}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const handleClick = (event, sample) => {
    console.log(`🚀🚀🚀 ~ handleClick ~ sample:`, sample)
    event.preventDefault();
  };

  return (
    <div className="d-lg-flex p-0 flex-wrap col-12 h-75">
      {isDesktop ? (
        <SamplesListDesktop
          samples={samplesDestkop}
          handleClick={handleClick}
        />
      ) : (
        <SamplesListMobile samples={samplesMobile} handleClick={handleClick} />
      )}
    </div>
  );
};

SamplesList.propTypes = {
  samplesDestkop: PropTypes.array,
  samplesMobile: PropTypes.array,
};

export default SamplesList;
