import classNames from "classnames/bind";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import NewsletterForm from "components/Footer/components/NewsletterForm";
import SeeMoreButton from "utils/components/SeeMoreButton";
import { CONTACT_PQR, FLATPAGE_DETAILS } from "routes";
import {
  getCurrentInstanceId,
  isLATAM,
  isMARINE,
  isUSA,
  getTranslatedLink,
} from "services/instances";
import { Query } from "react-apollo";
import flatpagesQuery from "components/Footer/queries";
import LoadingMessage from "utils/components/LoadingMessage";
import {
  instanceQuery,
  socialMediaInstanceQuery,
} from "components/Header/query";
import "./styles/Footer.scss";
import { ETHICAL_LINE_PDF_URL, INSTANCES_ITEMS } from "./constants";

export default function Footer() {
  const queryVariables = { instanceId: getCurrentInstanceId() };

  return (
    <footer className="spglobal-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 mb-4">
            <div className="row">
              <div className="col-6 col-md-12">
                <div className="footer-links footer-links--instances">
                  {INSTANCES_ITEMS.map(({ name, link: { href, target } }) => (
                    // Using <a/> instead of <Link/> so it reloads the page scrolled at top
                    <a
                      key={`key_${name}`}
                      href={href}
                      target={target}
                      rel="noopener noreferrer"
                    >
                      Spradling<sup>®</sup> {name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="col-6 col-md-12">
                {isLATAM() && (
                  <div className="footer-calypso">
                    <h5>
                      <FormattedMessage
                        id="Footer.calypsoTitle"
                        description="Calypso title in the footer"
                        defaultMessage="Calypso"
                      />
                    </h5>
                    <p>
                      <FormattedMessage
                        id="Footer.calypsoDescription"
                        description="Calypso description in the footer"
                        defaultMessage="Nuestras Tiendas en Colombia y Costa Rica"
                      />
                    </p>
                    <SeeMoreButton href="https://www.tiendascalypso.com/">
                      <FormattedMessage
                        id="Footer.seeMore"
                        description="Ver más button label in footer"
                        defaultMessage="Ver más"
                      />
                    </SeeMoreButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-4">
            <div className="row">
              <div className="col-6 col-md-12">
                <div className="footer-links footer-links--misc pt-0">
                  {!isUSA() && !isMARINE() && (
                    <Link to={getTranslatedLink(CONTACT_PQR, {})}>
                      <FormattedMessage
                        id="Footer.pqr"
                        description="Preguntas, Quejas y Reclamos link text in footer"
                        defaultMessage="Preguntas, Quejas y Reclamos"
                      />
                    </Link>
                  )}
                  <Query
                    query={flatpagesQuery}
                    variables={{ instanceIds: [getCurrentInstanceId()] }}
                  >
                    {({ data }) => {
                      if (data) {
                        const { flatpages } = data;

                        return flatpages.edges.map((edge) => (
                          <Link
                            key={edge.node.id}
                            to={getTranslatedLink(FLATPAGE_DETAILS, {
                              flatpageSlug: edge.node.slug,
                            })}
                          >
                            {edge.node.title}
                          </Link>
                        ));
                      }

                      return <LoadingMessage />;
                    }}
                  </Query>
                  {isLATAM() && (
                    <a
                      href={ETHICAL_LINE_PDF_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="Footer.policies.ethicalLine"
                        defaultMessage="Línea Ética"
                      />
                    </a>
                  )}
                  {!isUSA() && !isMARINE() && (
                    <>
                      <a
                        href="http://pqnintranet.proquinal.com/PEP-PORTAL-WEB/appmanager/intranet/proquinal_es"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="Footer.intranetLink"
                          description="Intranet link text in footer"
                          defaultMessage="Ingreso empleados"
                        />
                      </a>
                      <a
                        href="http://pqnintranet.proquinal.com/PEP-PORTAL-WEB/appmanager/intranet/proquinal_es"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="Footer.intranetLinkProviders"
                          description="Intranet link providers text in footer"
                          defaultMessage="Ingreso proveedores"
                        />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-4">
            <h5>
              <FormattedMessage
                id="Footer.newsletter"
                description="Newsletter section title"
                defaultMessage="Newsletter"
              />
            </h5>
            <p>
              <FormattedMessage
                id="Footer.newsletterText"
                description="Newsletter section text"
                defaultMessage="Entérate de las últimas noticias de Spradling, nuevos productos, tecnologías, eventos, tendencias... déjanos inspirarte."
              />
            </p>
            <NewsletterForm />
          </div>
          <div className="col-12 col-md-3">
            <h5>
              <FormattedMessage
                id="Footer.socialNetworks"
                description="Redes sociales section title in footer"
                defaultMessage="Redes sociales"
              />
            </h5>
            <div className="footer-links footer-links--social">
              <Query
                query={socialMediaInstanceQuery}
                variables={queryVariables}
              >
                {({ data }) => {
                  if (data) {
                    const {
                      instance: {
                        facebookUrl,
                        instagramUrl,
                        linkedinUrl,
                        twitterUrl,
                      },
                    } = data;

                    return (
                      <>
                        {facebookUrl && (
                          <a
                            href={facebookUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-facebook fa-fw" /> Facebook
                          </a>
                        )}
                        {instagramUrl && (
                          <a
                            href={instagramUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-instagram fa-fw" /> Instagram
                          </a>
                        )}
                        {linkedinUrl && (
                          <a
                            href={linkedinUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-linkedin fa-fw" /> Linkedin
                          </a>
                        )}
                        {twitterUrl && (
                          <a
                            href={twitterUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-twitter fa-fw" /> Twitter
                          </a>
                        )}
                      </>
                    );
                  }

                  return <Skeleton count={3} />;
                }}
              </Query>
            </div>
          </div>
        </div>
        <div
          className={classNames({
            "footer-copyright text-center": true,
            "footer-copyright--latam": isLATAM(),
          })}
        >
          <Query query={instanceQuery} variables={queryVariables}>
            {({ data }) => {
              if (data) {
                const {
                  instance: { logo },
                } = data;

                return (
                  <img
                    src={logo}
                    alt="Spradling Group"
                    title="Spradling Group"
                  />
                );
              }
              return <Skeleton width={150} height={32} />;
            }}
          </Query>
          <p className="text-uppercase mb-0">
            &copy;{new Date().getFullYear()} Spradling<sup>®</sup> Group
          </p>
        </div>
      </div>
    </footer>
  );
}
