import {connect} from "react-redux";
import ResetPasswordForm from "../index";
import {toggleAuthModal} from "components/Header/actions";

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: () => dispatch(toggleAuthModal())
  };
};

const ResetPasswordFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordForm);

export default ResetPasswordFormContainer;