import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/HomeSlide.scss";
import Skeleton from "react-loading-skeleton";
import { isMARINE } from "services/instances";
import useMediaQuery from "utils/hooks/useMediaQuery";

function handleLink({ isMobile, isLinkByFunction, slide }) {
  if (!isLinkByFunction) {
    return slide?.linkMobile && isMobile ? slide?.linkMobile : slide.link;
  }

  return slide.link();
}

const HomeSlide = ({ slide, isBtnlight, isLinkByFunction }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const currentSlideImage =
    isMobile && slide?.imagePhone ? slide.imagePhoneThumb : slide?.imageThumb;

  return (
    <div
      className="home-slide"
      style={{
        backgroundImage: `url(${slide && currentSlideImage})`,
      }}
    >
      <div className="home-slide__content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="slide-content">
                <h2 className="slide-content__title">
                  {slide ? (
                    <div dangerouslySetInnerHTML={{ __html: slide.title }} />
                  ) : (
                    <Skeleton width={200} />
                  )}
                </h2>
                <p className="slide-content__description text-weight-medium">
                  {slide ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: slide.description }}
                    />
                  ) : (
                    <Skeleton count={2} />
                  )}
                </p>
                {slide ? (
                  <a
                    href={handleLink({ isMobile, isLinkByFunction, slide })}
                    className={classNames({
                      "slide-content__button btn btn-lg": true,
                      "btn-dark": !isBtnlight,
                      "btn-light": isBtnlight,
                      "btn-prussian-blue": isMARINE() && !isBtnlight,
                    })}
                    target={slide.linkTarget}
                  >
                    {slide.buttonText}
                  </a>
                ) : (
                  <Skeleton width={100} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeSlide.defaultProps = {
  slide: null,
  isBtnlight: false,
  isLinkByFunction: false,
};

HomeSlide.propTypes = {
  slide: PropTypes.shape({
    imageThumb: PropTypes.string.isRequired,
    imagePhone: PropTypes.string,
    imagePhoneThumb: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    linkMobile: PropTypes.string,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    linkTarget: PropTypes.string,
  }),
  isBtnlight: PropTypes.bool,
  isLinkByFunction: PropTypes.bool,
};

export default HomeSlide;
