// COLOMBIA PLANT SLIDER IMAGES
import transform1 from "../img/transform-lifes-1-colombia.png";
import transform2 from "../img/transform-lifes-2-colombia.png";
import transform3 from "../img/transform-lifes-3-colombia.png";
import transform4 from "../img/transform-lifes-4-colombia.png";
import transform5 from "../img/transform-lifes-5-colombia.png";
import transform6 from "../img/transform-lifes-6-colombia.png";

// COSTA RICA PLANT SLIDER IMAGES
import transform1Costa from "../img/transform-lifes-1-costa-rica.png";
import transform2Costa from "../img/transform-lifes-2-costa-rica.png";
import transform3Costa from "../img/transform-lifes-3-costa-rica.png";
import transform4Costa from "../img/transform-lifes-4-costa-rica.png";
import transform5Costa from "../img/transform-lifes-5-costa-rica.png";
import transform6Costa from "../img/transform-lifes-6-costa-rica.png";

export const TRANSFORM_LIFES_IMAGES_COLOMBIA = [
  { id: 1, src: transform1, alt: "slider-img" },
  { id: 2, src: transform2, alt: "slider-img" },
  { id: 3, src: transform3, alt: "slider-img" },
  { id: 4, src: transform4, alt: "slider-img" },
  { id: 5, src: transform5, alt: "slider-img" },
  { id: 6, src: transform6, alt: "slider-img" }
]

export const TRANSFORM_LIFES_IMAGES_COSTA_RICA = [
  { id: 1, src: transform1Costa, alt: "slider-img" },
  { id: 2, src: transform2Costa, alt: "slider-img" },
  { id: 3, src: transform3Costa, alt: "slider-img" },
  { id: 4, src: transform4Costa, alt: "slider-img" },
  { id: 5, src: transform5Costa, alt: "slider-img" },
  { id: 6, src: transform6Costa, alt: "slider-img" },
]