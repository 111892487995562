import SliderImagesArrow from "../components/SliderImagesArrow";

export const SLIDER_IMAGES_OPTIONS = {
  infinite: true,
  lazyLoad: true,
  slidesToShow: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: (
    <div className="d-block">
      <SliderImagesArrow 
        classNameContainer="next-arrow-container"
        classNameArrow="next-arrow-img"
      />
    </div>
  ),
  prevArrow: (
    <div className="d-block">
      <SliderImagesArrow 
        classNameContainer="prev-arrow-container"
        classNameArrow="prev-arrow-img"
      />
    </div>
  ),
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
  ]
};