import gql from "graphql-tag";

const featuredProjectQuery = gql`
    query FeaturedProjectQuery (
        $instanceIds: [ID],
        $first: Int
    ) {
        projects(
            instances: $instanceIds,
            first: $first
        ) {
            edges {
                node {
                    id
                    name
                    client
                    slug
                    description
                    sector {
                        id
                        name
                    }
                    heroImageThumbMedium
                }
            }
        }
    }
`;

export default featuredProjectQuery;