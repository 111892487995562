import gql from "graphql-tag";
import singleUserFragment from "utils/fragments/SingleUserFragment";

const meQuery = gql`
  query MeQuery {
      me {
          ...UserFragment
      }
  }
  ${singleUserFragment}
`;

export default meQuery;