import * as types from "modules/stores/WhereToBuyPage/actions/types";

export const setNearbyStores = nearbyStores => ({
  type: types.NEARBY_STORES_SET,
  nearbyStores
});

export const setHighlightedStoreId = highlightedStoreId => ({
  type: types.HIGHLIGHTED_STORE_ID_SET,
  highlightedStoreId
});

export const setPatternId = patternId => ({
  type: types.PATTERN_ID_SET,
  patternId
})

export const setCityId = cityId => ({
  type: types.CITY_ID_SET,
  cityId
})

export const clearCurrentSearch = () => ({
  type: types.CURRENT_SEARCH_CLEAR
})