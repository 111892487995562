
export const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: "1.25rem",
    color: "#495057",
    padding: 10,
  }),
  control: (provided) => ({
    ...provided,
    height: "calc(1.5em + 1rem + 2px)",
    padding: "0.2rem 0",
    paddingRight: 0,
    fontSize: "1rem",
    lineHeight: 1.5,
    borderRadius: 5,
    borderColor: "black"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    SVG: {
      height: 16,
      with: 16,
      color: "#485056",
    }
  }),
  indicatorSeparator: () => {},
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    paddingBottom: 0,
    color: "#495057"
  })
}
