import {defineMessages} from "react-intl";


export const TIMELINE = [
  {
    year: 1959,
    title: defineMessages({
      timelineTitle1: {
        id: "SpradlingTimeline.timelineTitle1",
        description: "Timeline 1 in 'We make history' section",
        defaultMessage: "El inicio"
      }
    }).timelineTitle1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary1: {
        id: "SpradlingTimeline.timelineSummary1",
        description: "Timeline 1 in 'We make history' section",
        defaultMessage: "Nació Productos Químicos Nacionales (hoy Proquinal S. A.). Se construyeron la planta y las oficinas."
      }
    }).timelineSummary1
  },
  {
    year: 1981,
    title: defineMessages({
      timelineTitle2: {
        id: "SpradlingTimeline.timelineTitle2",
        description: "Timeline 2 in 'We make history' section",
        defaultMessage: "Líderes en exportación"
      }
    }).timelineTitle2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2: {
        id: "SpradlingTimeline.timelineSummary2",
        description: "Timeline 2 in 'We make history' section",
        defaultMessage: "El Presidente de Colombia nos hace entrega de la Medalla de Plata del Exportador."
      }
    }).timelineSummary2
  },
  {
    year: 1986,
    title: defineMessages({
      timelineTitle3: {
        id: "SpradlingTimeline.timelineTitle3",
        description: "Timeline 3 in 'We make history' section",
        defaultMessage: "Alianzas estratégicas"
      }
    }).timelineTitle3,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary3: {
        id: "SpradlingTimeline.timelineSummary3",
        description: "Timeline 3 in 'We make history' section",
        defaultMessage: "Consolidamos la alianza comercial con Spradling International Inc., como representante exclusivo en Estados Unidos y con All Cargo Intermodal Forwarding Inc. – ACIF -, como operador logístico en Miami, Estados Unidos."
      }
    }).timelineSummary3
  },
  {
    year: 1997,
    title: defineMessages({
      timelineTitle4: {
        id: "SpradlingTimeline.timelineTitle4",
        description: "Timeline 4 in 'We make history' section",
        defaultMessage: "Mercado Alemán"
      }
    }).timelineTitle4,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary4: {
        id: "SpradlingTimeline.timelineSummary4",
        description: "Timeline 4 in 'We make history' section",
        defaultMessage: "Consolidamos la alianza comercial con Spradling International GmbH, como representante exclusivo en Alemania."
      }
    }).timelineSummary4
  },
  {
    year: 2004,
    title: defineMessages({
      timelineTitle5: {
        id: "SpradlingTimeline.timelineTitle5",
        description: "Timeline 5 in 'We make history' section",
        defaultMessage: "Presentes en España"
      }
    }).timelineTitle5,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary5: {
        id: "SpradlingTimeline.timelineSummary5",
        description: "Timeline 5 in 'We make history' section",
        defaultMessage: "Se consolidó la alianza comercial con Euro Spradling, como representante exclusivo en España."
      }
    }).timelineSummary5
  },
  {
    year: 2004,
    title: defineMessages({
      timelineTitle6: {
        id: "SpradlingTimeline.timelineTitle6",
        description: "Timeline 6 in 'We make history' section",
        defaultMessage: "Costa Rica se pone en marcha"
      }
    }).timelineTitle6,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary6: {
        id: "SpradlingTimeline.timelineSummary6",
        description: "Timeline 6 in 'We make history' section",
        defaultMessage: "Se inauguró la planta con un área de construcción de 15.000 m2, en un terreno de 68.000 m2."
      }
    }).timelineSummary6
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle7: {
        id: "SpradlingTimeline.timelineTitle7",
        description: "Timeline 7 in 'We make history' section",
        defaultMessage: "Cautivando el mercado del Reino Unido"
      }
    }).timelineTitle7,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary7: {
        id: "SpradlingTimeline.timelineSummary7",
        description: "Timeline 7 in 'We make history' section",
        defaultMessage: "Se consolidó la alianza comercial con CTP Textiles, como representante exclusivo en el Reino Unido."
      }
    }).timelineSummary7
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle8: {
        id: "SpradlingTimeline.timelineTitle8",
        description: "Timeline 8 in 'We make history' section",
        defaultMessage: "Nos ampliamos al sector Marina"
      }
    }).timelineTitle8,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary8: {
        id: "SpradlingTimeline.timelineSummary8",
        description: "Timeline 8 in 'We make history' section",
        defaultMessage: "Spradling International, adquiere la división marina de la empresa norteamericana G&T, y así nace Spradling International Marine (SIM)."
      }
    }).timelineSummary8
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle9: {
        id: "SpradlingTimeline.timelineTitle9",
        description: "Timeline 9 in 'We make history' section",
        defaultMessage: "Continúa nuestro crecimiento"
      }
    }).timelineTitle9,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary9: {
        id: "SpradlingTimeline.timelineSummary9",
        description: "Timeline 9 in 'We make history' section",
        defaultMessage: "Nace Spradmex, en la Ciudad de México."
      }
    }).timelineSummary9
  },
  {
    year: 2014,
    title: defineMessages({
      timelineTitle10: {
        id: "SpradlingTimeline.timelineTitle10",
        description: "Timeline 10 in 'We make history' section",
        defaultMessage: "Spradling Group"
      }
    }).timelineTitle10,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary10: {
        id: "SpradlingTimeline.timelineSummary10",
        description: "Timeline 10 in 'We make history' section",
        defaultMessage: "Se consolida oficialmente Spradling Group."
      }
    }).timelineSummary10
  },
  {
    year: 2016,
    title: defineMessages({
      timelineTitle11: {
        id: "SpradlingTimeline.timelineTitle11",
        description: "Timeline 11 in 'We make history' section",
        defaultMessage: "Cada vez más grandes"
      }
    }).timelineTitle11,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary11: {
        id: "SpradlingTimeline.timelineSummary11",
        description: "Timeline 11 in 'We make history' section",
        defaultMessage: "Ampliamos la planta en Costa Rica incrementando nuestra capacidad de producción."
      }
    }).timelineSummary11
  },
  {
    year: 2019,
    title: defineMessages({
      timelineTitle12: {
        id: "SpradlingTimeline.timelineTitle12",
        description: "Timeline 12 in 'We make history' section",
        defaultMessage: "Cumplimos 60 años"
      }
    }).timelineTitle12,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary12: {
        id: "SpradlingTimeline.timelineSummary12",
        description: "Timeline 12 in 'We make history' section",
        defaultMessage: "Nos consolidamos en el mercado, demostrando que somos expertos en lo que hacemos, nuestros 60 años lo confirman."
      }
    }).timelineSummary12
  }
];