import gql from "graphql-tag";

const homeSlidersQuery = gql`
  query HomeSliders($instanceIds: [ID]!, $tag: String) {
    homeSliders(instances: $instanceIds, tag: $tag) {
      edges {
        node {
          id
          title
          description
          buttonText
          imageThumb
          imagePhone
          imagePhoneThumb
          link
          linkMobile
          linkTarget
        }
      }
    }
  }
`;

export default homeSlidersQuery;
