import {H2_TAG} from "utils/components/TitleUnderlined/constants";
import classNames from "classnames/bind";
import {FormattedMessage} from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {TIMELINE} from 'modules/about/WeAreSpradlingPage/components/SpradlingTimeline/constants';
import "./styles/SpradlingTimeline.scss";

const SpradlingTimeline = () => (
  <div className="spradling-timeline">
    <TitleUnderlined
        hTag={H2_TAG}
        align="center">
      <FormattedMessage
          id="SpradlingTimeline.title"
          description="Spradling timeline section title"
          defaultMessage="Hacemos historia"/>
    </TitleUnderlined>
    <div className="timeline">
      {
        TIMELINE.map((chapter, index) =>
            <div
                key={index}
                className={classNames({
                  "timeline-item": true,
                  "timeline-item--odd": index % 2 === 0,
                  "timeline-item--even": index % 2 !== 0
                })}>
              <div className="row justify-content-center">
                <div className="col-6 col-lg-4">
                  <h3 className="timeline-year">
                    {chapter.year}
                  </h3>
                </div>
                <div className="col-6 col-lg-4">
                  <h5 className="timeline-title">
                    <FormattedMessage
                        {...chapter.title}/>
                  </h5>
                  <p className="timeline-content">
                    <FormattedMessage
                        {...chapter.summary}/>
                  </p>
                </div>
              </div>
            </div>
        )
      }
    </div>
  </div>
)

export default SpradlingTimeline