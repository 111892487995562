import PropTypes from "prop-types";
import "./styles/CertificateRow.scss";
import { FormattedMessage } from "react-intl";

const CertificateRow = ({ groupTitle, link, attrs }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="col-6 m-0 p-0">
        {attrs.map((sustainabilityProperty) => {
          return sustainabilityProperty.certificate !== "" ? (
            <a href={link + sustainabilityProperty.certificate}>
              <img
                src={link + sustainabilityProperty.logoImg}
                className="mr-2"
                width={70}
              />
            </a>
          ) : (
            <img
              src={link + sustainabilityProperty.logoImg}
              className="mr-2"
              width={70}
            />
          );
        })}
      </div>
      {groupTitle === "certifications" && (
        <div className="col-6">
          <h6 className="certificate--row_note">
            *{" "}
            <FormattedMessage
              id="ProductSpecs.sustainabilityAttributes.showCertificate"
              defaultMessage="To view the certification document, click on the PDF logo"
            />
          </h6>
          <h6 className="certificate--row_note">
            *{" "}
            <FormattedMessage
              id="ProductSpecs.sustainabilityAttributes.toKnowMoreAboutEachCertificate"
              defaultMessage="To learn more about each certification, click here"
            />
          </h6>
        </div>
      )}
    </div>
  );
};

CertificateRow.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  attrs: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
};

export default CertificateRow;
