import PropTypes from "prop-types";
import CharacteristicImage from "./components/CharacteristicImage";
import HeaderSection from "./components/HeaderSection";
import ProseatGlossary from "modules/proseat/ProseatPage/components/ProseatGlossary";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import DownloadCharacteristicPdfBtn from "./components/DownloadCharacteristicPDFBtn";
import { PINK_STAIN, SEWING_RECOMMENDATION } from "./components/DownloadCharacteristicPDFBtn/constants/availablesPDF";
import classNames from "classnames";

const CharacteristicDetailsSection = ({
  details,
  hideCharacteristicInfo
}) => {
  const isDesktop = useMediaQuery(LG);
  const { hideBtnPink, hideBtnSewing } = details?.characteristicDetail;

  return (
    <div className="characteristic-detail--container bg-white py-0">
      <HeaderSection 
        details={details}
        hideCharacteristicInfo={hideCharacteristicInfo}
      />
      <main className={classNames({
        "px-4 pt-2": true,
        "pb-5": !hideBtnPink || !hideBtnSewing,
        "pb-4": hideBtnPink && hideBtnSewing,
        })}>
        <CharacteristicImage details={details} />

        <h5 className={classNames({
          "characteristic-detail--title font-weight-bold mb-4 mt-4": true,
          "mt-xl-0": !hideBtnPink || !hideBtnSewing,
          "mt-xl-3": hideBtnPink || hideBtnSewing,
          })}>
          {details.characteristicDetail.title}
        </h5>
        <p className={classNames({
          "characteristic-detail--description": true,
          "mb-5": !hideBtnPink || !hideBtnSewing
          })}>
          {details.characteristicDetail.description}
        </p>
        <div className="d-flex flex-column mt-4 justify-content-center align-items-center">
          {!isDesktop && 
            <div className="col-12 px-0 mb-0">
              <ProseatGlossary currentCharacteristic={details} />
            </div>
          }
          {!hideBtnPink && 
            <DownloadCharacteristicPdfBtn 
              name={PINK_STAIN}
            />}
          {!hideBtnSewing && 
            <DownloadCharacteristicPdfBtn 
              name={SEWING_RECOMMENDATION}
              className="mt-3"
            />}
        </div>
      </main>
    </div>
  )
}

CharacteristicDetailsSection.propTypes = {
  details: PropTypes.shape({
    showCharacteristicInfo: PropTypes.bool,
    characteristicDetail: PropTypes.shape({
      id: PropTypes.string,
      characteristicNumber: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.array,
      img: PropTypes.string,
      imgWidth: PropTypes.string,
      imgHeight: PropTypes.string,
      hideBtnPink: PropTypes.bool,
      hideBtnSewing: PropTypes.bool
    })
  }),
  hideCharacteristicInfo: PropTypes.func
}

export default CharacteristicDetailsSection;