import PropTypes from 'prop-types';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import PurposesGroupAccordion from './components/PurposeGroupAccordion';
import useToggleAccordionGroup from './hooks/useToggleAccordionGroup';
import CategoryPurposeAccordion from './components/CagetoryPurposeAccordion';

const PurposesAccordionsSection = ({ purposesItems, handleClickToChangeMasterImage }) => {
  const isMobile = useMediaQuery("(max-width: 995px)");
  const { higherPurposesGroups, handleClosePurposesGroup } = useToggleAccordionGroup(purposesItems);

  return (
    higherPurposesGroups.map((group) => (
      <PurposesGroupAccordion 
        key={group.id}
        {...group}
        handleClosePurposesGroup={handleClosePurposesGroup}
      >
        {group.groupItems.map(({ id, title, description, imgIcon, isOpen, masterImg }) => {
          return (
            <div key={id} onClick={() => handleClickToChangeMasterImage(masterImg)}>
              <CategoryPurposeAccordion
                title={title} 
                imgIcon={imgIcon}
                isOpen={isOpen}
                parentAccordionTitle={group.groupTitle}
                handleClosePurposesGroup={handleClosePurposesGroup}
              >
                <p className='text-white pt-4'>
                  {description}
                </p>
                {isMobile && <div className='text-center'>
                  <img src={masterImg} alt='master-image' width={325} height={335} />
                </div>}
              </CategoryPurposeAccordion>
            </div>
          );
        })}
      </PurposesGroupAccordion>
    ))
  );
};

PurposesAccordionsSection.propTypes = {
  purposesItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      groupTitle: PropTypes.string.isRequired,
      groupItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          imgIcon: PropTypes.string.isRequired,
          masterImg: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default PurposesAccordionsSection;