import gql from "graphql-tag";

export const GET_ORDERS_HISTORY = gql`
  query GetOrders(
      $user: ID
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
    ){
    orders(
      user: $user
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
    ) {
      __typename
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          updated
          notes
          items {
            edges {
              node {
                id
                reference {
                  altName
                  imageThumbLarge
                }
                pattern {
                  color
                  stock
                  imageThumbSmall
                }
                product {
                  color
                  stock
                  imageThumbSmall
                }
              }
            }
          }
        }
      }
    }
  }
`