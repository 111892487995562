import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import AsyncSelect from "react-select/async";
import { apolloClient } from "apollo/client";
import { defineMessages, useIntl } from "react-intl";
import regionsQuery from "components/AuthModal/components/SignUpForm/components/RegionField/query";
import { customStyles } from "../CityField/styles";

const placeholders = defineMessages({
  withoutCountry: {
    id: "RegionField.withoutCountry",
    description:
      "Placeholder for autocomplete select when there's no country selected for RegionField component",
    defaultMessage: "Primero selecciona un país",
  },
  withCountry: {
    id: "RegionField.withCountry",
    description:
      "Placeholder for autocomplete select when there's a country selected for RegionField component",
    defaultMessage: "Escribe tu región",
  },
});

export default function RegionField({
  countryId,
  isCustomStyles = false,
  regionByZipcode,
}) {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const [marchedRegion, setMarchedRegion] = useState(null);
  const placeholder = intl.formatMessage(
    !countryId ? placeholders.withoutCountry : placeholders.withCountry
  );

  useEffect(() => {
    const setRegionByZipCode = async () => {
      const region = await loadOptions(regionByZipcode);
      /**
       * WARNING:
       * Haciendo test con el codigo postal 110111 de bogota no funciona
       * ya que en la db el nombre es Bogota D.C y google maps retorna el Bogotá
       * por lo cual no hace match el nombre en la query de getRegions
       */

      if (region.length) {
        const [firstRegion] = region;
        setMarchedRegion(firstRegion);
        setFieldValue("regionId", firstRegion.value);
      }
    };

    if (countryId && regionByZipcode) {
      setRegionByZipCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, regionByZipcode]);

  /**
   * Load options through the GraphQL api
   * @param value {String}
   * @returns {Promise<Array>|Promise<any>}
   */
  function loadOptions(value) {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: regionsQuery,
          variables: {
            countryId,
            query: value,
          },
        })
        .then((response) => {
          const {
            data: { regions },
          } = response;
          resolve(
            regions.edges.map((edge) => ({
              label: edge.node.name,
              value: edge.node.id,
            }))
          );
        });
    });
  }

  const handleOnChange = (name, value) => {
    setMarchedRegion(null);
    setFieldValue(name, value);
  };

  return (
    <Field name="regionId">
      {({ field }) => (
        <AsyncSelect
        {...field}
        styles={isCustomStyles && customStyles}
        placeholder={placeholder}
        loadOptions={loadOptions}
        label={
          !marchedRegion?.label
            ? field.value && field.value.label
            : marchedRegion.label
        }
        value={
          !marchedRegion?.value
            ? field.value && field.value.value
            : marchedRegion
        }
        isDisabled={!countryId}
        onChange={(option) => handleOnChange(field.name, option.value)}
      />
      )}
    </Field>
  );
}

RegionField.propTypes = {
  countryId: PropTypes.string,
  isCustomStyles: PropTypes.bool,
  regionByZipcode: PropTypes.string,
};
