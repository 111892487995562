import {connect} from "react-redux";
import ResetPassword from "../index";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated
  };
};

const mapDispatchToProps = () => {
  return {

  };
};

const ResetPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);

export default ResetPasswordContainer;