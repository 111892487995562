import "./no-match.scss";

const NoMatch = () => (
  <div className="no-match">
    <header className="spglobal-header fixed-top">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-6 col-lg-2 order-lg-0">
            <div className="header-logo">
              <a href="/">
                <img
                  className="img-fluid w-75"
                  src="https://d35uhk0h6i20hu.cloudfront.net/uploads/instances/4/logo-spradling.png"
                  alt="Spradling Group"
                  title="Spradling Group"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main className="no-match-main">
      <div className="no-match-main-container">
        <div className="label-container">
          <h1 className="label">404</h1>
          <p>Error, page not found.</p>
        </div>
        <i className="ban-icon fa fa-ban fa-light" />
      </div>
      <a className="btn btn-outline-dark" href="/">
        Go to home
      </a>
    </main>
    <footer className="p-0 m-0 spglobal-footer">
      <div className="p-0 pb-3 m-0 footer-copyright text-center">
        <img
          src="https://d35uhk0h6i20hu.cloudfront.net/uploads/instances/4/logo-spradling.png"
          alt="Spradling Group"
          title="Spradling Group"
        />
        <p className="text-uppercase mb-0">
          ©{new Date().getFullYear()} Spradling<sup>®</sup> Group
        </p>
      </div>
    </footer>
  </div>
);

export default NoMatch;
