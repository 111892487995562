import HotspotPoint from "../components/HotspotPoint"

export const HOTSPOTS_POINTS_COORDINATES = [
  { x: 94, y: 56 },
  { x: 90, y: 61 },
  { x: 90, y: 75 },
  { x: 88, y: 82 },
  { x: 69, y: 91 },
  { x: 59, y: 96 },
  { x: 52, y: 94 },
  { x: 8, y: 75 },
  { x: 2, y: 26 },
  { x: 4, y: 19 },
  { x: 5, y: 10 },
  { x: 65, y: 33 }
]

export const hotspotsResultData = (handleChangeAndShowCharacteristicInfo) => {
  const resultHotspotsPoints = HOTSPOTS_POINTS_COORDINATES.map((point, index) => {
    return { 
      ...point, 
      content: <HotspotPoint 
      pointNumber={index + 1} 
      handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo} /> 
    }
  })

  return resultHotspotsPoints
}