import * as types from '../actions/types';
import {isAuthenticated} from "services/auth/localStorage";

export const initialState = {
  isAuthenticated: isAuthenticated(),  // initial value from localStorage
};

export const authReducer = (state = initialState, action) => {
  switch (action.type){
    case types.AUTH_LOGIN:
      return {
        ...state,
        isAuthenticated: true
      };
    case types.AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
};