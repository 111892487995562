import React from 'react'
import PropTypes from "prop-types";
import { Modal, ModalHeader } from 'reactstrap';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import "./styles/TestimonialsModal.scss";
import { isLATAM, isMEXICO } from 'services/instances';

const TestimonialsModal = ({isOpen, testimonial, handleToggleModal}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(min-width: 768px)")
  const isSpanishLanguage = isMEXICO() || isLATAM()
  const { imageUrl, description, descriptionEn, htmlTag } = testimonial

  return (
    <Modal
      size={isDesktop ? "lg" : isTablet ? "md" : "sm"}
      centered
      isOpen={isOpen}
      toggle={handleToggleModal}
      className='testimonial-modal--container-modal d-flex justify-content-center align-items-center'
    >
      <ModalHeader className='testimonial-modal--header' toggle={handleToggleModal} />
      <div className='d-flex flex-column justify-content-center mt-5 container px-lg-4'>
        <div className='testimonial-modal--container-img'>
          {!htmlTag 
            ? <img 
              className='testimonial-modal--big-img img-fluid'
              src={imageUrl} 
              alt="big-image-testimonial"
              width={1000}
              height={1000}
              />
            : <div className='d-flex justify-content-center align-items-center h-100' dangerouslySetInnerHTML={{ __html: htmlTag }} />}
        </div>
        <section 
          className='testimonial-modal--description' 
          dangerouslySetInnerHTML={{ __html: isSpanishLanguage ? description : descriptionEn }} />
      </div>
    </Modal>
  )
}

TestimonialsModal.propTypes = {
  isOpen: PropTypes.bool,
  testimonial: PropTypes.object,
  handleToggleModal: PropTypes.func
}

export default TestimonialsModal;