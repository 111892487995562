import gql from "graphql-tag";

const singleStoreQuery = gql`
  query StoreQuery ($id: ID!) {
      store(id: $id) {
          id
          latitude
          longitude
          name
          phoneNumbers
          address
          email
          website
          sectors {
              edges {
                  node {
                      id
                      name
                      market {
                          id
                          name
                      }
                  }
              }
          }
          city {
              id
              name
              country {
                  id
                  name
              }
          }
          businessHours {
              edges {
                  node {
                      id
                      startDay
                      startHour
                      endDay
                      endHour
                  }
              }
          }
      }
  }
`;

export default singleStoreQuery;