import validationMessages from "utils/forms/messages";
import * as Yup from "yup";


export const initialValues = {
  countryId: "",
  regionId: "",
  cityId: "",
  address: "",
  zipCode: "",
  phone: "",
  company: "",
  position: "",
  companyType: "",
};

export const getYupValidationSchema = (intl) => {

  const validationSchema = Yup.object().shape({
    countryId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    regionId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    cityId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    position: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    // TODO validate it's a phone number
    address: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    zipCode: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    phone: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    company: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyType: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    )
  });

  return validationSchema

} 
