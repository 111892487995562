import TitleUnderlined from "utils/components/TitleUnderlined"
import {FormattedMessage} from "react-intl"
import RequestSamplesDisclaimer from "modules/cart/scenes/SamplesCart/components/RequestSamplesDisclaimer"
import SamplesListContainer from "modules/cart/scenes/SamplesCart/components/SamplesList/container"
import RequestSamplesBtnContainer from "modules/cart/scenes/SamplesCart/components/RequestSamplesBtn/container"
import { useQuery } from "react-apollo"
import meQuery from "utils/queries/MeQuery"
import { useEffect, useState } from "react"
import FormUserInformationToRequestSamples from "./components/FormUserInformationToRequestSamples"

export default function SamplesCart() {
  const [showFormToCompleteUserInformation, setShowFormToCompleteUserInformation] = useState(false)

  const { data } = useQuery(meQuery)

  useEffect(() => {
    if (data) {
      if (!data.me.profile) setShowFormToCompleteUserInformation(true)
    }
  }, [data])

  return (
      <div className="samples-cart py-5">
        <div className="container">
        {!showFormToCompleteUserInformation ? <>
          <TitleUnderlined
            align="center">
            <FormattedMessage
              id="SamplesCart.title"
              defaultMessage="Mi carrito de muestras" />
          </TitleUnderlined>
          <p>
            <FormattedMessage
                id="SamplesCart.subtitle"
                defaultMessage="Aquí puedes encontrar los productos que has agregado en tus solicitudes de muestras."/>
          </p>
            <SamplesListContainer/>
            <RequestSamplesDisclaimer/>
            <RequestSamplesBtnContainer/>
          </>
          : <FormUserInformationToRequestSamples
              userInfo={data.me} 
              callbackShowFormUserInformation={setShowFormToCompleteUserInformation} />}
        </div>
      </div>
  )
}