import PropTypes from "prop-types";
import HeroTitle from "utils/components/HeroTitle";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import { defineMessages, injectIntl } from "react-intl";
import ContactBox from "modules/contact/ContactUsPage/components/ContactBox";
import CallUs from "modules/contact/ContactUsPage/components/CallUs";
import FAQ from "modules/contact/PQRPage/components/FAQ";
import heroBg from "./img/hero-background.jpg";
import { CONTACT_PQR_SCENE } from "services/seo/constants/scenes";
import SEO from "services/seo/components/SEO";

const PQRPage = ({ intl }) => (
  <div className="faq">
    <ScrollTopOnMount />
    <HeroTitle
      title={intl.formatMessage(
        defineMessages({
          heroTitle: {
            id: "PQRPage.heroTitle",
            description: "PQR page header title",
            defaultMessage: "Quejas, sugerencias y dudas.",
          },
        }).heroTitle
      )}
      backgroundImage={heroBg}
    />
    <div className="container mt-4 mb-4 mt-lg-5">
      <div className="pl-md-5 pr-md-5 mb-lg-5 pt-lg-3">
        <FAQ />
      </div>
      <ContactBox
        message={intl.formatMessage(
          defineMessages({
            faqContactBoxMessage: {
              id: "PQRPage.faqContactBoxMessage",
              description: "PQR contact box message",
              defaultMessage:
                "Si tienes una pregunta, queja o reclamo aditional a las que te hemos compartido, te invitamos a que nos la compartasa través de este formulario",
            },
          }).faqContactBoxMessage
        )}
      />
    </div>
    <div className="mb-4 pt-4">
      <CallUs />
    </div>
    <SEO scene={CONTACT_PQR_SCENE} />
  </div>
);

PQRPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(PQRPage);
