import { AVAILABLES_PDF } from "../constants/availablesPDF";

const handleURLPdfAccordingToFormType = (pdfName) => {
  const pdfURL =  AVAILABLES_PDF[pdfName].pdfURL
  const fileName = AVAILABLES_PDF[pdfName].pdfName

  return { pdfURL, fileName }
}

export const handleDownloadFile = async (pdfName) => {
  try {
    const { pdfURL, fileName } = handleURLPdfAccordingToFormType(pdfName);

    const response = await fetch(pdfURL, {
      method: "GET",
      headers: {},
    });

    if (!response.ok) {
      console.log("HTTP error, status =", response.status);
      return;
    }

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = `${fileName}.pdf`;
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error("Error during fetch/download operation:", error);
  }
};
