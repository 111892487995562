import PropTypes from "prop-types";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import { Modal } from "reactstrap";
import useMediaQuery from "utils/hooks/useMediaQuery";
import CharacteristicDetailsSection from "./components/CharacteristicDetailsSection";
import "./styles/CharacteristicDetailsSection.scss";

const CharacteristicDetailsSectionWrapper = ({
  details,
  hideCharacteristicInfo
}) => {
  const isDesktop = useMediaQuery(LG);

  return (
    !isDesktop
      ? <Modal
        size="xl"
        isOpen={details.showCharacteristicInfo}
        modalClassName="characteristic-details--modal-mobile"
      >
        <CharacteristicDetailsSection
          details={details}
          hideCharacteristicInfo={hideCharacteristicInfo}
        />
      </Modal>
      : <CharacteristicDetailsSection
          details={details}
          hideCharacteristicInfo={hideCharacteristicInfo}
        />
  )
}

CharacteristicDetailsSectionWrapper.propTypes = {
  details: PropTypes.shape({
    showCharacteristicInfo: PropTypes.bool,
    characteristicDetail: PropTypes.shape({
      id: PropTypes.string,
      characteristicNumber: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.array,
      img: PropTypes.string,
      imgWidth: PropTypes.string,
      imgHeight: PropTypes.string
    })
  }),
  hideCharacteristicInfo: PropTypes.func
}

export default CharacteristicDetailsSectionWrapper;