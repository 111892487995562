import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {ABOUT_WORK_WITH_US} from "routes";
import {Query} from "react-apollo";
import singleVacantQuery from "modules/about/VacantDetails/query";
import './styles/VacantDetails.scss';
import Skeleton from 'react-loading-skeleton';
import JobApplicationForm from "modules/about/VacantDetails/components/JobApplicationForm";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import HTMLContent from "utils/components/HTMLContent";
import LoadingMessage from "utils/components/LoadingMessage";
import {getTranslatedLink} from "services/instances";

const VacantDetails = ({match}) => (
    <div className="vacant-details">
      <ScrollTopOnMount/>
      <div className="container pt-lg-5 pb-5">
        <div className="pt-5 pb-5">
          <Link
              className="vacant-details__return-link"
              to={getTranslatedLink(ABOUT_WORK_WITH_US, {})}>
            <small>
              <i className="sp-chevron-left"/>
            </small>&nbsp;&nbsp;
            <FormattedMessage
                id="VacantDetails.returnLink"
                description="Return link label to Vacant details page"
                defaultMessage="Volver a ofertas de empleo disponibles"/>
          </Link>
        </div>
        <Query
            query={singleVacantQuery}
            variables={{
              slug: match.params.vacantSlug
            }}>
          {({loading, data}) => {
            if (loading)
              return (
                <>
                  <h1 className="vacant-details__name">
                    <Skeleton width={"50%"}/>
                  </h1>
                  <p>
                    <Skeleton count={2}/>
                  </p>
                </>
              );

            if (data) {
              const {vacant} = data;
  
              return (
                <>
                  <h1 className="vacant-details__name">
                    {vacant.name}, <span
                      className="vacant-details__city">{vacant.city.name}, {vacant.city.country.name}</span>
                  </h1>
                  <HTMLContent html={vacant.description}/>
                  <hr/>
                  <JobApplicationForm
                      vacantId={vacant.id}/>
                </>
              )
            }
            return <LoadingMessage/>
          }}
        </Query>
      </div>
    </div>
);

VacantDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      vacantSlug: PropTypes.string
    })
  }),
};

export default VacantDetails;
