import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles/DocumentListPage.scss";
import { Query } from "react-apollo";
import { Link, useLocation } from "react-router-dom";
import documentsQuery from "modules/documents/DocumentListPage/queries";
import Skeleton from "react-loading-skeleton";
import DocumentItem from "modules/documents/DocumentListPage/component/DocumentItem";
import Paginator from "utils/components/Paginator";
import NoResultsMessage from "utils/components/NoResultsMessage";
import LoadingMessage from "utils/components/LoadingMessage";
import { DOCUMENT_LIBRARY } from "routes";
import { FormattedMessage } from "react-intl";
import { getCurrentInstanceId, getTranslatedLink } from "services/instances";
import QueryParams from "services/browser-history/QueryParams";
import ProseatPage from "modules/proseat/ProseatPage";
import { PROSEAT_SLUG } from "modules/proseat/ProseatPage/constants/proseatSlug";

export default function DocumentListPage({ match }) {
  const [query, setQuery] = useState("");
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const pageSize = 10;
  
  // TODO have a debounce before executing search when the input changes
  const queryVariables = {
    // Filters
    categorySlug: match.params.categorySlug,
    searchDocument: query,
    instances: [getCurrentInstanceId()],
    orderBy: "-id",
    // Pagination
    ...queryParams.paginationGraphQLVariables(pageSize),
  };
  
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  /**
   * Generic Handler to text input's
   * @param event {Object}
   */
  function handleInputChange(event) {
    setQuery(event.target.value);
  }

  /**
   * Handles form submit event
   * @param event {Object}
   */
  function handleSearchFormSubmit(event) {
    event.preventDefault();
  }
  
  // NOTE: This to show the Proseat page based on the category slug
  if (match.params.categorySlug === PROSEAT_SLUG) {
    return <ProseatPage />
  }

  return (
    <div className="product-list-page">
      <div className="container pt-5 pb-5">
        <Link
          className="gray-light"
          to={getTranslatedLink(DOCUMENT_LIBRARY, {})}
        >
          <i className="fa fa-chevron-left text-size-small" />
          &nbsp;
          <FormattedMessage
            id="DocumentListPage.goBackLink"
            description="Go back link in Document list page"
            defaultMessage="Volver"
          />
        </Link>
        <Query
          query={documentsQuery}
          variables={{
            ...queryVariables,
          }}
        >
          {({ loading, data }) => {
            if (loading)
              return (
                <div>
                  <Skeleton className="products-page-title" width="50%" />
                  {[1, 2, 3, 4, 5, 6].map((item) => (
                    <DocumentItem key={item} loading />
                  ))}
                </div>
              );

            if (data) {
              const { documentCategory } = data;
              const noResults =
                !loading && documentCategory.documents.edges.length === 0;
              return (
                <>
                  <h1 className="products-page-title">
                    {documentCategory.name}
                  </h1>
                  <form
                    className="documents-list-filter"
                    onSubmit={handleSearchFormSubmit}
                    noValidate
                  >
                    <div className="form-row">
                      <div className="form-group col-12 col-md-6">
                        <div className="input-group">
                          <FormattedMessage
                            id="DocumentListPage.searchInputPlaceholder"
                            description="Search input placeholder text in DocumentListPage component"
                            defaultMessage="Buscar por nombre de producto"
                          >
                            {(placeholder) => (
                              <input
                                value={query}
                                onChange={handleInputChange}
                                className="form-control"
                                type="text"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fa fa-search" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <hr />
                  {noResults && <NoResultsMessage />}
                  {documentCategory.documents.edges.map((document, index) => (
                    <DocumentItem key={index} document={document.node} />
                  ))}
                  {!noResults && (
                    <Paginator
                      elementId={"productListElement"}
                      pageSize={pageSize}
                      pageInfo={documentCategory.documents.pageInfo}
                      totalCount={documentCategory.documents.totalCount}
                    />
                  )}
                </>
              );
            }

            return <LoadingMessage />;
          }}
        </Query>
      </div>
    </div>
  );
}

DocumentListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categorySlug: PropTypes.string,
    }),
  }),
};
