import PropTypes from "prop-types";
import SubHeroContent from "utils/components/SubHeroContent";
import {FormattedMessage} from "react-intl";
import Skeleton from "react-loading-skeleton";
import HeroTitle from "utils/components/HeroTitle";
import TranslatedLink from "routes/components/TranslatedLink";
import {PRODUCT_DETAILS, PROJECTS_LIST} from "routes";
import SocialShareNav from "utils/components/SocialShareNav";
import {graphql} from "react-apollo";
import singleProjectQuery from "modules/projects/SingleProjectPage/query";
import RelatedProjects from "modules/projects/SingleProjectPage/components/RelatedProjects";
import {Redirect} from "react-router";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import "./styles/SingleProjectPage.scss";
import HTMLContent from "utils/components/HTMLContent";
import {Link} from "react-router-dom";
import {getTranslatedLink} from "services/instances"

const SingleProjectPage = ({singleProjectQuery: {loading, error, project}}) => {

  if (error)
    return <Redirect to={getTranslatedLink(PROJECTS_LIST, {})}/>;

  return (
      <div className="single-project-page">
        <ScrollTopOnMount/>
        <HeroTitle
            title={!loading ? project.name : <Skeleton width={200}/>}
            backgroundImage={!loading ? project.heroImageThumbLarge : ""}/>
        <SubHeroContent theme="white">
          <div className="container mb-5">
            <div className="row justify-content-between pt-4 pb-3 pt-md-5 pb-md-4">
              <div className="col-auto">
                <ul className="nav justify-content-right">
                  <li className="nav-item">
                    <TranslatedLink
                        name={PROJECTS_LIST}
                        className="nav-link pl-0 pr-0 colored-link gray">
                      <small>
                        <i className="sp-strong-chevron-left"/>
                      </small>
                      &nbsp;&nbsp;&nbsp;
                      <FormattedMessage
                          id="SingleProjectPage.seeMore"
                          description="See more projects link text in single project page"
                          defaultMessage="Ver más creaciones"/>
                    </TranslatedLink>
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <SocialShareNav
                    title={!loading ? project.name : ""}
                    hideTitle/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8">
                <h2>
                  <FormattedMessage
                      id="SingleProjectPage.description"
                      description="Description word in single project page"
                      defaultMessage="Descripción"/>
                </h2>
                <p className="gray">
                  {
                    !loading ?
                        project.description
                        :
                        <Skeleton count={3}/>
                  }
                </p>
              </div>
              <div className="col-12 col-md-4">
                <ul className="list-group-flush pl-0 gray">
                  <li className="list-group-item pl-0 pr-0 pt-md-0 border-top-0">
                    {
                      !loading ?
                          project.client &&
                          <span className="text-weight-bold black">
                            <FormattedMessage
                                id="SingleProjectPage.client"
                                description="Client word in single project page"
                                defaultMessage="Cliente"/>:&nbsp;
                            <span className="text-weight-light">{project.client}</span>
                          </span>
                          :
                          <Skeleton/>
                    }
                  </li>
                  <li className="list-group-item pl-0 pr-0 border-bottom-0">
                    {
                      !loading ?
                          project.sector &&
                          <span className="text-weight-bold black">
                            <FormattedMessage
                                id="SingleProjectPage.sector"
                                description="Sector word in single project page"
                                defaultMessage="Sector"/>:&nbsp;
                            <span className="text-weight-light">{project.sector.name}, {project.sector.market.name}</span>
                          </span>
                          :
                          <Skeleton/>
                    }
                  </li>
                  <li className="list-group-item pl-0 pr-0 border-bottom-0">
                    {
                      !loading ?
                          project.country &&
                          <span className="text-weight-bold black">
                            <FormattedMessage
                                id="SingleProjectPage.country"
                                description="Country word in single project page"
                                defaultMessage="País"/>:&nbsp;
                            <span className="text-weight-light">{project.country.name}</span>
                          </span>
                          :
                          <Skeleton/>
                    }
                  </li>
                  <li className="list-group-item pl-0 pr-0 border-bottom-0">
                    {
                      !loading ?
                          project.patterns.edges.length > 0 &&
                          <span>
                            <span className="text-weight-bold black">
                              <FormattedMessage
                                id="SingleProjectPage.product"
                                description="Product word in single project page"
                                defaultMessage="Productos"/>:&nbsp;
                            </span>
                            {
                              project.patterns.edges.map((pattern, index) => (
                                  <span key={index}>
                                    <Link to={getTranslatedLink(PRODUCT_DETAILS, {productSlug: pattern.node.slug})}>
                                      {pattern.node.altName}
                                    </Link>
                                    {
                                      (index + 1) < project.patterns.edges.length &&
                                      <span>, </span>
                                    }
                                  </span>
                              ))
                            }
                          </span>
                          :
                          <Skeleton/>
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {
                  !loading ?
                      <HTMLContent html={project.content}/>
                      :
                      <Skeleton count={5}/>
                }
              </div>
            </div>
            <RelatedProjects
                projectId={project ? project.id : null}/>
          </div>
        </SubHeroContent>
      </div>
  );
};

SingleProjectPage.propTypes = {
  singleProjectQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      heroImageThumbLarge: PropTypes.string,
      description: PropTypes.string,
      content: PropTypes.string,
      client: PropTypes.string,
      sector: PropTypes.shape({
        name: PropTypes.string,
        market:  PropTypes.shape({
          name: PropTypes.string,
        })
      }),
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
      patterns: PropTypes.shape({
        edges: PropTypes.array,
      })
    })
  }),
};

//  TODO: update wuery with hooks
export default graphql(
    singleProjectQuery,
    {
      name: "singleProjectQuery",
      options: props => {
        return {
          variables: {
            slug: props.match.params.projectSlug
          }
        }
      }
    }
)(SingleProjectPage);
