import { FormattedMessage } from "react-intl";

export const getInformationOfReferences = (edge) => {
  let referenceName;
  let referenceImage;
  let itHasCatalogs = edge.node.items.edges?.map(({ node }) => {
    return node.pattern !== null ? true : false
  })[0];

  edge.node.items.edges?.map(({ node }, i) => {

    if (edge.node.items.edges.length === 1) {
      referenceName = node.reference?.altName
    } else {
      let tempName = node.reference?.altName
      let followingReferenceName = edge.node.items.edges[i + 1]?.node.reference?.altName

      let isEqual = followingReferenceName === tempName ? true : false
      referenceName = isEqual ? node.reference?.altName : <FormattedMessage id="RecordsPage.multipleItemsTitle" />
    }
    referenceImage = node.reference?.imageThumbLarge
  })

  return { referenceName, referenceImage, itHasCatalogs }
}