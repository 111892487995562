import gql from 'graphql-tag';

const singlePatternGeneralFragment = gql`
  fragment SinglePatternGeneralFragment on GeneralNode {
    id
    weight
    width
#    gauge
    topcoat
    printType
    rollLength
    composition
  }
`;

export default singlePatternGeneralFragment;