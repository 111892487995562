import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames/bind";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { useMutation } from "@apollo/react-hooks";
import forgotPasswordMutation from "modules/auth/scenes/ForgotPassword/components/ForgotPasswordForm/mutation";
import { AUTH_RESET_PASSWORD, SPGlobalRoutes } from "routes";
import {
  getCurrentInstanceId,
  getCurrentLanguageAndInstanceCode,
  isMARINE,
} from "services/instances";

export default function ForgotPasswordForm() {
  const intl = useIntl();
  const [forgotPassword, { loading, error, data }] = useMutation(
    forgotPasswordMutation
  );

  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage(validationMessages.email))
      .required(intl.formatMessage(validationMessages.required)),
  });

  /**
   * Handles form submit
   * @param values {Object}
   */
  function onSubmit(values) {
    const [lang, instance] = getCurrentLanguageAndInstanceCode();
    const url = SPGlobalRoutes[AUTH_RESET_PASSWORD][lang];
    forgotPassword({
      variables: {
        input: {
          ...values,
          instanceId: getCurrentInstanceId(),
          url: `${window.location.protocol}//${window.location.host}/${lang}-${instance}${url}`,
        },
      },
    });
  }

  if (error)
    return (
      <div>
        <p>
          <FormattedMessage
            id="ForgotPasswordForm.errorMessage"
            defaultMessage="Ocurrió un error al enviar el correo, por favor recarga la página e intenta de nuevo."
          />
        </p>
      </div>
    );

  if (data && data.forgotPassword.emailSent === true)
    return (
      <div>
        <p>
          <FormattedMessage
            id="ForgotPasswordForm.successMessage"
            defaultMessage="Por favor revisa tu correo y haz click en el enlace para continuar el proceso."
          />
        </p>
      </div>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form id="forgot-password-form" className="form" noValidate>
          <div className="form-group">
            <label htmlFor="email">
              <FormattedMessage
                id="ForgotPasswordForm.emailLabel"
                defaultMessage="Correo electrónico"
              />
              *
            </label>
            <Field
              type="email"
              name="email"
              id="email"
              className="form-control"
            />
            <FormErrorMessage name="email" />
          </div>
          <div className="text-center">
            <button
              disabled={loading}
              type="submit"
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
            >
              {loading ? (
                <FormattedMessage
                  id="ForgotPasswordForm.submitButtonLoading"
                  defaultMessage="Enviando..."
                />
              ) : (
                <FormattedMessage
                  id="ForgotPasswordForm.submitButton"
                  defaultMessage="Enviar"
                />
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
