import PropTypes from 'prop-types';
import {
  BACKGROUND_IMAGE_CARD_TYPE, COMMON_CARD_TYPE,
  FEATURED_CARD_TYPE
} from "modules/blog/PostsList/components/BlogPostCard/constants";
import classNames from 'classnames/bind';

import './styles/BlogPostTitle.scss';
import {Link} from "react-router-dom";

const BlogPostTitle = ({title, href, cardType}) => {
  return (
      <Link
          to={href}
          className={classNames({
            "blog-post-title": true,
            "blog-post-title--img-bg": cardType === BACKGROUND_IMAGE_CARD_TYPE,
            "blog-post-title--featured": cardType === FEATURED_CARD_TYPE,
            "blog-post-title--common": cardType === COMMON_CARD_TYPE
          })}>
        <h3 className="blog-post-title__heading">{title}</h3>
      </Link>
  );
};

BlogPostTitle.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  cardType: PropTypes.oneOf([
    BACKGROUND_IMAGE_CARD_TYPE,
    FEATURED_CARD_TYPE,
    COMMON_CARD_TYPE
  ])
};

export default BlogPostTitle;
