import { useState } from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl"
import RequestSampleForAClientModal from "./components/RequestSampleForAClientModal"

export default function RequestSamplesForAClientBtn({mutationLoading, handleSubmit}) {
  const [showSampleForAClientModal, setShowSampleForAClientModal] = useState(false)

  function toggleSampleForAClientModal() {
    setShowSampleForAClientModal(!showSampleForAClientModal)
  }

  return (
      <>
        <RequestSampleForAClientModal
          isOpen={showSampleForAClientModal}
          toggle={toggleSampleForAClientModal}
          mutationLoading={mutationLoading}
          handleSubmit={handleSubmit}
        />
        <div className="request-samples-btn">
          <div className="text-center pt-4">
            <button
              className="btn btn-outline-dark"
              disabled={mutationLoading}
              onClick={toggleSampleForAClientModal}
            >
              <FormattedMessage
                id="SamplesCart.btnRequestSamplesForAClientLabel"
                defaultMessage="Solicitar muestras para un Cliente"
              />
            </button>
          </div>
        </div>
      </>
  )
}

RequestSamplesForAClientBtn.propTypes = {
  mutationLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};