import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import TranslatedLink from "routes/components/TranslatedLink";
import { BRAND_DETAILS } from "routes";
import "utils/components/FullWidthArticles/components/FullWidthArticle/styles/FullWidthArticle.scss";
import { isMARINE } from "services/instances";
import "./styles/brand.scss";
import { BRAND_TYPE_MSG } from "./constants";

const Brand = ({ brand, reversed }) => {
  const intl = useIntl();
  const TYPE_BRAND_MESSAGE = brand?.brandType
    ? BRAND_TYPE_MSG[brand.brandType]
    : BRAND_TYPE_MSG.BRAND;

  return (
    <div className="fw-article">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div
              className="fw-article-background"
              style={{
                backgroundImage: `url(${brand.imageThumbMedium})`,
              }}
            >
              {brand.reverseLogoSmall && (
                <img
                  className={classNames({
                    logo: true,
                    default: !reversed,
                    reversed: reversed,
                  })}
                  src={brand.reverseLogoSmall}
                  alt={brand.title}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="fw-article-content">
              <div
                className={classNames({
                  "fw-article-content__wrapper": true,
                  "pr-3 text-right": reversed,
                })}
              >
                <h3>
                  {brand.title}
                  <br />
                  <small>{intl.formatMessage(TYPE_BRAND_MESSAGE)}</small>
                </h3>
                <p>{brand.description}</p>
                {brand.externalLink ? (
                  <a
                    href="https://new-landing.spradling.group/en-us/permacool"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames({
                      "btn btn-dark my-3 text-size-extra-small text-size-lg-small": true,
                      "btn-prussian-blue": isMARINE(),
                    })}
                  >
                    <FormattedMessage
                      id="WhyChooseUsPage.Brands.buttonLabel"
                      description="Button 'See More' in Why choose us page, brands section"
                      defaultMessage="Ver Más"
                    />
                  </a>
                ) : (
                  <TranslatedLink
                    params={{
                      brandSlug: brand.slug,
                    }}
                    name={BRAND_DETAILS}
                    className={classNames({
                      "btn btn-dark my-3 text-size-extra-small text-size-lg-small": true,
                      "btn-prussian-blue": isMARINE(),
                    })}
                  >
                    <FormattedMessage
                      id="WhyChooseUsPage.Brands.buttonLabel"
                      description="Button 'See More' in Why choose us page, brands section"
                      defaultMessage="Ver Más"
                    />
                  </TranslatedLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Brand.propTypes = {
  brand: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    brandType: PropTypes.string,
    description: PropTypes.string.isRequired,
    reverseLogoSmall: PropTypes.string,
    imageThumbMedium: PropTypes.string,
    externalLink: PropTypes.string,
  }).isRequired,
  reversed: PropTypes.bool,
};

export default Brand;
