import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames/bind";
import "./styles/SignUpForm.scss";
import {
  createProfileMutation,
  createUserMutation,
} from "components/AuthModal/components/SignUpForm/mutations";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import tokenAuth from "components/AuthModal/components/SignInForm/mutation";
import { authLogin } from "services/auth";
import CompanyTypeField from "modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField";
import CountriesField from "components/AuthModal/components/SignUpForm/components/CountriesField";
import CityField from "components/AuthModal/components/SignUpForm/components/CityField";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import RegionField from "components/AuthModal/components/SignUpForm/components/RegionField";
import { isMARINE } from "services/instances";
import useLocationByZipcode from "utils/hooks/useLocationByZipcode";
import useToggle from "utils/hooks/useToggable";

export default function SignUpForm({
  submitButtonLabel,
  disableSubmitButton = false,
  onAfterSubmit,
}) {
  const intl = useIntl();
  const [graphQLErrors, setGraphQLErrors] = useState(null);
  const [success, setSuccess] = useToggle();
  const [isPrivacyPolicyAccepted, togglePrivacyPolicyAccepted] = useToggle();
  const { locationByZipcode, handleLocationByZipcode } = useLocationByZipcode();
  const [createUser] = useMutation(createUserMutation, {
    onError(error) {
      setGraphQLErrors(error.graphQLErrors);
    },
  });
  const [createProfile] = useMutation(createProfileMutation, {
    onError(error) {
      setGraphQLErrors(error.graphQLErrors);
    },
  });
  const [login] = useMutation(tokenAuth);

  /**
   * Handler of the form submit.
   * @param values {Object}
   * @param setSubmitting {Function}
   */
  function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const createUserInput = _.pick(values, [
      "firstName",
      "lastName",
      "email",
      "password",
    ]);
    let createProfileInput = _.pick(values, [
      "phone",
      "company",
      "companyType",
      "position",
      "cityId",
      "address",
      "zipCode",
    ]);

    const createUserPromise = createUser({
      variables: {
        input: createUserInput,
      },
    });
    createUserPromise
      .then((response) => {
        createProfileInput["userId"] = response.data.createUser.user.id;

        const createProfilePromise = createProfile({
          variables: {
            input: createProfileInput,
          },
        });
        createProfilePromise.then(() => {
          setSuccess(true);
          login({
            variables: {
              input: {
                email: createUserInput["email"],
                password: createUserInput["password"],
              },
            },
          }).then((response) => {
            const { tokenAuth } = response.data;
            authLogin(tokenAuth.token);
            setSubmitting(false);
          });
        });
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  /**
   * Handle finish button click (go back to homepage button)
   */
  function handleFinishButtonClick() {
    onAfterSubmit(); // this closes the modal
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    regionId: "",
    cityId: "",
    address: "",
    zipCode: "",
    phone: "",
    company: "",
    position: "",
    companyType: "",
    password: "",
    rePassword: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string()
      .email(intl.formatMessage(validationMessages.email))
      .required(intl.formatMessage(validationMessages.required)),
    countryId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    regionId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    cityId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    position: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    // TODO validate it's a phone number
    address: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    zipCode: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    phone: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    company: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyType: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    password: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    rePassword: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
  });

  if (success)
    return (
      <div className="sign-up-success text-center pt-5 pb-5">
        <i className="sign-up-success__icon fa fa-check-circle-o mb-5" />
        <h4 className="sign-up-success__title mb-5">
          <FormattedMessage
            id="SignUpForm.successMessage"
            description="Success message in SignUpForm component"
            defaultMessage="Has creado tu cuenta de forma exitosa"
          />
        </h4>
        <button
          type="button"
          className={classNames({
            "btn btn-dark": true,
            "btn-prussian-blue": isMARINE(),
          })}
          onClick={handleFinishButtonClick}
        >
          <FormattedMessage
            id="SignUpForm.closeBtnLabel"
            description="Label for close button in SignUpForm component"
            defaultMessage="Cerrar"
          />
        </button>
      </div>
    );

  // TODO write custom validator for rePassword to make sure is the same as the password
  return (
    <div className="sign-up-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => {

          return (
            <Form id="sign-up-form" className="form">
              <div className="form-row">
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="firstName">
                    <FormattedMessage
                      id="SignUpForm.firstNameField"
                      description="First name field of the Sign Up form"
                      defaultMessage="Nombre"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="form-control"
                  />
                  <FormErrorMessage name="firstName" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="lastName">
                    <FormattedMessage
                      id="SignUpForm.lastNameField"
                      description="Last name field of the Sign Up form"
                      defaultMessage="Apellidos"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="form-control"
                  />
                  <FormErrorMessage name="lastName" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="email">
                    <FormattedMessage
                      id="SignUpForm.email"
                      description="Email field of the Sign Up form"
                      defaultMessage="Email"
                    />
                    *
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                  />
                  <FormErrorMessage name="email" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="company">
                    <FormattedMessage
                      id="SignUpForm.company"
                      description="Business field of the Sign Up form"
                      defaultMessage="Empresa"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="company"
                    id="company"
                    className="form-control"
                  />
                  <FormErrorMessage name="company" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="position">
                    <FormattedMessage
                      id="SignUpForm.position"
                      description="Position field of the Sign Up form"
                      defaultMessage="Cargo"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="position"
                    id="position"
                    className="form-control"
                  />
                  <FormErrorMessage name="position" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="companyType">
                    <FormattedMessage
                      id="SignUpForm.companyType"
                      description="Company type field of the Sign Up form"
                      defaultMessage="Tipo de compañía"
                    />
                    *
                  </label>
                  <CompanyTypeField />
                  <FormErrorMessage name="companyType" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="countryId">
                    <FormattedMessage
                      id="SignUpForm.countryId"
                      description="Country field of the Sign Up form"
                      defaultMessage="País"
                    />
                    *
                  </label>
                  <CountriesField
                    countryByZipcode={locationByZipcode?.country}
                  />
                  <FormErrorMessage name="countryId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="zipCode">
                    <FormattedMessage
                      id="SignUpForm.zipCode"
                      description="Zip code field of the Sign Up form"
                      defaultMessage="Código postal"
                    />
                    *
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    className="form-control"
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("zipCode", value);
                    }}
                    onBlur={() => handleLocationByZipcode(values.zipCode)}
                  />
                  <FormErrorMessage name="zipCode" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="regionId">
                    <FormattedMessage
                      id="SignUpForm.regionId"
                      description="Region field of the Sign Up form"
                      defaultMessage="Región"
                    />
                    *
                  </label>
                  <RegionField
                    countryId={values.countryId}
                    regionByZipcode={locationByZipcode?.state}
                  />
                  <FormErrorMessage name="regionId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="cityId">
                    <FormattedMessage
                      id="SignUpForm.city"
                      description="City field of the Sign Up form"
                      defaultMessage="Ciudad"
                    />
                    *
                  </label>
                  <CityField
                    regionId={values.regionId}
                    cityByZipcode={locationByZipcode?.city}
                  />
                  <FormErrorMessage name="cityId" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="address">
                    <FormattedMessage
                      id="SignUpForm.address"
                      description="Address field of the Sign Up form"
                      defaultMessage="Dirección"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                  />
                  <FormErrorMessage name="address" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="phone">
                    <FormattedMessage
                      id="SignUpForm.phone"
                      description="Phone field of the Sign Up form"
                      defaultMessage="Teléfono"
                    />
                    *
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                  />
                  <FormErrorMessage name="phone" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="password">
                    <FormattedMessage
                      id="SignUpForm.password"
                      description="Password field of the Sign Up form"
                      defaultMessage="Contraseña"
                    />
                    *
                  </label>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                  />
                  <FormErrorMessage name="password" />
                </div>
                <div className="form-group col-12 col-lg-4">
                  <label htmlFor="rePassword">
                    <FormattedMessage
                      id="SignUpForm.rePassword"
                      description="Password field of the Sign Up form"
                      defaultMessage="Confirmar contraseña"
                    />
                    *
                  </label>
                  <Field
                    type="password"
                    name="rePassword"
                    id="rePassword"
                    className="form-control"
                  />
                  <FormErrorMessage name="rePassword" />
                </div>
              </div>
              {/* <div className="text-center sign-up-form__privacy-policy"> */}
              <div className="text-center">
                <PrivacyPolicyCheckbox onChange={togglePrivacyPolicyAccepted} />
              </div>
              <div className="text-center">
                {graphQLErrors &&
                  graphQLErrors.map((error, index) => (
                    <div
                      key={index}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {error.message}
                    </div>
                  ))}
              </div>
              <div className="text-center">
                <button
                  disabled={
                    isSubmitting ||
                    disableSubmitButton ||
                    !isPrivacyPolicyAccepted
                  }
                  type="submit"
                  className={classNames({
                    "btn btn-dark": true,
                    "btn-prussian-blue": isMARINE(),
                  })}
                >
                  {isSubmitting ? (
                    <FormattedMessage
                      id="SignUpForm.submitButtonLoading"
                      description="Submit button of the Sign Up form"
                      defaultMessage="Guardando"
                    />
                  ) : submitButtonLabel ? (
                    intl.formatMessage(submitButtonLabel)
                  ) : (
                    <FormattedMessage
                      id="SignUpForm.submitButton"
                      description="Submit button of the Sign Up form in loading state"
                      defaultMessage="Registrarme"
                    />
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

SignUpForm.propTypes = {
  onAfterSubmit: PropTypes.func,
  disableSubmitButton: PropTypes.bool,
  submitButtonLabel: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
};
