import { defineMessages } from "react-intl";

export const TITLES = {
  global: {
    id: "WhyChooseUsPage.heroTitle",
    description: "Why choose us page header title",
    defaultMessage: "Calidad, diseño y tecnología",
    values: {
      sup: (...chunks) => <sup>{chunks}</sup>,
      br: <br />,
    },
  },
  resources: {
    id: "WhyChooseUsPage.resources.heroTitle",
    description: "Why choose us page header title for SIR",
    defaultMessage: "Spradling Resources, your one-stop-shop",
    values: {
      sup: (...chunks) => <sup>{chunks}</sup>,
      br: <br />,
    },
  },
};

export const DESIGNS = [
  {
    title: defineMessages({
      unlimitedDesignDesignTitle: {
        id: "Designs.unlimitedDesignDesignTitle",
        description: "Designnology unlimitedDesign",
        defaultMessage: "Nuestro diseño no tiene límites",
      },
    }).unlimitedDesignDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      unlimitedDesignDesignText: {
        id: "Designs.unlimitedDesignDesignText",
        description: "Designnology unlimitedDesign text",
        defaultMessage:
          "En Spradling siempre estamos en la búsqueda de ir más allá de los límites. El diseño es prioridad para nosotros; por eso, hacemos todo para estar a la vanguardia y sorprender con nuestros productos en cualquier parte del mundo.",
      },
    }).unlimitedDesignDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-1.jpg",
  },
  {
    title: defineMessages({
      crossesBordersDesignTitle: {
        id: "Designs.crossesBordersDesignTitle",
        description: "Designnology crossesBorders",
        defaultMessage: "Equipo de Diseño que traspasa fronteras",
      },
    }).crossesBordersDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      crossesBordersDesignText: {
        id: "Designs.crossesBordersDesignText",
        description: "Designnology crossesBorders text",
        defaultMessage:
          "Contamos con un equipo de diseño global que trabaja en cada detalle para asegurar una amplia variedad en grabados, estampados y gama de colores de acuerdo a las últimas tendencias del diseño mundial. Es un equipo con mucha experiencia y conocimiento de los mercados en los que estamos presentes, siempre están en la búsqueda de nuevos caminos basados en el presente y futuro de la moda y el diseño.",
      },
    }).crossesBordersDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-2.jpg",
  },
  {
    title: defineMessages({
      inspirationDesignTitle: {
        id: "Designs.inspirationDesignTitle",
        description: "Designnology inspiration",
        defaultMessage: "Nuestras fuentes de inspiración",
      },
    }).inspirationDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      inspirationDesignText: {
        id: "Designs.inspirationDesignText",
        description: "Designnology inspiration text",
        defaultMessage:
          "Las macrotendencias son la fuente de inspiración y el entendimiento de las necesidades de los consumidores son las que detonan nuestro proceso creativo. Desarrollamos diseños que pueden ser geométricos, animales, minerales, tecnológicos, florales, étnicos, entre otros. Vamos en línea con las últimas tendencias en color jugando con unicolores, bicolores y metalizados. Además, generamos texturas para dar apariencia textil, cuero o geométrica con diferentes niveles de brillo.",
      },
    }).inspirationDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-3.jpg",
  },
];

export const RESOURCES_DESIGNS = [
  {
    title: defineMessages({
      unlimitedDesignDesignTitle: {
        id: "Designs.unlimitedDesignDesignTitle.resources",
        description: "Designnology unlimitedDesign",
        defaultMessage: "Our brand is known across the globe",
      },
    }).unlimitedDesignDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      unlimitedDesignDesignText: {
        id: "Designs.unlimitedDesignDesignText.resources",
        description: "Designnology unlimitedDesign text",
        defaultMessage:
          "Spradling International, Inc is the leading provider of engineered-coated fabrics to Commercial, Contract, Marine and Transportation markets. With the inclusion of Spradling Resources’ value-added services, our customers are now able to combine and address all of their needs in one place.",
      },
    }).unlimitedDesignDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-1-SIR.jpg",
  },
  {
    title: defineMessages({
      crossesBordersDesignTitle: {
        id: "Designs.crossesBordersDesignTitle.resources",
        description: "Designnology crossesBorders",
        defaultMessage: "Your one-stop-shop",
      },
    }).crossesBordersDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      crossesBordersDesignText: {
        id: "Designs.crossesBordersDesignText.resources",
        description: "Designnology crossesBorders text",
        defaultMessage:
          "Customers are no longer required to send materials to a third party for lamination, perforation or customized leather cutting. They can now purchase coated fabrics and automotive leather from the same place where required additional services will be performed. This streamlines the buying process and provides savings by eliminating freight, paperwork, multiple vendors and complexity.",
      },
    }).crossesBordersDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-2-SIR.jpg",
  },
  {
    title: defineMessages({
      inspirationDesignTitle: {
        id: "Designs.inspirationDesignTitle.resources",
        description: "Designnology inspiration",
        defaultMessage: "Our design has no limits",
      },
    }).inspirationDesignTitle,
    subtitle: null,
    summary: null,
    text: defineMessages({
      inspirationDesignText: {
        id: "Designs.inspirationDesignText.resources",
        description: "Designnology inspiration text",
        defaultMessage:
          "World-class coated fabric is only the first step in creating any great finished product. Spradling Resources offers creative solutions to get you one, or even two, steps closer to your final product. Additional value-added services include: complete lamination solutions, perforation, custom debossing, technical coated fabrics sourcing and leather cutting.",
      },
    }).inspirationDesignText,
    image:
      "https://s3-us-west-2.amazonaws.com/spradling-misc/images-for-django-fixtures/why-choose-us/design-3-SIR.jpg",
  },
];
