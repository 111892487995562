import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CreateDownloadLogForm from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadProtectModal/components/CreateDownloadLogForm";
import { FormattedMessage } from "react-intl";
import { isMARINE } from "services/instances";
import useToggle from "utils/hooks/useToggable";

export default function DownloadProtectModal({
  id,
  isOpen,
  title,
  subtitle,
  patternId,
  resourceType,
  resourceUrl,
  toggle,
}) {
  const [success, toggleSuccess] = useToggle();

  return (
    <Modal size={"lg"} toggle={toggle} isOpen={isOpen}>
      <ModalHeader className="text-center" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <h5 className="text-center text-weight-light gray mb-4">{subtitle}</h5>
        {success ? (
          <div className="text-center">
            <a
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
              href={resourceUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage
                id="DownloadProtectModal.label"
                defaultMessage="Descargar"
              />
            </a>
          </div>
        ) : (
          <CreateDownloadLogForm
            id={id}
            patternId={patternId}
            resourceType={resourceType}
            resourceUrl={resourceUrl}
            onCompleted={toggleSuccess}
          />
        )}
      </ModalBody>
    </Modal>
  );
}

DownloadProtectModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  patternId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    "zipped-images",
    "specs-sheet",
    "approved-cleaners",
  ]).isRequired,
  resourceUrl: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};
