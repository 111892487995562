import gql from "graphql-tag";

const singleProjectQuery = gql`
  query SingleProjectQuery ($slug: String) {
    project(slug: $slug) {
      id
      name
      description
      heroImageThumbLarge
      client
      content
      country {
        id
        name
      }
      patterns {
        edges {
          node {
            id
            altName
            slug
          }
        }
      }
      sector {
        id
        name
        market {
          id
          name
        }
      }
    }
  }
`;

export default singleProjectQuery;