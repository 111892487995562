import PropTypes from 'prop-types';
import "./styles/GlossaryWords.scss";
import classNames from 'classnames';

const GlossaryWords = ({ selectedGlossary }) => {
  return (
    <div className={classNames({
      'overflow-auto': true,
      'h-100': !selectedGlossary.isRelatedWords,
      "glossary-words-container": selectedGlossary.isRelatedWords,
    })}>
      {selectedGlossary?.glossaryWords?.length > 0
        ? selectedGlossary?.glossaryWords?.map((word) => {
          return <p
            key={word.node.id}
            className='glossary-word'
          >
            <span className='text-uppercase glossary-word-name'>{word.node.name}</span> - <span className='font-weight-light'>{word.node.definition}</span>
          </p>
        })
        : <p className='text-center glossary-no-words-found'>No words found...</p>
      }
    </div>
  )
}

GlossaryWords.propTypes = {
  selectedGlossary: PropTypes.shape({
    isRelatedWords: PropTypes.bool.isRequired,
    glossaryWords: PropTypes.array.isRequired
  }).isRequired
};

export default GlossaryWords;