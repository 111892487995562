import PropTypes from "prop-types";
import { PROSEAT_CHARACTERTISTICS } from "../../constants/characteristics";
import CharacteristicDetails from "../CharacteristicDetails";
import CharacteristicsList from "../CharacteristicsList";

const CharacteristicWrapperMobile = ({ 
  characteristicDetail, 
  handleChangeAndShowCharacteristicInfo,
  handleHideCharacteristicInfo
}) => {
  return (
    <>
      <CharacteristicsList
        characteristics={PROSEAT_CHARACTERTISTICS}
        handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
      />
      <CharacteristicDetails
        details={characteristicDetail}
        hideCharacteristicInfo={handleHideCharacteristicInfo}
      />
    </>
  )
}

CharacteristicWrapperMobile.propTypes = {
  characteristicDetail: PropTypes.object,
  handleChangeAndShowCharacteristicInfo: PropTypes.func,
  handleHideCharacteristicInfo: PropTypes.func
}

export default CharacteristicWrapperMobile;