import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal, ModalBody } from "reactstrap";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H3_TAG } from "utils/components/TitleUnderlined/constants";
import { existCookie, CREATE_COOKIE } from "./cookieManagement/cookie";
import { CURRENT_INSTANCE_LABEL } from "./consts";
import useLocationModal from "./hooks/useLocationModal";

const MessageGeolocation = ({
  currentInstance,
  locationInstance,
  isOpen,
  handleModal,
}) => {

  const { showModal } = useLocationModal(existCookie, currentInstance);

  /**
   WARNING: temporal hardfix to redirect to EUROPE_INSTANCE_PAGE on prod
   locationInstance = EUROPE_INSTANCE_PAGE
   *  */
  const hrefLocationInstance =
    locationInstance && !locationInstance.includes("https")
      ? locationInstance[0]
      : locationInstance;

  return (
    showModal && (
      <Modal size="lg" isOpen={isOpen} centered>
        <ModalBody>
          <div>
            <TitleUnderlined hTag={H3_TAG}>
              <FormattedMessage
                id="MessageGeolocation.title"
                description="Title of modal Message Geolocation"
                defaultMessage="Estás entrando a <strong>Spradling {currentInstance}</strong>,{br}para ver productos en tu ubicación, debes:"
                values={{
                  br: <br />,
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  currentInstance: CURRENT_INSTANCE_LABEL[currentInstance],
                }}
              />
            </TitleUnderlined>
            <div className="d-block text-center">
              <button
                className="btn btn-dark m-1 w-50"
                onClick={() => handleModal()}
              >
                <a className="link-unstyled" href={hrefLocationInstance}>
                  <FormattedMessage
                    id="MessageGeolocation.btn.go"
                    description="btn go on modal Message Geolocation"
                    defaultMessage="Ir a Spradling {locationInstance}"
                    values={{
                      locationInstance:
                        locationInstance &&
                        CURRENT_INSTANCE_LABEL[
                          locationInstance[0].split("-")[1]
                        ],
                    }}
                  />
                </a>
              </button>
              <button
                className="btn btn-outline-dark m-1 w-50"
                onClick={(e) => {
                  handleModal(e);
                  CREATE_COOKIE();
                }}
                id="stayInSpradling"
              >
                <FormattedMessage
                  id="MessageGeolocation.btn.stay"
                  description="btn stay on modal Message Geolocation"
                  defaultMessage="Quedarme en Spradling {currentInstance}"
                  values={{
                    currentInstance: CURRENT_INSTANCE_LABEL[currentInstance],
                  }}
                />
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  );
};

MessageGeolocation.propTypes = {
  currentInstance: PropTypes.string,
  locationInstance: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isOpen: PropTypes.bool,
  handleModal: PropTypes.func,
};

export default MessageGeolocation;
