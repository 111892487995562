import PropTypes from "prop-types";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import CharacteristicWrapperDesktop from "./components/CharacteristicWrapperDesktop";
import CharacteristicWrapperMobile from "./components/CharacteristicWrapperMobile";

const ProseatCharacteristics = ({
  characteristicDetail,
  handleChangeAndShowCharacteristicInfo,
  handleHideCharacteristicInfo
}) => {
  const isDesktop = useMediaQuery(LG);

  return (
    <div className="col-12 col-xl-5 d-flex flex-column p-0 mx-auto">
      {isDesktop  
        ? <CharacteristicWrapperDesktop
          characteristicDetail={characteristicDetail}
          handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
          handleHideCharacteristicInfo={handleHideCharacteristicInfo}
        />
        : <CharacteristicWrapperMobile
          characteristicDetail={characteristicDetail}
          handleChangeAndShowCharacteristicInfo={handleChangeAndShowCharacteristicInfo}
          handleHideCharacteristicInfo={handleHideCharacteristicInfo}
        />
      }
    </div>
  )
}

ProseatCharacteristics.propTypes = {
  characteristicDetail: PropTypes.object,
  handleChangeAndShowCharacteristicInfo: PropTypes.func,
  handleHideCharacteristicInfo: PropTypes.func
}

export default ProseatCharacteristics;