import { defineMessages } from "react-intl";

export const RegionFieldPlaceholder = defineMessages({
  withoutCountry: {
    id: "RegionField.withoutCountry",
    description:
      "Placeholder for autocomplete select when there's no country selected for RegionField component",
    defaultMessage: "Primero selecciona un país",
  },
  withCountry: {
    id: "RegionField.withCountry",
    description:
      "Placeholder for autocomplete select when there's a country selected for RegionField component",
    defaultMessage: "Escribe tu región",
  },
  withCountryMX: {
    id: "RegionField.withCountryMX",
    description:
      "Placeholder for autocomplete select when there's a country selected for RegionField component",
    defaultMessage: "Escribe tu estado",
  },
});