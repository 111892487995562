import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import onClickOutside from "react-onclickoutside";
import "./styles/HeaderAuthBox.scss";
import {
  AUTH_MODAL_SIGN_IN_CONTEXT,
  AUTH_MODAL_SIGN_UP_CONTEXT,
} from "components/AuthModal/constants";
import { authLogout } from "services/auth";
import { isMARINE } from "services/instances";
import AuthBoxUserCard from "components/Header/components/HeaderAuthBox/components/AuthBoxUserCard";
function HeaderAuthBox({
  headerReducer,
  authReducer,
  setAuthModalContext,
  toggleAuthModal,
  closeAuthMenu,
}) {
  function handleButtonClick(context) {
    setAuthModalContext(context);
    toggleAuthModal();
  }

  HeaderAuthBox.handleClickOutside = () => closeAuthMenu();

  if (!headerReducer.authMenuIsOpen) return null;

  return (
    <div
      className={classNames({
        "header-auth-box": true,
        "header-auth-box--open": headerReducer.authMenuIsOpen,
        "header-auth-box--guest": !authReducer.isAuthenticated,
        "header-auth-box--authenticated": authReducer.isAuthenticated,
      })}
    >
      {!authReducer.isAuthenticated ? (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4>
              <FormattedMessage
                id="HeaderAuthBox.newTitle"
                description="Title for new users in Header Auth Box component"
                defaultMessage="¿Eres nuevo?"
              />
            </h4>
            <p>
              <FormattedMessage
                id="HeaderAuthBox.newSubtitle"
                description="Subtitle for new users in Header Auth Box component"
                defaultMessage="Crea una cuenta y descarga catálogos y muestras"
              />
            </p>
            <button
              onClick={() => handleButtonClick(AUTH_MODAL_SIGN_UP_CONTEXT)}
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
            >
              <FormattedMessage
                id="HeaderAuthBox.newButtonLabel"
                description="Button label for new users in Header Auth Box component"
                defaultMessage="Registrarme"
              />
            </button>
          </div>
          <div className="col-12 col-md-6">
            <h4>
              <FormattedMessage
                id="HeaderAuthBox.existentTitle"
                description="Title for existent users in Header Auth Box component"
                defaultMessage="Usuarios registrados"
              />
            </h4>
            <p>
              <FormattedMessage
                id="HeaderAuthBox.existentSubtitle"
                description="Subtitle for existent users in Header Auth Box component"
                defaultMessage="Ingresa a tu cuenta y revisa el historial de descargas"
              />
            </p>
            <button
              onClick={() => handleButtonClick(AUTH_MODAL_SIGN_IN_CONTEXT)}
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
            >
              <FormattedMessage
                id="HeaderAuthBox.existentButtonLabel"
                description="Button label for existent users in Header Auth Box component"
                defaultMessage="Ingresar"
              />
            </button>
          </div>
        </div>
      ) : (
        <div>
          <AuthBoxUserCard />
          <hr />
          <p className="mb-0" onClick={authLogout}>
            <FormattedMessage
              id="HeaderAuthBox.signOff"
              description="Label for sign off link"
              defaultMessage="Cerrar sesión"
            />
          </p>
        </div>
      )}
    </div>
  );
}

HeaderAuthBox.propTypes = {
  headerReducer: PropTypes.object,
  authReducer: PropTypes.object,
  setAuthModalContext: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  closeAuthMenu: PropTypes.func,
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderAuthBox.handleClickOutside,
};

export default onClickOutside(HeaderAuthBox, clickOutsideConfig);
