import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames/bind";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { createJobApplicationMutation } from "modules/about/VacantDetails/components/JobApplicationForm/mutations";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import FormErrorMessage from "utils/components/FormErrorMessage";
import validationMessages from "utils/forms/messages";
import { getCurrentInstanceId, isMARINE } from "services/instances";
import "./styles/JobApplicationForm.scss";
import CountriesField from "components/AuthModal/components/SignUpForm/components/CountriesField";
import CityField from "components/AuthModal/components/SignUpForm/components/CityField";

function JobApplicationForm({ vacantId }) {
  const intl = useIntl();
  const [createJobApplication, { loading, error }] = useMutation(
    createJobApplicationMutation
  );

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    linkedIn: "",
    countryId: "",
    cityId: "",
    file: null,
    acceptedPrivacyPolicy: false,
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    phone: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    linkedIn: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    countryId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    cityId: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    file: Yup.mixed().required(intl.formatMessage(validationMessages.required)),
    acceptedPrivacyPolicy: Yup.bool().oneOf(
      [true],
      intl.formatMessage(validationMessages.required)
    ),
  });

  function handleSubmit(values, { resetForm, setSubmitting }) {
    // use destructuring for acceptedPrivacyPolicy value of values
    // eslint-disable-next-line no-unused-vars
    const { acceptedPrivacyPolicy, countryId } = values;
    createJobApplication({
      variables: {
        vacantId,
        instanceId: getCurrentInstanceId(),
        ...values,
      },
    });
    setSubmitting(false);
    resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values: { countryId, file, acceptedPrivacyPolicy },
        setFieldValue,
        isSubmitting,
      }) => (
        <Form
          id="job-application-form"
          noValidate
          className="form contact-box-form"
        >
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fullName">
                  <FormattedMessage
                    id="JobApplicationForm.fullNameField"
                    description="Name field of the Job application form"
                    defaultMessage="Nombre y apellido"
                  />
                </label>
                <Field
                  id="fullName"
                  name="fullName"
                  type="text"
                  className="form-control form-control-lg"
                />
                <FormErrorMessage name="fullName" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">
                  <FormattedMessage
                    id="JobApplicationForm.emailField"
                    description="Email field of the Job application form"
                    defaultMessage="Tu Email"
                  />
                </label>
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className="form-control form-control-lg"
                />
                <FormErrorMessage name="email" />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">
                  <FormattedMessage
                    id="JobApplicationForm.phoneField"
                    description="Phone field of the Job application form"
                    defaultMessage="Tu Teléfono"
                  />
                </label>
                <Field
                  id="phone"
                  name="phone"
                  type="phone"
                  className="form-control form-control-lg"
                />
                <FormErrorMessage name="phone" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="linkedIn">
                  <FormattedMessage
                    id="JobApplicationForm.linkedinField"
                    description="LinkedIn field of the Job application form"
                    defaultMessage="Link de LinkedIn"
                  />
                </label>
                <Field
                  id="linkedIn"
                  name="linkedIn"
                  type="text"
                  className="form-control form-control-lg"
                />
                <FormErrorMessage name="linkedIn" />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="countryId">
                <FormattedMessage
                  id="SignUpForm.countryId"
                  description="Country field of the Sign Up form"
                  defaultMessage="País"
                />
              </label>
              <CountriesField className="form-control-lg" />
              <FormErrorMessage name="countryId" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="cityId">
                <FormattedMessage
                  id="SignUpForm.city"
                  description="City field of the Sign Up form"
                  defaultMessage="Ciudad"
                />
              </label>
              <CityField
                className="form-control-lg"
                regionId={countryId}
                searchByCountryId
              />
              <FormErrorMessage name="cityId" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12 text-center m-2">
              <label
                htmlFor="file"
                className={classNames({
                  "btn btn-outline-dark pl-5 pr-5": true,
                  "btn-outline-prussian-blue": isMARINE(),
                })}
              >
                {!file ? (
                  <FormattedMessage
                    id="JobApplicationForm.attachCVButton"
                    description="Attach CV button of the Job application form"
                    defaultMessage="Adjuntar hoja de vida (.doc, .pdf)"
                  />
                ) : (
                  file.name
                )}
              </label>
              <input
                className="d-none"
                id="file"
                name="file"
                type="file"
                accept=".doc,.pdf"
                disabled={isSubmitting || loading}
                onChange={(e) =>
                  setFieldValue("file", e.currentTarget.files[0])
                }
                placeholder={
                  <FormattedMessage
                    id="JobApplicationForm.attachCVButton"
                    description="Attach CV button of the Job application form"
                    defaultMessage="Adjuntar hoja de vida (.doc, .pdf)"
                  />
                }
              />
              <FormErrorMessage name="file" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12 text-center">
              <PrivacyPolicyCheckbox
                onChange={(checked) =>
                  setFieldValue("acceptedPrivacyPolicy", checked)
                }
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-dark"
              disabled={!acceptedPrivacyPolicy || isSubmitting || loading}
              type="submit"
            >
              {isSubmitting || loading ? (
                <FormattedMessage
                  id="ContactBox.submitButtonLoading"
                  description="Submit button label of the Contact form in loading state"
                  defaultMessage="Enviando"
                />
              ) : (
                <FormattedMessage
                  id="ContactBox.submitButtonLabel"
                  description="Submit button label for contact box form"
                  defaultMessage="Enviar"
                />
              )}
            </button>
            <span className="form-error-message form-error-message--red m-2">
              {error}
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
}

JobApplicationForm.propTypes = {
  vacantId: PropTypes.string,
};

export default JobApplicationForm;
