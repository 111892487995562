import PropTypes from "prop-types";
import Home from "modules/Home";
import { Route, Switch } from "react-router-dom";
import { SPGlobalRoutes } from "routes";
import Header from "components/Header";
import Footer from "components/Footer";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";
import { Flip, ToastContainer } from "react-toastify";
import useGoToIpLocation from "modules/Home/components/MessageGeolocation/hooks/useGoToIpLocation";
import MessageGeolocation from "modules/Home/components/MessageGeolocation";
import { isLATAM } from "services/instances";
import {Redirect} from "react-router-dom";
import { HOTJAR_BY_LANG } from "services/seo/constants/hotjarCodes";
import SEO from "services/seo/components/SEO";

const RenderRoutes = ({ match }) => {
  const {
    currentInstance,
    currentLanguage,
    locationInstance,
    showMessageLocation,
    toggleMessageLocation,
  } = useGoToIpLocation({ onlyInstance: true });

  if (isLATAM() && currentLanguage !== "es") 
    return <Redirect to="/" />

  return (
    <ConnectedIntlProvider>
      <>
        <Header locationInstance={locationInstance} />
        <SEO onlyHotjarCode hotjarInfo={HOTJAR_BY_LANG[currentInstance]} />
        <Switch>
          <Route exact path={`${match.path}`} component={Home} />
          {Object.keys(SPGlobalRoutes).map((key, index) => {
            const route = SPGlobalRoutes[key];
            return (
              <Route
                key={index}
                exact={route.exact}
                path={`${match.path}${route[match.params.language]}`}
                component={route.component}
              />
            );
          })}
        </Switch>
        <Footer locationInstance={locationInstance} />
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Flip}
        />
        <MessageGeolocation
          currentInstance={currentInstance}
          locationInstance={locationInstance}
          isOpen={showMessageLocation}
          handleModal={toggleMessageLocation}
        />
      </>
    </ConnectedIntlProvider>
  );
};

RenderRoutes.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default RenderRoutes;
