import PropTypes from "prop-types";
import { ModalEditInformationProfile } from "./components/ModalEditInformationProfile";
import { useState } from "react";
import { useIntl } from "react-intl";
import { UserInformationSection } from "./components/UserInformationSection";
import { getUserInformationFields } from "./constants/userInformationFields";
import "./styles/ProfileInformation.scss";

export const ProfileInformation = (props) => {
  const intl = useIntl();
  const { userInformation } = props;
  const [
    seeModalOfEditingUserInformation,
    setSeeModalOfEditingUserInformation,
  ] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const callBackToHideModal = () => setSeeModalOfEditingUserInformation(false);

  const formattedMessage = (textToTranslate) => {
    return intl.formatMessage({ id: textToTranslate });
  };

  const userInformationFields = getUserInformationFields(
    userInformation,
    formattedMessage
  );

  const profileInformationSections = Object.values(userInformationFields);

  const handleEditClick = (section) => {
    setSelectedSection(section);
    setSeeModalOfEditingUserInformation(true);
  };

  return (
    <div className="ProfileInformation pt-4 pb-4">
      <div className="d-md-flex flex-wrap">
        {profileInformationSections.map((section, index) => (
          <UserInformationSection
            key={index}
            title={section.title}
            fields={section.fields}
            onEditClick={() => handleEditClick(section)}
          />
        ))}
      </div>
      {selectedSection && (
        <ModalEditInformationProfile
          showModal={seeModalOfEditingUserInformation}
          callbackToHide={callBackToHideModal}
          section={selectedSection}
        />
      )}
    </div>
  );
};

ProfileInformation.propTypes = {
  userInformation: PropTypes.object,
};
