import proseatLogo from "./img/Logo-Proseat.png"
import "./styles/ProseatHeader.scss";

const ProseatHeader = () => {
  return (
    <div className="proseat-header-containter d-xl-flex align-items-center p-0">
      <div className="col-12 col-xl-2 m-0 p-0 h-100 d-flex justify-content-center justify-content-md-start align-items-center">
        <img src={proseatLogo} alt="proseat-logo" width={180} />
      </div>
      <p className="proseat-header--subtitle font-weight-light ml-lg-3 my-0 p-0 pt-3 pt-lg-0 pl-md-2">
        A professional guide to boat seat construction for optimal life.
      </p>
    </div>
  )
}

export default ProseatHeader;