import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import { ECOSENSE_SCENE } from "services/seo/constants/scenes";
import SEO from "services/seo/components/SEO";
import {
  FirstSection,
  TextSecction,
  VideoSection,
  EnvolvingSecction,
  EmbracingSection,
  Cards,
  Certifications,
  VideoWorkingSection,
} from "./components";
import "./styles/ecosense-page.scss";
import EcoSustainableReports from "./components/EcoSustainableReports";
import { BioSection } from "./components/BioSection";

const EcosensePage = () => (
  <div style={{ margin: 0, padding: 0 }} className="container-fluid">
    <ScrollTopOnMount />
    <FirstSection />
    <TextSecction />
    <VideoSection />
    <EnvolvingSecction />
    <BioSection />
    <EmbracingSection />
    <Cards />
    <Certifications />
    <VideoWorkingSection />
    <EcoSustainableReports />
    <SEO scene={ECOSENSE_SCENE} />
  </div>
);

export default EcosensePage;
