import PropTypes from "prop-types";
import { default as ProseatImageChairHotspot } from "react-image-hotspots";
import { hotspotsResultData } from "./constants/hotspots";
import proseatChair from "./img/silla-sin-puntos-cut.png";
import "./styles/ImageHotspot.scss";

const ProseatImageHotspotSection = ({ handleChangeAndShowCharacteristicInfo }) => {
  const hotspotPoints = hotspotsResultData(handleChangeAndShowCharacteristicInfo);

  return (
    <div className="image-hotspot--container col-12 mx-lg-0 col-md-6 col-lg-5 mx-md-auto mx-lg-auto col-xl-7 p-0 m-0">
      <ProseatImageChairHotspot
        src={proseatChair}
        alt='proseat-chair-image'
        hotspots={hotspotPoints}
        hideFullscreenControl
        hideZoomControls
      />
    </div>
  )
}

ProseatImageHotspotSection.propTypes = {
  handleChangeAndShowCharacteristicInfo: PropTypes.func
}

export default ProseatImageHotspotSection;