import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/HeaderProductsDropdown.scss";
import HeaderMarketSegmentContainer
  from "components/Header/components/HeaderMainMenu/components/HeaderProductsDropdown/components/HeaderMarketSegment/container";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import {DOCUMENT_LIBRARY, PRODUCTS_LIBRARY} from "routes";
import {isMARINE, getTranslatedLink} from "services/instances";

/**
 * @return {null}
 */
export function HeaderProductsDropdown({isOpen, onLinkClick, markets, closeMobileMenu}) {
  const [marketsCounter, setMarketsCounter] = useState(0);

  useEffect(() => {
    // Determine the amount of markets which contains at least 1 pattern, that's for CSS distribution purposes
    let marketsWithPatterns = 0;

    markets?.edges.forEach(market => {
      // For each market, check every sector to look for products
      let sectorsWithPatterns = 0;
      market.node.sectors.edges.forEach((sector) => {
        if (sector.node.patternSet.totalCount > 0)
          sectorsWithPatterns++;
      });

      // If there are at least 1 sector containing patterns, increase the counter
      if (sectorsWithPatterns > 0)
        marketsWithPatterns++;
    });

    setMarketsCounter(marketsWithPatterns);
  }, [markets]);

  /**
   * Handles click on the bottom links to close the menu
   */
  function handleLinkClick() {
    onLinkClick();
    closeMobileMenu();
  }

  /**
   * Handles clicks outside this component
   */
  HeaderProductsDropdown.handleClickOutside = () => {
    if (window.innerWidth >= 992)  // bootstrap's lg-up
      onLinkClick();
  };

  // This is to prevent react-onclickoutside to be executing callback
  // on every click in the website
  if (!isOpen)
    return null;

  return (
      <div className={classNames({
        "header-products-dropdown": true,
        "header-products-dropdown--open": isOpen
      })}>
        <div className="header-products-dropdown__top">
          <div className={classNames({
            "products-dropdown-markets": true,
            [`products-dropdown-markets--has-${marketsCounter}-children`]: true
          })}>
            {
              markets?.edges.map((market, index) => {
                return (
                    <HeaderMarketSegmentContainer
                        key={index}
                        market={market.node}
                        onLinkClick={onLinkClick}/>
                );
              })
            }
          </div>
        </div>
        <div className="header-products-dropdown__bottom">
          <div className="row">
            <div className="col-12 col-lg-5">
              <Link
                  to={getTranslatedLink(PRODUCTS_LIBRARY, {})}
                  className="products-dropdown-link"
                  onClick={handleLinkClick}>
                <span
                  className={classNames({
                    "products-dropdown-link__title": true,
                    "products-dropdown-link__title--marine": isMARINE()
                  })}>
                  <FormattedMessage
                      id="HeaderProductsDropdown.collections"
                      description="Colecciones link text in products dropdown"
                      defaultMessage="Colecciones"/>
                </span>
                <span className="products-dropdown-link__subtitle">
                  <FormattedMessage
                      id="HeaderProductsDropdown.collectionsSubtitle"
                      description="Subtitle for Colecciones link text in products dropdown"
                      defaultMessage="Conoce todas las colecciones que tenemos en Spradling."/>
                </span>
              </Link>
            </div>
            <div className="col-12 col-lg-7 pl-lg-5">
              <Link
                  to={getTranslatedLink(DOCUMENT_LIBRARY, {})}
                  className="products-dropdown-link"
                  onClick={handleLinkClick}>
                <span
                  className={classNames({
                      "products-dropdown-link__title": true,
                      "products-dropdown-link__title--marine": isMARINE()
                    })}>
                  <FormattedMessage
                      id="HeaderProductsDropdown.library"
                      description="Biblioteca de documentos link text in products dropdown"
                      defaultMessage="Biblioteca de documentos"/>
                </span>
                <span className="products-dropdown-link__subtitle">
                  <FormattedMessage
                      id="HeaderProductsDropdown.librarySubtitle"
                      description="Subtitle for Biblioteca de documentos link text in products dropdown"
                      defaultMessage="Todos los documentos de interés y consulta que necesites los tenemos disponibles para ti."/>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
  )
}

HeaderProductsDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onLinkClick: PropTypes.func,
  closeMobileMenu: PropTypes.func,
  markets: PropTypes.shape({
    edges: PropTypes.array.isRequired
  }).isRequired
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderProductsDropdown.handleClickOutside
};

export default onClickOutside(HeaderProductsDropdown, clickOutsideConfig);
