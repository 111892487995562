import gql from "graphql-tag"

export const createDownloadLog = gql`
  mutation CreateDownloadLog($input: CreateDownloadLogInput!) {
    createDownloadLog(input: $input) {
      downloadLog {
        id
        resourceType
        resourceUrl
      }
    }
  }
`