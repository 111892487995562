import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { QUERY_PARAM_REFERENCE } from "modules/products/ProductDetailsPage/constants";
import QueryParams from "services/browser-history/QueryParams";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "./styles/ReferenceCard.scss";
import { PRODUCT_DETAILS } from "routes";
import { getTranslatedLink } from "services/instances";
import NewProductLabel from "utils/components/NewProductLabel";

export default function ReferenceCard({
  patternSlug,
  reference,
  hideStockAndPantone,
}) {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new QueryParams(location.search);
  const selectedReference = queryParams.find(QUERY_PARAM_REFERENCE);

  /**
   * Handle reference click to update the query params with the new reference
   */
  function handleReferenceClick() {
    if (!reference || !patternSlug) return false;

    history.push({
      pathname: getTranslatedLink(PRODUCT_DETAILS, {
        productSlug: patternSlug,
      }),
      search: `?${QUERY_PARAM_REFERENCE}=${reference.color}`,
    });
  }

  return (
    <div
      onClick={handleReferenceClick}
      className={classNames({
        "reference-card mb-md-3": true,
        "reference-card--active": reference?.color === selectedReference,
        "position-relative": reference?.isNew,
      })}
    >
      {reference?.isNew && <NewProductLabel />}
      <div
        className="reference-card__image"
        style={{
          backgroundImage: reference && `url("${reference.imageThumbSmall}")`,
        }}
      />
      <div className="reference-card__name">
        <h4>{reference ? reference.color : <Skeleton />}</h4>
        {!hideStockAndPantone && (
          <>
            <p className="mb-0">
              {reference ? reference.stock : <Skeleton width={50} />}
            </p>
            <p className="mb-0">
              {reference ? reference.pantone : <Skeleton width={50} />}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

ReferenceCard.propTypes = {
  patternSlug: PropTypes.string,
  reference: PropTypes.shape({
    id: PropTypes.string,
    color: PropTypes.string,
    imageThumbMedium: PropTypes.string,
    imageThumbSmall: PropTypes.string,
    stock: PropTypes.string,
    pantone: PropTypes.string,
    isNew: PropTypes.bool,
  }),
  hideStockAndPantone: PropTypes.bool,
};
