import gql from "graphql-tag";

const singleMarketQuery = gql`
  query SingleMarketQuery($slug: String, $instanceIds: [ID]!) {
    market(slug: $slug) {
      id
      name
      description
      imageThumbLarge
      pageTitle
      metaDescriptions
      sectors {
        edges {
          node {
            id
            slug
            name
            description
            imageThumbMedium
            patternSet(products_Instances: $instanceIds) {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default singleMarketQuery;
