import {
  isUSA,
  isMARINE,
  isRESOURCES,
  getInstanceLink,
} from "services/instances";
import {
  INSTANCE_MARINE,
  INSTANCE_RESOURCES,
  INSTANCE_USA,
} from "services/instances/constants";
import iconInternational from "../img/icon-international.png";
import iconMarine from "../img/icon-marine.png";
import iconResources from "../img/icon-resources.png";

export const SUBINSTANCES_USA = [
  {
    instance: "Spradling International",
    to: getInstanceLink(INSTANCE_USA),
    is_actived: isUSA(),
    icon: iconInternational,
  },
  {
    instance: "Spradling Marine & RV",
    to: getInstanceLink(INSTANCE_MARINE),
    is_actived: isMARINE(),
    icon: iconMarine,
  },
  {
    instance: "Spradling Resources",
    to: getInstanceLink(INSTANCE_RESOURCES),
    is_actived: isRESOURCES(),
    icon: iconResources,
  },
];
