import PropTypes from "prop-types";
import classNames from "classnames";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "modules/proseat/ProseatPage/constants/breakpoints";
import arrowDown from "./img/arrow-down.svg";
import "./styles/ProseatCharacteristics.scss";

const CharacteristicsList = ({ 
  characteristics,
  handleChangeAndShowCharacteristicInfo
}) => {
  const isDesktop = useMediaQuery(LG);

  return characteristics.map((characteristic) => {
    const { key, title, characteristicNumber } = characteristic

    return (
      <div
        key={`item_${key}`}
        className={classNames({
          "proseat-characteristic--content d-flex align-items-center cursor-pointer": true,
          "justify-content-start align-self-end": isDesktop,
          "align-self-end": !isDesktop
        })}
        onClick={() => handleChangeAndShowCharacteristicInfo(characteristic)}
      >
        <div
          className="proseat-characteristic--number d-flex align-items-center justify-content-center rounded-circle">
          {characteristicNumber}
        </div>
        <p className={classNames({
          "proseat-characteristic--description p-0 m-0": true,
          "d-flex justify-content-between align-items-center w-100": !isDesktop
          })}>
          <span>{title}</span>
          {!isDesktop && 
            <img src={arrowDown} className="mr-3" alt="arrow-down-icon" width={12} height={12} />}
        </p>
      </div>
    )
  })
}

CharacteristicsList.propTypes = {
  characteristics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    characteristicNumber: PropTypes.number,
    title: PropTypes.string
  })),
  handleChangeAndShowCharacteristicInfo: PropTypes.func
}

export default CharacteristicsList;