import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import singlePatternZippedImagesQuery from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadZippedImagesButton/query";
import { FormattedMessage } from "react-intl";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import { getCurrentInstanceId } from "services/instances";
import { createDownloadLog } from "modules/products/ProductDetailsPage/components/ProductSidebar/mutations";
import DownloadProtectModal from "modules/products/ProductDetailsPage/components/ProductSidebar/components/DownloadProtectModal";
import meQuery from "utils/queries/MeQuery";

export default function DownloadZippedImagesButton({
  authReducer: { isAuthenticated },
}) {
  const pattern = useContext(PatternContext);
  const { data: userData } = useQuery(meQuery);
  const [isOpen, setIsOpen] = useState(false);
  const [logDownload] = useMutation(createDownloadLog, {
    onError(error) {
      console.warn(error);
    },
  });
  const { loading, error, data } = useQuery(singlePatternZippedImagesQuery, {
    variables: {
      id: pattern.id,
      instanceId: getCurrentInstanceId(),
    },
  });

  /**
   * Handles link click to create a download log
   */
  function handleClick() {
    logDownload({
      variables: {
        input: {
          email: userData && userData.me.email,
          patternId: pattern.id,
          resourceType: "zipped-images",
          resourceUrl: data.pattern.zippedImagesUrl,
        },
      },
    });
  }

  if (loading || error) return null;

  return (
    <>
      {isAuthenticated ? (
        <a
          className="btn btn-outline-dark btn-lg btn-block"
          href={data.pattern.zippedImagesUrl}
          rel="noopener noreferrer"
          target="_blank"
          onClick={handleClick}
        >
          <FormattedMessage
            id="DownloadZippedImagesButton.label"
            description="Product details download zipped images button label"
            defaultMessage="Descargar imágenes"
          />
        </a>
      ) : (
        <button
          className="btn btn-outline-dark btn-lg btn-block"
          onClick={() => setIsOpen(true)}
        >
          <FormattedMessage
            id="DownloadZippedImagesButton.label"
            description="Product details download zipped images button label"
            defaultMessage="Descargar imágenes"
          />
        </button>
      )}
      <DownloadProtectModal
        title={
          <FormattedMessage
            id="DownloadZippedImagesButton.downloadModalTitle"
            defaultMessage="Descargar imágenes"
          />
        }
        subtitle={
          <FormattedMessage
            id="DownloadZippedImagesButton.downloadModalSubtitle"
            defaultMessage="Queremos mantenernos en contacto. Déjanos tus datos y descarga las imágenes de ésta referencia en alta definición."
          />
        }
        id="download-catalog-form"
        isOpen={isOpen}
        patternId={pattern.id}
        resourceType="zipped-images"
        resourceUrl={data.pattern.zippedImagesUrl}
        toggle={() => setIsOpen(!isOpen)}
      />
    </>
  );
}

DownloadZippedImagesButton.propTypes = {
  authReducer: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
};
