import gql from "graphql-tag";

const brandsQuery = gql`
  query BrandsQuery($instanceIds: [ID], $isVisible: Boolean) {
    brands(instances: $instanceIds, isVisible: $isVisible) {
      edges {
        node {
          id
          title
          slug
          brandType
          reverseLogoSmall
          imageThumbMedium
          description
          content
          externalLink
        }
      }
    }
  }
`;

export default brandsQuery;
