import { useEffect } from 'react';
import { useState } from 'react';
// import { switchAnimationValidation } from '../constants/switchAnimationValidation';
import { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { lookUpGlossaryWord } from '../queries';
import { useIntl } from 'react-intl';
import { initializedSelectedGlossary } from '../constants/initializedSelectedGlossary';
import { useRef } from 'react';

const useSelectedGlossary = (currentCharacteristic, glossaryTypes) => {
  const intl = useIntl();
  const [selectedGlossary, setSelectedGlossary] = useState({});
  const { loading: loadingLookupWords, refetch } = useQuery(lookUpGlossaryWord);
  const defaultGlossaryRelatedWords = useRef([]);

  const handleSelectedGlossary = (glossarySelected) => {
    setSelectedGlossary((prevState) => ({
      ...prevState,
      ...glossarySelected,
    }))
  };

  const handleSelectedGlossaryWhenNotOpenCharacteristic = useCallback(() => {
    const initialStateGlossaryValue = initializedSelectedGlossary(glossaryTypes);

    return initialStateGlossaryValue
  }, [glossaryTypes])

  const handleLoadWordOfCurrentCharacteristic = useCallback(async (characteristic) => {
    const title = characteristic.characteristicDetail?.title;
    const descriptionId = characteristic.characteristicDetail?.description.props.id;
    const description = intl.formatMessage({ id: descriptionId }).replace(/\(/g, '\\(').replace(/\)/g, '\\)');
    const fullTextData = title + " " + description

    try {
      const { data } = await refetch({ text: fullTextData })

      if (data) {
        defaultGlossaryRelatedWords.current = data.lookupWords.edges

        setSelectedGlossary(prevState => ({
          ...prevState,
          isRelatedWords: true,
          glossaryWords: data.lookupWords.edges
        }));
      }
    } catch (error) {
      throw new Error(`Error loading glossary words of current characteristic: ${error}`)
    }
  }, [refetch, intl])

  useEffect(() => {
    if (currentCharacteristic && !currentCharacteristic.showCharacteristicInfo) {
      const initialGlossaryState = handleSelectedGlossaryWhenNotOpenCharacteristic();
      setSelectedGlossary(initialGlossaryState)
    } else {
      handleLoadWordOfCurrentCharacteristic(currentCharacteristic)
    }
  }, [currentCharacteristic, glossaryTypes, handleSelectedGlossaryWhenNotOpenCharacteristic, handleLoadWordOfCurrentCharacteristic])

  return {
    selectedGlossary,
    handleSelectedGlossary,
    defaultGlossaryRelatedWords,
    loadingLookupWords
  };
};

export default useSelectedGlossary;