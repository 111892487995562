import { useState } from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import classNames from "classnames/bind";
import "./styles/HeaderMarketSegment.scss";
import {MARKET_DETAILS, PRODUCTS_LIBRARY} from "routes";
import {QUERY_PARAM_SECTOR} from "modules/products/ProductListPage/constants";
import {isMARINE, getTranslatedLink} from "services/instances";
function HeaderMarketSegment({market, onLinkClick, closeMobileMenu}) {
  const [isOpen, setIsOpen] = useState(false)

  function handleTitleClick() {
    setIsOpen(!isOpen)
  }

  function handleLinkClick() {
    setIsOpen(false)
    onLinkClick();
    closeMobileMenu();
  }

  let sectorsWithPatterns = 0;
    market.sectors.edges.forEach((sector) => {
      if (sector.node.patternSet.totalCount > 0)
        sectorsWithPatterns++;
    });

    if (sectorsWithPatterns === 0)
      return null;  // Don't render markets if they doesn't have at least 1 related product

    return (
        <div className={classNames({
          "header-market-segment": true,
          "header-market-segment--open": isOpen
        })}>
          <div className="d-flex justify-content-between align-items-center">
            <Link
                to={getTranslatedLink(MARKET_DETAILS, {marketSlug: market.slug})}
                onClick={handleLinkClick}>
              <h5
                  className={classNames({
                    "header-market-segment__title": true,
                    "header-market-segment__title--marine": isMARINE()
                  })}>
                {market.name}
              </h5>
            </Link>
            <i 
              className={classNames({
                "header-market-segment__chevron": true,
                "sp-chevron-down": !isOpen,
                "sp-chevron-up": isOpen
              })}
              onClick={handleTitleClick}/>
          </div>
          <ul className="header-market-segment__links">
            {
              market.sectors.edges.map((sector, index) => {
                if (sector.node.patternSet.totalCount === 0)
                  return null;  // Don't render sectors without associated products for the current instance

                return (
                    <li key={index}>
                      <Link
                          to={{
                            pathname: `${getTranslatedLink(PRODUCTS_LIBRARY, {})}`,
                            search: `?${QUERY_PARAM_SECTOR}=${sector.node.id}`
                          }}
                          onClick={handleLinkClick}>
                        {sector.node.name}
                      </Link>
                    </li>
                )
              })
            }
          </ul>
        </div>
    )
}

HeaderMarketSegment.propTypes = {
  market: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sectors: PropTypes.object.isRequired,
    slug: PropTypes.object.isRequired
  }).isRequired,
  onLinkClick: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired
};

export default HeaderMarketSegment;