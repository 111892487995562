import { useState } from "react";
import { CHARACTERISTIC_GENERAL_INFO, initialCharacteristicInfoState } from "../components/ProseatImageHotspot/components/ProseatCharacteristics/constants/characteristicsInfo";

const useCharacteristicDetails = () => {
  const [characteristicDetails, setCharacteristicDetails] = useState(initialCharacteristicInfoState);

  const handleChangeAndShowCharacteristicInfo = (characteristicCurrentItem) => {
    const currentCharacteristic = 
      characteristicCurrentItem?.key ? characteristicCurrentItem.key : characteristicCurrentItem

    setCharacteristicDetails((prevState) => ({
      ...prevState,
      showCharacteristicInfo: true,
      characteristicDetail: CHARACTERISTIC_GENERAL_INFO[currentCharacteristic]
    }))
  }

  const handleHideCharacteristicInfo = () => {
    setCharacteristicDetails((prevState) => ({
      ...prevState,
      showCharacteristicInfo: !prevState.showCharacteristicInfo
    }))
  }

  return { 
    characteristicDetails,
    handleChangeAndShowCharacteristicInfo,
    handleHideCharacteristicInfo
  }
}

export default useCharacteristicDetails;