import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/TitleUnderlined.scss";
import {H1_TAG, H2_TAG, H3_TAG, H4_TAG} from "utils/components/TitleUnderlined/constants";

const TitleUnderlined = ({children, className, align, color, hTag}) => {
  const classes = {
    "title-underlined": true,
    [`title-underlined--align-${align}`]: true,
    [`title-underlined--color-${color}`]: true,
    [className]: className !== undefined
  };

  switch (hTag) {
    case H1_TAG:
      return (
          <h1 className={classNames(classes)}>
            {children}
          </h1>
      );

    case H2_TAG:
      return (
          <h2 className={classNames(classes)}>
            {children}
          </h2>
      );

    case H3_TAG:
      return (
          <h3 className={classNames(classes)}>
            {children}
          </h3>
      );

    case H4_TAG:
      return (
          <h4 className={classNames(classes)}>
            {children}
          </h4>
      );

    default:
  }
};

TitleUnderlined.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hTag: PropTypes.oneOf([H1_TAG, H2_TAG, H3_TAG, H4_TAG]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.oneOf(['white', 'black'])
};

TitleUnderlined.defaultProps = {
  align: 'center',
  color: 'black',
  hTag: H1_TAG
};

export default TitleUnderlined;