import gql from "graphql-tag";

const relatedProjectsQuery = gql`
    query RelatedProjectsQuery ($projectId: String, $first: Int) {
        projects(relatedWith: $projectId, first: $first) {
            edges {
                node {
                    id
                    name
                    slug
                    listingImageThumbSmall
                    client
                    sector {
                        id
                        name
                        market {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default relatedProjectsQuery;