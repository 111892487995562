import * as Yup from "yup";
import validationMessages from "utils/forms/messages";

const GENERAL_CONTACT_FOR_SAMPLES_TITLES_MSGS = {
  id: "ContactForSamplesPage.title",
  defaultMessage: "Contáctanos para solicitar catálogo",
};

export const CONTACT_FOR_SAMPLES_TITLES_MSGS = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_TITLES_MSGS,
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    id: "ContactForSamplesPage.mx.title",
    defaultMessage:
      "Contáctanos para solicitar más información sobre nuestros productos",
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_TITLES_MSGS,
  [process.env.REACT_APP_SM_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_TITLES_MSGS,
  [process.env.REACT_APP_RC_INSTANCE_ID]: {
    id: "ContactForSamplesPage.resource.title",
    defaultMessage: "Contact us",
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_TITLES_MSGS,
};

export const INITIAL_VALUES_GLOBAL = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  countryId: "",
  regionId: "",
  cityId: "",
  companyName: "",
  companyType: "",
  zipCode: "",
  message: "",
};

export const INITIAL_VALUES_LATAM_MX_MARINE = {
  ...INITIAL_VALUES_GLOBAL,
  position: "",
  address: "",
};

export const INITIAL_VALUES_RESOURCES = {
  ...INITIAL_VALUES_GLOBAL,
};

export const VALIDATION_SCHEMA_GLOBAL = (intl) => ({
  firstName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  lastName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  email: Yup.string()
    .email(intl.formatMessage(validationMessages.email))
    .required(intl.formatMessage(validationMessages.required)),
  phone: Yup.number().required(intl.formatMessage(validationMessages.required)),
  countryId: Yup.string().when("isMEXICO()", {
    is: false,
    then: Yup.string().required(validationMessages.required),
    otherwise: Yup.string(),
  }),
  regionId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  cityId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  companyName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  companyType: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  zipCode: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  message: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
});

export const VALIDATION_SCHEMA_LATAM_MX_MARINE = (intl) => ({
  ...VALIDATION_SCHEMA_GLOBAL(intl),
  position: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  address: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
});

export const FORM_SCHEMA = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_LATAM_MX_MARINE,
    validationSchema: (intl) => VALIDATION_SCHEMA_LATAM_MX_MARINE(intl),
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_LATAM_MX_MARINE,
    validationSchema: (intl) => VALIDATION_SCHEMA_LATAM_MX_MARINE(intl),
  },
  [process.env.REACT_APP_SM_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_LATAM_MX_MARINE,
    validationSchema: (intl) => VALIDATION_SCHEMA_LATAM_MX_MARINE(intl),
  },
  [process.env.REACT_APP_RC_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_RESOURCES,
    validationSchema: (intl) => VALIDATION_SCHEMA_GLOBAL(intl),
  },
};
