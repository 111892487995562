import PropTypes from "prop-types";
import { initializedSelectedGlossary } from "../../constants/initializedSelectedGlossary";

const SeeAllTermsBtn = ({ selectedGlossary, glossary, handleSelectedGlossary }) => {
  const restoreDefaultGlossary = initializedSelectedGlossary(glossary)

  return (
    selectedGlossary.isRelatedWords &&  
    <p 
      className="col-12 glossary-section--footer-related-word h-100 d-flex align-items-center justify-content-center"
      onClick={() => handleSelectedGlossary(restoreDefaultGlossary)}
      >
      See all terms
    </p>
  )
}

SeeAllTermsBtn.propTypes = {
  selectedGlossary: PropTypes.object.isRequired,
  glossary: PropTypes.array.isRequired,
  handleSelectedGlossary: PropTypes.func.isRequired
};

export default SeeAllTermsBtn;