import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/ProductCard.scss";
import ProductCardImage from "modules/products/ProductListPage/components/ProductList/components/ProductCard/components/ProductCardImage";
import ProductCardName from "modules/products/ProductListPage/components/ProductList/components/ProductCard/components/ProductCardName";
import Skeleton from "react-loading-skeleton";

const ProductCard = ({ pattern, loading = false }) => (
  <div className="product-card">
    <div className="product-card__name">
      <ProductCardName
        loading={loading}
        name={loading ? null : pattern.name}
        altName={loading ? null : pattern.altName}
        slug={loading ? null : pattern.slug}
      />
    </div>
    <div className="product-card__image">
      <ProductCardImage
        loading={loading}
        image={!loading && pattern.imageThumbSmall}
        slug={loading ? null : pattern.slug}
        isNew={pattern?.isNew}
      />
    </div>
    <div className="product-card__info">
      <div className="row no-gutters">
        <div className="col-6">
          {loading ? (
            <p>
              <Skeleton />
            </p>
          ) : (
            <p>
              {pattern.products.totalCount}&nbsp;
              {pattern.products.totalCount > 1 ? (
                <FormattedMessage
                  id="ProductCard.colors"
                  description="Colors plural word in product card (single product in products list)"
                  defaultMessage="colors"
                />
              ) : (
                <FormattedMessage
                  id="ProductCard.color"
                  description="Colors singular word in product card (single product in products list)"
                  defaultMessage="color"
                />
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  </div>
);

ProductCard.propTypes = {
  loading: PropTypes.bool,
  pattern: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    imageThumbSmall: PropTypes.string.isRequired,
    altName: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    products: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }),
};

export default ProductCard;
