import gql from "graphql-tag";

export const certificatesPlantsQuery = gql`
  query getCertificates($domainsUrl: String!, $slug: String) {
    certificates(domainsUrl: $domainsUrl, slug: $slug){
      edges{
        node{
          id
          title
          description
          image
          imageThumb
          active  
        }
      }
    }
  }
`;
