import { useContext, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { FaSearch } from 'react-icons/fa';
import { GlassMagnifier } from 'react-image-magnifiers';
import CollectionContext from './context/CollectionContext';
import ScrollTopOnMount from 'utils/components/ScrollTopOnMount';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import SamplesList from './Components/SamplesList';
import useGetCollectionByID from '../hooks/useGetCollectionByID';
import HeaderQuickShip from '../components/Header';
import './styles/sample-details.scss';
import { customStylesSelctCollectionQuickShipPage, customStylesSelctQuickShipPage } from './styles/customStylesSelctQuickShipPage';
import { useQuery } from 'react-apollo';
import { collectionsQuery } from '../queries';
import upArrowSelectorQuickship from './img/up-arrow-selector-quickship.svg'
import downArrowSelectorQuickship from './img/down-arrow-selector-quickship.svg'
import leftArrowQuickshipPage from './img/left-arrow-quickship-page.svg'
import mobileLeftArrowQuickshipPage from './img/mobile-left-arrow-go-back-quickship-page.svg'
import { TRANSLATED_ROUTES_QUICKSHIP_PAGE } from '../components/Header/Constants/urlsQuinckshipPage';
import useGetFiltersByCollectionID from '../hooks/useGetFiltersByCollectionID';
import { capitalizeWords } from '../utils/capitalizeWords';
import useGetFilteredProductQuickshipPage from '../hooks/useGetFilteredProductQuickshipPage';

const QuickshipPageParamSlug = () => {
  const { id, language, instance } = useParams();
  const history = useHistory();
  const { data } = useQuery(collectionsQuery);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const { collectionItems } = useGetCollectionByID(id || 'UXVpY2tzaGlwQ29sbGVjdGlvbk5vZGU6Mw==');
  const { filtersByCollection } = useGetFiltersByCollectionID(id, language);
  const [filters, setFilters] = useState({
    market: null,
    design: null,
    characteristics: null,
  });

  const { filteredProducts } = useGetFilteredProductQuickshipPage(id, filters.market ? [filters.market] : [], filters.design ? [filters.design] : []);
  console.log('🚀🚀🚀  > > > > QuickshipPageParamSlug > > > > filteredProducts:', filteredProducts);
  
  const { selectedItemCollection, setSelectedItemCollection } = useContext(CollectionContext);

  const collectionData = data?.quickshipCollections?.edges;

  const currentInstance = instance ? `-${instance}` : '';

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [quantity, setQuantity] = useState(1); // Nueva línea para manejar la cantidad

  useEffect(() => {
    if (!selectedItemCollection) {
      setSelectedItemCollection(
        collectionItems?.productonquickshipSet?.edges[0]?.node
      );
      return
    }

    setSelectedItemCollection(
      collectionItems?.productonquickshipSet?.edges[0]?.node
    );
    
    return () => {
      setSelectedItemCollection(null);
    };
  }, [collectionItems]);

  useEffect(() => {
    if (collectionData) {
      const initialCollection = collectionData.find(collection => collection.node.id === id);
      if (initialCollection) {
        setSelectedCollection({
          value: initialCollection.node.id,
          label: initialCollection.node.name,
        });
      }
    }
  }, [collectionData, id]);

  useEffect(() => {
    setQuantity(1); // Setea el contenedor a 1 si selectedItemCollection cambia
  }, [selectedItemCollection]);

  
  // Función para manejar los cambios en los filtros
  const handleFilterChange = (selectedOption, { name }) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };


  const handleCollectionChange = (selectedOption) => {
    setSelectedCollection(selectedOption);
    history.push(`/${language}${instance ? `-${instance}` : ''}/productos/quickship/${selectedOption.value}`);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const incrementQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity - 1));
  };

  const handleAddSample = () => {
    // Lógica para manejar el evento de agregar muestra
    console.log(`Adding ${quantity} samples`);
  };
  const DATA_FILTER_EXAMPLE_CHARACTERISTICS = [
    { id: 'default', name: 'Characteristics', value: '' },
    { id: 'Filter1', name: 'Filter 1', value: 'Filter1' },
    { id: 'Filter2', name: 'Filter 2', value: 'Filter2' },
    { id: 'Filter3', name: 'Filter 3', value: 'Filter3' },
    { id: 'Filter4', name: 'Filter 4', value: 'Filter4' },
    { id: 'Filter5', name: 'Filter 5', value: 'Filter5' },
  ];


  const groupedSectors = filtersByCollection?.sectors?.reduce((groups, sector) => {
    const group = groups.find(g => g.label === sector.market);
  
    if (group) {
      group.options.push({ 
        value: sector.id, 
        labelEs: sector.name, 
        labelEn: sector.nameEn 
      });
    } else {
      groups.push({
        label: sector.market,
        options: [{ 
          value: sector.id, 
          labelEs: sector.name, 
          labelEn: sector.nameEn 
        }]
      });
    }
  
    return groups;
  }, []);


  return (
    <div className='main-page-slug-quickship position-relative pb-5'>
      <ScrollTopOnMount />
      <HeaderQuickShip />

      <div className='main-container-content-page-quickship-collection'>
        <div className='container-row-page-quickship-collection'>
          <Link
            to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}`}
            className={'btn-go-back-quickship'}
          >
            {
              isDesktop ? 
              <img src={leftArrowQuickshipPage} alt="left arrow" /> 
              :
              <img src={mobileLeftArrowQuickshipPage} alt="left arrow" /> 
            }
            {language === 'es' ? 'Regresar': 'Go back'}
          </Link>
          <div className='Container-selects-quickship-page'>
            <Select
              name='market'
              options={groupedSectors?.map(group => ({
                ...group,
                options: group?.options?.map(option => ({
                  value: option?.value,
                  label: language === 'es' ? option?.labelEs : option?.labelEn,
                }))
              }))}
              onChange={handleFilterChange}
              placeholder='Markets'
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              menuPortalTarget={document.body}
            />

            <Select
              name='design'
              options={filtersByCollection?.designs?.map((filter) => ({
                value: filter.value,
                label: capitalizeWords(filter.name),
              }))}
              onChange={handleFilterChange}
              placeholder='Desing'
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              menuPortalTarget={document.body}
            />

            <Select
              name='characteristics'
              options={DATA_FILTER_EXAMPLE_CHARACTERISTICS?.map((filter) => ({
                value: filter?.value,
                label: filter?.name,
              }))}
              onChange={handleFilterChange}
              placeholder='Characteristics'
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              // isSearchable={false}
              menuPortalTarget={document.body}
            />
          </div>
        </div>

        <div className='container-row-selector-collection-quiickship-page'>
          <p className='text-selector-color-quickship-page'>
            {language === 'es' ? 'Colores': 'Colors'}
          </p>
          <Select
            name='selectCollection'
            value={selectedCollection}
            options={collectionData?.map((filter) => ({
              value: filter?.node?.id,
              label: filter?.node?.name,
            }))}
            onChange={handleCollectionChange}
            placeholder={language === 'es' ? 'Colección' : 'Collection'}
            isSearchable={false}
            styles={customStylesSelctCollectionQuickShipPage}
          />
        </div>

        <div className='container-row-quickhip-selector row g-4 mt-4'>
          <div className='col-12 col-lg-5 d-xl-flex justify-content-xl-end'>
            <div className='container-master-sample'>
              <div className='position-relative'>
                {isDesktop && (
                  <div className='container-search-icon p-4'>
                    <div className='bg-light rounded-circle'>
                      <FaSearch className='p-2' color='gray' />
                    </div>
                  </div>
                )}
                {isDesktop ? (
                  <GlassMagnifier
                    className='large-img--masiala'
                    imageSrc={
                      selectedItemCollection?.product?.imageForProduction ||
                      'https://valenciabiosense.spradling.eu/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FVALENCIA%20PURE-WHITE_107-9607.3d59c194.jpg&w=828&q=75'
                    }
                    imageAlt='sample-master-image'
                    magnifierBorderColor='white'
                    magnifierBackgroundColor='white'
                    magnifierBorderSize={2}
                    magnifierOffsetX={0}
                    magnifierOffsetY={0}
                    magnifierSize={150}
                  />
                ) : (
                  <img
                    className='large-img--masiala'
                    src={
                      selectedItemCollection?.product?.imageForProduction ||
                      'https://valenciabiosense.spradling.eu/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FVALENCIA%20PURE-WHITE_107-9607.3d59c194.jpg&w=828&q=75'
                    }
                    alt='sample-master-image'
                  />
                )}
                <p className='sample-detail-selected--masiala m-0'>
                  {selectedItemCollection !== undefined &&
                    `${selectedItemCollection?.product?.color} | ${selectedItemCollection?.product?.stock}`}
                </p>
              </div>
            </div>
          </div>
          <div className='container-sample-list-and-quantity col-12 col-lg-7 d-xl-flex justify-content-xl-end m-0'>
            <SamplesList
              samplesDestkop={filteredProducts}
              samplesMobile={filteredProducts}
            />
            <div className='quantity-selector-quickship-page'>
              <div className='container-quantity'>
                <button onClick={decrementQuantity} className='quantity-btn-decrement'>
                  <img src={downArrowSelectorQuickship} alt="add" />
                </button>
                <input 
                  type='number' 
                  value={quantity} 
                  onChange={handleQuantityChange} 
                  min='1' 
                  className='quantity-input'
                />
                <button onClick={incrementQuantity} className='quantity-btn-increment'>
                  <img src={upArrowSelectorQuickship} alt="add" />
                </button>
              </div>
              <button className='btn-add-sample' onClick={handleAddSample}>
                {language === 'es' ? 'Añadir muestra': 'Add MEMO Sample'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickshipPageParamSlug;
