import gql from "graphql-tag";

const servicesAndPatternsQuery = gql`
  query ServicesAndPatterns($instanceIds: [ID]) {
    patterns(products_Instances: $instanceIds) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    services {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export default servicesAndPatternsQuery;
