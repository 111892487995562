import { connect } from "react-redux";
import HeaderCartButton from "../index";
import { setCartId, clearCartId } from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    cartId: state.headerReducer.cartId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartId: (cartId) => dispatch(setCartId(cartId)),
    clearCartId: () => dispatch(clearCartId()),
  };
};

const HeaderCartButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCartButton);

export default HeaderCartButtonContainer;
