import { FormattedMessage } from "react-intl";
import DotsMobileStepper from "./Carousel";

export const EnvolvingSecction = () => {
  return (
    <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
      <div className="container-fluid pl-md-5 pl-lg-5 mb-md-2 mb-xl-5">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-10 col-md-10 col-sm-10">
            <p className="text-td-ads mb-5 mt-5">
              <span className="evolving-title">
                <FormattedMessage
                  id="EcosensePage.v2.Evolving.title"
                  defaultMessage="Evolving our processes"
                />
              </span>
            </p>
            <p className="evolving-content">
              <FormattedMessage
                id="EcosensePage.v2.Evolving.description"
                defaultMessage=" Through years of dedicated investment in research and cutting-edge
                  technology, we've pioneered the shift towards a greener future for
                  our industry. From advanced energy-efficient processes to
                  wastewater treatment, our commitment to sustainability is
                  reflected in every aspect of our operations."
              />
            </p>
          </div>
        </div>
      </div>
      <DotsMobileStepper />
    </div>
  );
};
