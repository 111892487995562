import PropTypes from "prop-types";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-apollo";
import companyTypesQuery from "./query";
import { getCurrentInstanceId } from "services/instances";

const CompanyTypeField = ({ className = "" }) => {
  const { loading, error, data } = useQuery(companyTypesQuery,{
    variables: {
      instanceIds: [getCurrentInstanceId()],
    },
  });

  if (error) return null;

  return (
    <Field
      as="select"
      id="companyType"
      name="companyType"
      className={`form-control ${className}`}
    >
      {loading ? (
        <FormattedMessage
          id="CompanyTypeField.loading"
          description="Loading label for CompanyType field"
          defaultMessage="Cargando tipos de compañia..."
        >
          {(label) => (
            <option value="" disabled>
              {label}
            </option>
          )}
        </FormattedMessage>
      ) : (
        <>
          <FormattedMessage
            id="CompanyTypeField.companyTypeDefault"
            description="Default of company type field of the Contact box form"
            defaultMessage="Seleccione tipo de compañia"
          >
            {(label) => <option value="">{label}</option>}
          </FormattedMessage>
          {data.companyTypes.edges.map(({ node: { id, name } }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </>
      )}
    </Field>
  );
};

CompanyTypeField.propTypes = {
  className: PropTypes.string,
};

export default CompanyTypeField;
