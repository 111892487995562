import gql from 'graphql-tag'

const checkOutCartMutation = gql`
  mutation CheckOutCart($input: CheckOutCartInput!) {
    checkOutCart(input: $input) {
      success
    }
  }
`

export default checkOutCartMutation