import gql from 'graphql-tag';

const pageInfoFragment = gql`
    fragment PageInfoFragment on PageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
    }
`;

export default pageInfoFragment;