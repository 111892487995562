import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { useMutation } from "@apollo/react-hooks";
import { createDownloadLog } from "modules/products/ProductDetailsPage/components/ProductSidebar/mutations";
import {
  isUSA,
  isMARINE,
  isRESOURCES,
  getCurrentInstanceId,
  isMEXICO,
} from "services/instances";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import CompanyTypeField from "modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField";
import SectorFieldToUSA from "./components/SectorFieldToUSA";
import SectorsField from "./components/SectorsField";
import { handleCountryIdForMX } from "modules/contact/ContactUsPage/components/ContactBox/constants/handleCountryIdForMX";
import RegionField from "./components/RegionField";
import CityField from "./components/CityField";
import "./styles/CreateDownloadLogForm.scss";

export default function CreateDownloadLogForm({
  id,
  patternId,
  resourceType,
  resourceUrl,
  onCompleted,
}) {
  const intl = useIntl();
  const isInstanceOfUsa = isUSA() || isMARINE() || isRESOURCES();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [logDownload, { loading }] = useMutation(createDownloadLog, {
    onCompleted() {
      onCompleted();
    },
    onError(error) {
      console.warn(error);
    },
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    companyType: "",
    countryId: "",
    regionId: "",
    cityId: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.required))
      .email(intl.formatMessage(validationMessages.email)),
    sector: Yup.string().when("isInstanceOfUsa", {
      is: false,
      then: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      otherwise: Yup.string(),
    }),
    marketWebUsa: Yup.string().when("isInstanceOfUsa", {
      is: true,
      then: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      otherwise: Yup.string(),
    }),
    companyType: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    regionId: Yup.string().when("isMEXICO()", {
      is: true,
      then: Yup.string().required(validationMessages.required),
      otherwise: Yup.string(),
    }),
    cityId: Yup.string().when("isMEXICO()", {
      is: true,
      then: Yup.string().required(validationMessages.required),
      otherwise: Yup.string(),
    }),
  });

  /**
   * Handles form submit
   * @param values {Object}
   */
  function onSubmit(values) {
    delete values.privacyPolicy;
    delete values.regionId;
    delete values.countryId;

    const input = {
      ...values,
      instance: getCurrentInstanceId(),
      patternId,
      resourceType,
      resourceUrl,
    };

    logDownload({
      variables: { input },
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values: { countryId, regionId } }) => (
        <Form id={`download-resource-form ${id}`} noValidate>
          <div className="form-group">
            <label htmlFor="firstName">
              <FormattedMessage
                id="CreateContactForm.firstNameLabel"
                defaultMessage="Nombre"
              />
              *
            </label>
            <Field
              type="text"
              name="firstName"
              id="firstName"
              className="form-control"
            />
            <FormErrorMessage name="firstName" />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">
              <FormattedMessage
                id="CreateContactForm.lastNameLabel"
                defaultMessage="Apellido"
              />
              *
            </label>
            <Field
              type="text"
              name="lastName"
              id="lastName"
              className="form-control"
            />
            <FormErrorMessage name="lastName" />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              <FormattedMessage
                id="CreateContactForm.emailLabel"
                defaultMessage="Correo electrónico"
              />
              *
            </label>
            <Field
              type="email"
              name="email"
              id="email"
              className="form-control"
            />
            <FormErrorMessage name="email" />
          </div>
          <div className="form-group">
            {!isInstanceOfUsa ? (
              <>
                <label htmlFor="sector">
                  <FormattedMessage
                    id="ContactBoxForm.sectorField"
                    description="Sector field of the Contact form"
                    defaultMessage="Sector"
                  />
                  *
                </label>
                <SectorsField />
                <FormErrorMessage name="sector" />
              </>
            ) : (
              <>
                <label htmlFor="marketWebUsa">
                  <FormattedMessage
                    id="ContactBoxForm.sectorField"
                    description="Sector field of the Contact form"
                    defaultMessage="Sector"
                  />
                  *
                </label>
                <SectorFieldToUSA name="marketWebUsa" />
                <FormErrorMessage name="marketWebUsa" />
              </>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="companyType">
              <FormattedMessage
                id="CreateContactForm.companyType"
                defaultMessage="Tipo de compañía"
              />
              *
            </label>
            <CompanyTypeField />
            <FormErrorMessage name="companyType" />
          </div>
          {isMEXICO() && (
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="regionId">
                    <FormattedMessage
                      id="SignUpForm.stateId"
                      description="State field of the Contact form"
                      defaultMessage="Estado"
                    />
                    *
                  </label>
                  <RegionField
                    className="form-control"
                    countryId={handleCountryIdForMX(countryId)}
                    isCustomStyles
                  />
                  <FormErrorMessage color="black" name="regionId" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="cityId">
                    <FormattedMessage
                      id="ContactBoxForm.cityFieldMx"
                      description="City field of the Contact form"
                      defaultMessage="Delegación"
                    />
                    *
                  </label>
                  <CityField
                    className="form-control"
                    regionId={regionId}
                    isCustomStyles
                  />
                  <FormErrorMessage color="white" name="cityId" />
                </div>
              </div>
            </div>
          )}
          <div className="text-center">
            <PrivacyPolicyCheckbox onChange={setIsPrivacyPolicyAccepted} />
            <button
              disabled={loading || !isPrivacyPolicyAccepted}
              type="submit"
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
            >
              {loading ? (
                <FormattedMessage
                  id="CreateContactForm.submitButtonLoading"
                  defaultMessage="Enviando..."
                />
              ) : (
                <FormattedMessage
                  id="CreateContactForm.submitButton"
                  defaultMessage="Enviar"
                />
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

CreateDownloadLogForm.propTypes = {
  id: PropTypes.string,
  patternId: PropTypes.string,
  resourceType: PropTypes.string,
  resourceUrl: PropTypes.string,
  onCompleted: PropTypes.func,
};
