import { useQuery } from 'react-apollo';
import { collectionsQuery } from '../../queries';
import { Link, useLocation } from "react-router-dom";
import "./styles/HomeProductSection.scss";
import downArrowWhiteQuickshipPage from './img/down-arrow-white-quickship-page.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { getCurrentLanguageAndInstanceCode } from 'services/instances';


const HomeProductsSection = () => {
  const { language } = useParams();

  const location = useLocation();
  const currentPath = location?.pathname;
  const { data } = useQuery(collectionsQuery)
  
  return (
    <div className='main-container-products-home-quickship-page'>
      <div className='home--products-container d-flex flex-wrap border border-dark p-0' id="section1">
        {/* {HOME_PRODUCTS_SECTION?.map(({ title, img }) => { */}
        {data?.quickshipCollections?.edges?.map(({ node }) => {

        const slugNameCollection = node?.name?.replaceAll(" ","-")

          return (
            <div key={node.id} id={slugNameCollection} className='home--products-container-product p-0'>
              <img src={node.imageThumb} alt={node.name} />
              <section className='text-center px-2'>
                <h1>{node.name}</h1>
                <Link
                  // to={`/${lang}${instance ? `-${instance}` : ''}/productos/quickship/${node?.id}`}
                  to={`${currentPath}/${node?.id}`}
                  className='btn--Products-home-quickship'>
                  
                  {language === 'es' ? 'Ver productos relacionados': 'View related products'}

                </Link>
              </section>
              <div className='home--products-container-arrow-down animationImgArrowDown'>
                <img src={downArrowWhiteQuickshipPage} alt="icon-down-arrow-white-quickship-page" />
              </div>
            </div>
          )
        }

        )}
      </div>
    </div>
  )
}

export default HomeProductsSection;