export const INSTANCE_USA = "USA";
export const INSTANCE_EUROPE = "EUROPE";
export const INSTANCE_MEXICO = "MEXICO";
export const INSTANCE_LATAM = "LATAM";
export const INSTANCE_MARINE = "MARINE";
export const INSTANCE_RESOURCES = "RESOURCES";

export const INSTANCES = [
  INSTANCE_USA,
  INSTANCE_EUROPE,
  INSTANCE_MEXICO,
  INSTANCE_LATAM,
  INSTANCE_MARINE,
  INSTANCE_RESOURCES,
];
