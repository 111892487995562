import classNames from "classnames";
import PropTypes from "prop-types";

export const SimpleImage = ({ 
  characteristicImage,
  imgClassName
}) => {
  const { src, imgWidth, imgHeight } = characteristicImage;

  return (
    <>
      <div
        className={classNames({
          "individual-image--container overflow-hidden": true,
          "mb-4": !characteristicImage.imageName,
          "mb-2": characteristicImage.imageName
          })}>
        <img
          src={src}
          className={imgClassName ?? ''}
          alt="characteristic-image"
          width={`${imgWidth}%`}
          height={`${imgHeight}%`}
        />
      </div>
      {characteristicImage.imageName && <p className="text-center mt-0 pt-0">{characteristicImage.imageName}</p>}
    </>
  )
}

SimpleImage.propTypes = {
  characteristicImage: PropTypes.object,
  imgClassName: PropTypes.string
}

export default SimpleImage;