import PropTypes from "prop-types";
import { useState } from "react";
import OrderDetails from "./components/OrderDetails";
import { FormattedMessage } from "react-intl";
import useGetOrdersHistory from "./hooks/useGetOrdersHistory";
import { formatedDate } from "./constants/formatedDate";
import { getInformationOfReferences } from "./constants/getInformationOfReferences";
import { getCurrentLanguageAndInstanceCode } from "services/instances";
import InfiniteScroll from "react-infinite-scroller";
import SeverErrorMessage from "utils/components/ServerErrorMessage";
import NoResultsMessage from "utils/components/NoResultsMessage";
import OrderListCard from "./components/OrderListCard";
import "./styles/UserOrdersHistory.scss";

const UserOrdersHistory = ({ userInformation }) => {
  const [lang] = getCurrentLanguageAndInstanceCode();

  const [resultDate, setResultDate] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [patterns, setPatterns] = useState(null);

  const { data, loading, error, hasMore, getMoreData } =
    useGetOrdersHistory(userInformation);

  const handleClickOnShowDetailsOfTheOrder = (patterns, date, orderId) => {
    window.scrollTo(0, 0);
    setShowOrderDetails(true);
    setPatterns(patterns);
    setResultDate(date);
    setOrderId(orderId);
  };

  const handleGoToBack = () => {
    setShowOrderDetails(false);
  };

  if (error) return <SeverErrorMessage />;

  if (!loading && data?.orders.edges.length === 0) return <NoResultsMessage />;

  return (
    <>
      {!showOrderDetails && (
        <InfiniteScroll hasMore={hasMore} loadMore={getMoreData} threshold={50}>
          <div className="d-flex justify-content-between flex-wrap p-0 pt-5 row small-gutters regular-gutters-md">
            {loading && (
              <h3>
                <FormattedMessage id="DetailedStoreBox.loading" />
              </h3>
            )}
            {data?.orders.edges.length !== 0 &&
              data?.orders.edges.map((edge, index) => {
                const updatedDate = formatedDate(edge, lang);
                const { referenceName, referenceImage, itHasCatalogs } =
                  getInformationOfReferences(edge);

                const articlesNumber = edge.node.items.edges.length;
                const patterns = edge.node.items.edges;
                const orderId = edge.node.id;

                const decodedOrderId = window.atob(orderId);
                const resultOrderId = decodedOrderId.substring(10, orderId.length);

                const orderInformation = {
                  articlesNumber,
                  resultOrderId,
                  itHasCatalogs,
                  patterns,
                  referenceName,
                  referenceImage,
                  updatedDate,
                  handleClickOnShowDetailsOfTheOrder,
                };

                return (
                  articlesNumber !== 0 && (
                    <OrderListCard
                      key={`${index}_${resultOrderId}`}
                      orderInformation={orderInformation}
                    />
                  )
                );
              })}
          </div>
        </InfiniteScroll>
      )}
      {showOrderDetails && (
        <OrderDetails
          patternsToShow={patterns}
          handleGoBack={handleGoToBack}
          dateUpdated={resultDate}
          orderId={orderId}
        />
      )}
    </>
  );
};

UserOrdersHistory.propTypes = {
  userInformation: PropTypes.object,
};

export default UserOrdersHistory;
