import PropTypes from "prop-types";
import "./styles/brand-cell.scss";

const BrandCell = ({ title, logo }) => (
  <>{logo ? <img className="brand-logo" src={logo} alt="brand" /> : title}</>
);

BrandCell.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default BrandCell;
