
export const PROSEAT_CHARACTERTISTICS = [
  {
    key: "characteristic_1",
    characteristicNumber: 1,
    title: "Foam Lamination with Film"
  },
  {
    key: "characteristic_2",
    characteristicNumber: 2,
    title: "Clear Seat Film"
  },
  {
    key: "characteristic_3",
    characteristicNumber: 3,
    title: "Antimicrobial Biocide Comfort Foam"
  },
  {
    key: "characteristic_4",
    characteristicNumber: 4,
    title: "Antimicrobial Biocide Reticulated Foam"
  },
  {
    key: "characteristic_5",
    characteristicNumber: 5,
    title: "3D Ventilation Panel"
  },
  {
    key: "characteristic_6",
    characteristicNumber: 6,
    title: "Marine Board or Rotocast Plastic"
  },
  {
    key: "characteristic_7",
    characteristicNumber: 7,
    title: "Ventilation Fabric"
  },
  {
    key: "characteristic_8",
    characteristicNumber: 8,
    title: "Side Cushion Ventilation"
  },
  {
    key: "characteristic_9",
    characteristicNumber: 9,
    title: "Marine Quality Vinyl"
  },
  {
    key: "characteristic_10",
    characteristicNumber: 10,
    title: "UV Stable Thread" 
  },
  {
    key: "characteristic_11",
    characteristicNumber: 11,
    title: "Dielectric Logo"
  },
  {
    key: "characteristic_12",
    characteristicNumber: 12,
    title: "Dielectric Quilting Patterns"
  },
]