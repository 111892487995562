import PropTypes from "prop-types";
import {Query} from "react-apollo";
import relatedPostsQuery from "modules/blog/PostDetails/components/RelatedPosts/query";
import TitleUnderlined from "utils/components/TitleUnderlined";
import LoadingMessage from "utils/components/LoadingMessage";
import {FormattedMessage} from "react-intl";
import BlogPostCard from "modules/blog/PostsList/components/BlogPostCard";
import {COMMON_CARD_TYPE} from "modules/blog/PostsList/components/BlogPostCard/constants";
import {BLOG_DETAILS} from "routes";
import {getTranslatedLink} from "services/instances"

export default function RelatedPosts({postId}) {
  return (
      <div className="related-posts">
        <Query
            query={relatedPostsQuery}
            variables={{
              postId,
              first: 3
            }}>
          {({data}) => {

            if (data) {
              const {posts} = data;

              return (
                  <>
                    {
                      posts.edges.length > 0 &&
                      <TitleUnderlined
                          align="center"
                          color="black"
                          className="text-size-h3 text-size-sm-h2 mt-5">
                        <FormattedMessage
                            id="PostDetails.relatedPosts"
                            description="Related posts title in single post page"
                            defaultMessage="Noticias relacionadas"/>
                      </TitleUnderlined>
                    }
                    <div className="row">
                      {
                        posts.edges.map((post, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4">
                              <BlogPostCard
                                  post={{
                                    id: post.node.id,
                                    title: post.node.title,
                                    summary: post.node.summary,
                                    shortDescription: post.node.summary,
                                    img: post.node.listingImageThumbSmall,
                                    instanceTag: post.node.instances.edges[0].node.name,
                                    layoutType: COMMON_CARD_TYPE,
                                    link: getTranslatedLink(BLOG_DETAILS, {postSlug: post.node.slug})
                                  }}/>
                            </div>
                        ))
                      }
                    </div>
                  </>
              );
            }

            return <LoadingMessage/>
          }}
        </Query>
      </div>
  )
}

RelatedPosts.propTypes = {
  postId: PropTypes.string
};