import { useState } from "react";
import SpecsTableAccordion from "utils/components/SpecsTableAccordion";
import {FormattedMessage} from "react-intl";
import DisclaimerText from "utils/components/DisclaimerText";

export default function DisclaimerAccordion() {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleClick() {
    setIsOpen(!isOpen);
  }

  return (
      <SpecsTableAccordion
          title={
            <FormattedMessage
                id="DisclaimerAccordion.title"
                description="Disclaimer accordion title"
                defaultMessage="Términos y condiciones"/>
          }
          isOpen={isOpen}
          onClickToggle={handleToggleClick}>
        <tr>
          <td>
            <DisclaimerText
                text={
                  <FormattedMessage
                      id="DisclaimerAccordion.disclaimerV1"
                      description="Disclaimer accordion text"
                      defaultMessage={
                        "<span>Si se someten a ensayos con los materiales adecuados, nuestros productos pueden cumplir el Boletín Técnico 133 de California y el Código contra Incendios de Boston (ensayo BFD IX-1).</span>" +
                        "<span>Ciertas prendas de vestir y tintes de accesorios (como los utilizados en los pantalones vaqueros) pueden migrar a superficies más claras. Este fenómeno se ve incrementado por los efectos de la humedad y la temperatura; es irreversible.</span>" +
                        "<span>Spradling Group Inc. no asumirán ninguna responsabilidad ante transferencias de color debidas a contaminantes externos.</span>"
                      }
                      values={{
                        span: (...chunks) => <span>{chunks}</span>
                      }}/>
                }/>
          </td>
        </tr>
      </SpecsTableAccordion>
  );
}