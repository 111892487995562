import { FormattedMessage, useIntl } from "react-intl";
import CheckboxFilter from "modules/products/ProductListPage/components/FiltersBar/components/CheckboxFilter";
import FiltersBox from "modules/products/ProductListPage/components/FiltersBar/components/FiltersBox";
import { OPTIONS } from "./constants";
import { getCurrentInstanceId } from "services/instances";

export default function CharacteristicsFilter() {
  const intl = useIntl();

  return (
    <FiltersBox
      title={
        <FormattedMessage
          id="CharacteristicsFilter.title"
          description="Title for characteristics filter section at filters bar"
          defaultMessage="Características"
        />
      }
    >
      <CheckboxFilter
        optGroups={[
          {
            title: null,
            options: OPTIONS(intl)[getCurrentInstanceId()],
          },
        ]}
      />
    </FiltersBox>
  );
}
