import PropTypes from "prop-types";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/react-hooks";
import classNames from "classnames/bind";
import resetPasswordMutation from "modules/auth/scenes/ResetPassword/components/ResetPasswordForm/mutation";
import { Link, useRouteMatch } from "react-router-dom";
import { AUTH_FORGOT_PASSWORD } from "routes";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { isMARINE, getTranslatedLink } from "services/instances";

export default function ResetPasswordForm({ toggleAuthModal }) {
  const intl = useIntl();
  const match = useRouteMatch();
  const [resetPassword, { loading, error, data }] = useMutation(
    resetPasswordMutation,
    {
      onError(error) {
        console.log(error);
      },
    }
  );

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    passwordConfirmation: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
  });

  /**
   * Handles form submit
   * @param values {Object}
   * @param setErrors {Function}
   */
  function onSubmit(values, { setErrors }) {
    if (values.password === values.passwordConfirmation)
      resetPassword({
        variables: {
          input: {
            uid: match.params.uid,
            token: match.params.token,
            newPassword: values.password,
          },
        },
      });
    else
      setErrors({
        password: intl.formatMessage(validationMessages.passwordMismatch),
      });
  }

  if (error)
    return (
      <div>
        <p>
          <FormattedMessage
            id="ResetPasswordForm.errorMessage"
            defaultMessage="Ocurrió un error al cambiar la contraseña: parece que el enlace ya expiró."
          />
        </p>
        <div className="text-center">
          <Link
            to={getTranslatedLink(AUTH_FORGOT_PASSWORD)}
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE(),
            })}
          >
            <FormattedMessage
              id="ResetPasswordForm.tryAgainButton"
              defaultMessage="Generar nuevo enlace"
            />
          </Link>
        </div>
      </div>
    );

  if (data && data.resetPassword)
    return (
      <div>
        <p>
          <FormattedMessage
            id="ResetPasswordForm.successMessage"
            defaultMessage="¡Tu contraseña fue actualizada con éxito!"
          />
        </p>
        <div className="text-center">
          <button
            type="button"
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE(),
            })}
            onClick={toggleAuthModal}
          >
            <FormattedMessage
              id="ResetPasswordForm.loginButton"
              defaultMessage="Ingresa a tu cuenta"
            />
          </button>
        </div>
      </div>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form id="reset-password-form" className="form" noValidate>
          <div className="form-group">
            <label htmlFor="password">
              <FormattedMessage
                id="ResetPasswordForm.passwordLabel"
                defaultMessage="Contraseña"
              />
              *
            </label>
            <Field
              type="password"
              name="password"
              id="password"
              className="form-control"
            />
            <FormErrorMessage name="password" />
          </div>
          <div className="form-group">
            <label htmlFor="passwordConfirmation">
              <FormattedMessage
                id="ResetPasswordForm.passwordConfirmationLabel"
                defaultMessage="Confirmar contraseña"
              />
              *
            </label>
            <Field
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              className="form-control"
            />
            <FormErrorMessage name="passwordConfirmation" />
          </div>
          <div className="text-center">
            <button
              disabled={loading}
              type="submit"
              className={classNames({
                "btn btn-dark": true,
                "btn-prussian-blue": isMARINE(),
              })}
            >
              {loading ? (
                <FormattedMessage
                  id="ResetPasswordForm.submitButtonLoading"
                  defaultMessage="Estableciendo..."
                />
              ) : (
                <FormattedMessage
                  id="ResetPasswordForm.submitButton"
                  defaultMessage="Establecer contraseña"
                />
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  toggleAuthModal: PropTypes.func,
};
