import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/components/LocationsListHeader/styles/LocationsListHeader.scss";
import { useQuery } from "@apollo/react-hooks";
import {
  patternNameQuery,
  cityNameQuery,
} from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/components/LocationsListHeader/query";
import Skeleton from "react-loading-skeleton";
import { handleProductAltName } from "utils/commons/products";

const LocationsListHeader = ({ patternId, cityId, onClickBack }) => {
  const query = !patternId ? cityNameQuery : patternNameQuery;
  const variables = !patternId ? { id: cityId } : { id: patternId };

  const { loading, data } = useQuery(query, { variables });

  return (
    <div className="locations-list-header">
      <div className="row">
        <div className="col-4 align-self-center">
          <p className="mb-0" onClick={onClickBack}>
            <i className="fa fa-angle-left" />
            &nbsp;
            <FormattedMessage
              id="LocationsListHeader.goBackButton"
              description="Locations list header go back action"
              defaultMessage="Cambiar de ubicación"
            />
          </p>
        </div>
        <div className="col-8 text-right">
          <p className="mb-0">
            {patternId ? (
              <>
                {!loading ? (
                  <FormattedMessage
                    id="LocationsListHeader.product"
                    description="Product word in locations list header"
                    defaultMessage="Producto: {pattern_name}"
                    values={{
                      pattern_name: (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: handleProductAltName(data?.pattern.altName),
                          }}
                        />
                      ),
                      sup: (...chunks) => <sup>{chunks}</sup>,
                    }}
                  />
                ) : (
                  <Skeleton width={100} />
                )}
              </>
            ) : (
              <>
                <FormattedMessage
                  id="LocationsListHeader.city"
                  description="City word in locations list header"
                  defaultMessage="Ciudad"
                />
                <span>
                  : {!loading ? data?.city.name : <Skeleton width={100} />}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

LocationsListHeader.propTypes = {
  patternId: PropTypes.string,
  cityId: PropTypes.string,
  onClickBack: PropTypes.func,
};

export default LocationsListHeader;
