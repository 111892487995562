export const QUERY_PARAM_SECTOR = "sector";
export const QUERY_PARAM_BRAND = "brand";

export const QUERY_PARAM_GROUP = "group";
const PARAM_GROUP_SCHOLAR_SEASON = "temporada-escolar";
const PARAM_GROUP_QUICK_SHIP = "quickship";

export const QUERY_PARAM_GROUPS_VALUES = {
  [PARAM_GROUP_SCHOLAR_SEASON]: {
    title: "Capelladas para temporada escolar",
    slugs: "capellada-cocco,agata,galapago-ultra,halcon,capellada-jaguar",
    filterProductsBySlug: {
      "capellada-cocco": ["Azul", "Negro", "Nieve"], // uva is missing on API
      agata: ["Azul Medio", "Negro", "Nieve"],
      "galapago-ultra": ["Negro", "Nieve"],
      halcon: ["Azul Medio", "Negro", "Nieve"],
      "capellada-jaguar": ["Azul Medio", "Negro", "Nieve"],
    },
  },
  [PARAM_GROUP_QUICK_SHIP]: {
    title: "Quickship",
    description: `
      In today's fast-paced business world, time is crucial. We maintain an inventory of over 500 yards per SKU, ensuring you can fulfill orders promptly.
      <br/>
      For more information, please download the next file <a href="https://api.spradling.group/emailer/asset/en/107/" target="_blank">here</a>
    `,
    slugs:
      "maglia,whisper-neo-permablok-plus,silvertex-usa,patriot-plus,modena-ecosense,nuance-hl,monticello-soft,ez-vinyl",
    filterProductsBySlug: {
      maglia: ["Thunder", "Vanilla", "Aegean"],
      "whisper-neo-permablok-plus": [
        "Charcoal",
        "Black",
        "Bone",
        "Fog",
        "Storm",
        "Elephant",
        "Pewter",
        "Fawn",
        "Antelope",
        "Cashew",
        "Cinnamon",
        "Mahogany",
        "Luggage",
        "Cognac",
        "Cappuccino",
        "Camel",
        "Mallard",
        "Patina",
        "Cerulean",
        "Indigo",
        "Navy",
      ],
      "silvertex-usa": [
        "Carbon",
        "Meteor",
        "Sterling",
        "Plata",
        "Taupe",
        "Bazaar",
        "Acid",
        "Turquoise",
        "Marine Blue",
        "Storm",
        "Lagoon",
        "Jet",
        "Sapphire",
      ],
      "patriot-plus": [
        "Charcoal",
        "Black",
        "Gunmetal",
        "Dove",
        "Mocha",
        "Blue Ridge",
        "Imperial Blue",
        "Royal Blue",
      ],
      "modena-ecosense": ["Black", "Graphite", "Auster", "Ink"],
      "nuance-hl": ["Ebony"],
      "monticello-soft": ["Black"],
      "ez-vinyl": ["Oxen Brown"],
    },
  },
};
