import PropTypes from "prop-types";
import iconFabric from "./img/icon-fabric.svg";
import iconStore from "./img/icon-store.svg";

export default function MethodIcon({icon}) {
  return (
      <img src={icon === "fabric" ? iconFabric : iconStore} alt="Spradling Group"/>
  )
}

MethodIcon.propTypes = {
  icon: PropTypes.oneOf(["fabric", "store"])
}