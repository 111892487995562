import "./styles/EditProfileInputs.scss";
import { Field } from "formik";
import FormErrorMessage from "utils/components/FormErrorMessage";

const EditProfileInputs = () => (
  <div className="bg-white d-flex flex-column">
    <Field
      name="firstName"
      type="text"
      placeholder="first name"
      className="mt-3 rounded border border-dark py-2 px-2"
    />
    <div className="mt-1">
      <FormErrorMessage name="firstName" />
    </div>
    <Field
      name="lastName"
      type="text"
      placeholder="last name"
      className="mt-3 rounded border border-dark py-2 px-2"
    />
    <div className="mt-1">
      <FormErrorMessage name="lastName" />
    </div>
    <Field
      name="phone"
      type="text"
      placeholder="phone"
      className="mt-3 rounded border border-dark py-2 px-2"
    />
    <div className="mt-1">
      <FormErrorMessage name="phone" />
    </div>
  </div>
);

export default EditProfileInputs;
