import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { filtersByCollectionID } from "../queries";

const useGetFiltersByCollectionID = (id) => {
  const { data, loading, error } = useQuery(filtersByCollectionID, {
    variables: { 
      quickshipCollection: id || '' ,
      language: "es"
    },
  });

  const [filtersByCollection, setFiltersByCollection] = useState([]);

  useEffect(() => {
    if (data) {
      setFiltersByCollection(data.filtersByCollection || []);
    }
  }, [data]);

  return { filtersByCollection, loading, error };
};

export default useGetFiltersByCollectionID;
