import {
  QUERY_PARAM_NO_BIOCIDES,
  QUERY_PARAM_FORMALDEHYDE_FREE,
  QUERY_PARAM_NO_FLAME_RETARDANTS,
  QUERY_PARAM_ANTIFUNGAL,
  QUERY_PARAM_PROP_65_COMPLIANT,
  QUERY_PARAM_POLYCARBONATE_RESIN_SYSTEM,
  QUERY_PARAM_ECOSENSE,
  QUERY_PARAM_SOFTSIDE,
  QUERY_PARAM_PROSTOCK,
  QUERY_PARAM_COMPASS_HB,
  QUERY_PARAM_INTAGLIODESIGNS,
  QUERY_PARAM_IPA_CLEANABLE,
  QUERY_PARAM_BLEACH_CLEANABLE,
  QUERY_PARAM_AB_1998_COMPLIANT,
  QUERY_PARAM_DENIM_DYE_RESISTANT,
  QUERY_PARAM_ANTISTATIC,
  QUERY_PARAM_SULFIDE_STAIN_RESISTANT,
  QUERY_PARAM_HYDROLYSIS,
  QUERY_PARAM_MILDEW_RESISTANT,
  QUERY_PARAM_ANTIBACTERIAL,
  QUERY_PARAM_HAS_NOT_HEAVY_METALS,
  QUERY_PARAM_HEAT_SEALABLE,
  QUERY_PARAM_PET_FRIENDLY,
} from "modules/products/ProductListPage/components/FiltersBar/constants/query_params";
import CHARACTERISTICS, {
  NO_BIOCIDES,
  FORMALDEHYDE_FREE,
  NO_FLAME_RETARDANTS,
  ANTIFUNGAL,
  PROP_65_COMPLIANT,
  POLYCARBONATE_RESIN_SYSTEM,
  ECOSENSE,
  SOFTSIDE,
  PROSTOCK,
  COMPASS_HB,
  INTAGLIODESIGNS,
  IPA_CLEANABLE,
  BLEACH_CLEANABLE,
  AB_1998_COMPLIANT,
  DENIM_DYE_RESISTANT,
  ANTISTATIC,
  HEAT_SEALABLE,
  SULFIDE_STAIN_RESISTANT,
  HYDROLYSIS,
  MILDEW_RESISTANT,
  ANTIBACTERIAL,
  HAS_NOT_HEAVY_METALS,
  PET_FRIENDLY,
} from "modules/products/ProductListPage/components/FiltersBar/constants/characteristics";

const OPTIONS_US_EU = (intl) => [
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[NO_BIOCIDES]),
    QUERY_PARAM_NO_BIOCIDES,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[FORMALDEHYDE_FREE]),
    QUERY_PARAM_FORMALDEHYDE_FREE,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[NO_FLAME_RETARDANTS]),
    QUERY_PARAM_NO_FLAME_RETARDANTS,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[ANTIFUNGAL]),
    QUERY_PARAM_ANTIFUNGAL,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[PROP_65_COMPLIANT]),
    QUERY_PARAM_PROP_65_COMPLIANT,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[POLYCARBONATE_RESIN_SYSTEM]),
    QUERY_PARAM_POLYCARBONATE_RESIN_SYSTEM,
  ],
  ["1", intl.formatMessage(CHARACTERISTICS[ECOSENSE]), QUERY_PARAM_ECOSENSE],
  ["1", intl.formatMessage(CHARACTERISTICS[SOFTSIDE]), QUERY_PARAM_SOFTSIDE],
  ["1", intl.formatMessage(CHARACTERISTICS[PROSTOCK]), QUERY_PARAM_PROSTOCK],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[COMPASS_HB]),
    QUERY_PARAM_COMPASS_HB,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[INTAGLIODESIGNS]),
    QUERY_PARAM_INTAGLIODESIGNS,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[IPA_CLEANABLE]),
    QUERY_PARAM_IPA_CLEANABLE,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[BLEACH_CLEANABLE]),
    QUERY_PARAM_BLEACH_CLEANABLE,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[AB_1998_COMPLIANT]),
    QUERY_PARAM_AB_1998_COMPLIANT,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[DENIM_DYE_RESISTANT]),
    QUERY_PARAM_DENIM_DYE_RESISTANT,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[ANTISTATIC]),
    QUERY_PARAM_ANTISTATIC,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[HEAT_SEALABLE]),
    QUERY_PARAM_HEAT_SEALABLE,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[SULFIDE_STAIN_RESISTANT]),
    QUERY_PARAM_SULFIDE_STAIN_RESISTANT,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[HYDROLYSIS]),
    QUERY_PARAM_HYDROLYSIS,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[MILDEW_RESISTANT]),
    QUERY_PARAM_MILDEW_RESISTANT,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[ANTIBACTERIAL]),
    QUERY_PARAM_ANTIBACTERIAL,
  ],
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[HAS_NOT_HEAVY_METALS]),
    QUERY_PARAM_HAS_NOT_HEAVY_METALS,
  ],
];

const OPTIONS_LA_MX = (intl) => [
  [
    "1",
    intl.formatMessage(CHARACTERISTICS[PET_FRIENDLY]),
    QUERY_PARAM_PET_FRIENDLY,
  ],
];

export const OPTIONS = (intl) => ({
  [process.env.REACT_APP_USA_INSTANCE_ID]: OPTIONS_US_EU(intl),
  [process.env.REACT_APP_SM_INSTANCE_ID]: OPTIONS_US_EU(intl),
  [process.env.REACT_APP_RC_INSTANCE_ID]: OPTIONS_US_EU(intl),
  [process.env.REACT_APP_EU_INSTANCE_ID]: OPTIONS_US_EU(intl),
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: OPTIONS_LA_MX(intl),
  [process.env.REACT_APP_MX_INSTANCE_ID]: OPTIONS_LA_MX(intl),
});
