import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FormattedMessage } from "react-intl";
import onClickOutside from "react-onclickoutside";
import { reduxStore } from "redux/store";
import { closeSearchBar } from "components/Header/actions";
import { useHistory } from "react-router-dom";
import { SEARCH_RESULTS } from "routes";
import {
  isMEXICO,
  isMARINE,
  getTranslatedLink,
  isLATAM,
} from "services/instances";
import "./styles/HeaderSearchBar.scss";

function HeaderSearchBar({ headerReducer, closeSearchBar }) {
  const [text, setText] = useState("");
  const history = useHistory();

  /**
   * Handles search form submit event
   * @param {Object} event
   */
  function handleSubmit(event) {
    event.preventDefault();

    history.push({
      pathname: getTranslatedLink(SEARCH_RESULTS, { text }),
    });
    closeSearchBar();
  }

  /**
   * Handle search input change
   * @param event
   */
  function handleInputChange(event) {
    setText(event.target.value);
  }

  if (!headerReducer.searchBarIsOpen) return null;

  return (
    <div
      className={classNames({
        "header-search-bar": true,
        "header-search-bar--no-header-cart": isMEXICO() | isLATAM(),
        "header-search-bar--marine": isMARINE(),
        "header-search-bar--open": headerReducer.searchBarIsOpen,
      })}
    >
      <div className="container">
        <form id="global-search-bar-form" onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <FormattedMessage
              id="HeaderSearchBar.formPlaceholder"
              description="Placeholder text for Header Search Form input"
              defaultMessage="BUSCAR"
            >
              {(placeholder) => (
                <input
                  value={text}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  name="query"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </div>
        </form>
      </div>
    </div>
  );
}

HeaderSearchBar.propTypes = {
  headerReducer: PropTypes.shape({
    searchBarIsOpen: PropTypes.bool,
  }),
  closeSearchBar: PropTypes.func,
};

const clickOutsideConfig = {
  handleClickOutside: () => {
    return () => {
      reduxStore.dispatch(closeSearchBar());
    };
  },
};

export default onClickOutside(HeaderSearchBar, clickOutsideConfig);
