import PropTypes from "prop-types";
import "./styles/ProductCardImage.scss";
import { Link } from "react-router-dom";
import { PRODUCT_DETAILS } from "routes";
import { getTranslatedLink } from "services/instances";
import NewProductLabel from "utils/components/NewProductLabel";
export default function ProductCardImage({
  slug,
  image,
  loading = false,
  isNew = false,
}) {
  return (
    <Link
      to={
        loading
          ? "#"
          : getTranslatedLink(PRODUCT_DETAILS, { productSlug: slug })
      }
    >
      <div className="position-relative">
        {isNew && <NewProductLabel />}
        <div
          className="product-card-image"
          style={{
            backgroundImage: loading ? null : `url("${image}")`,
            backgroundColor: loading ? "#CCCCCC" : null,
          }}
        />
      </div>
    </Link>
  );
}

ProductCardImage.propTypes = {
  loading: PropTypes.bool,
  isNew: PropTypes.bool,
  slug: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
