import * as types from "./types";
import {
  setCartIdLocalStorage,
  removeCartIdLocalStorage,
} from "services/auth/localStorage";

export const openMobileMenu = () => ({
  type: types.MOBILE_MENU_OPEN,
});

export const closeMobileMenu = () => ({
  type: types.MOBILE_MENU_CLOSE,
});

export const openAuthMenu = () => ({
  type: types.AUTH_MENU_OPEN,
});

export const closeAuthMenu = () => ({
  type: types.AUTH_MENU_CLOSE,
});

export const openSearchBar = () => ({
  type: types.SEARCH_BAR_OPEN,
});

export const closeSearchBar = () => ({
  type: types.SEARCH_BAR_CLOSE,
});

export const toggleAuthModal = () => ({
  type: types.AUTH_MODAL_TOGGLE,
});

export const openAuthModal = () => ({
  type: types.AUTH_MODAL_OPEN,
});

export const closeAuthModal = () => ({
  type: types.AUTH_MODAL_CLOSE,
});

export const setAuthModalContext = (context) => ({
  type: types.AUTH_MODAL_CONTEXT_SET,
  context,
});

export const setCartId = (cartId) => {
  setCartIdLocalStorage(cartId);
  return {
    type: types.CART_ID_SET,
    cartId,
  };
};

export const clearCartId = () => {
  removeCartIdLocalStorage();
  return {
    type: types.CART_ID_CLEAR,
    cartId: null,
  };
};
