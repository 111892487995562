import classNames from "classnames/bind";
import { Query } from "react-apollo";
import Skeleton from "react-loading-skeleton";
import HeaderBurgerContainer from "components/Header/components/HeaderBurger/container";
import HeaderMainMenuContainer from "components/Header/components/HeaderMainMenu/container";
import TranslatedLink from "routes/components/TranslatedLink";
import { HOME_PAGE } from "routes";
import HeaderAuthBoxContainer from "components/Header/components/HeaderAuthBox/container";
import HeaderAuthCircleButtonContainer from "components/Header/components/HeaderAuthCircleButton/container";
import AuthModalContainer from "components/AuthModal/container";
import HeaderSearchBarContainer from "components/Header/components/HeaderSearchBar/container";
import HeaderSearchCircleButtonContainer from "components/Header/components/HeaderSearchCircleButton/container";
import HeaderCartButtonContainer from "components/Header/components/HeaderCartButton/container";
import {
  getCurrentInstanceId,
  isLATAM,
  isMEXICO,
  isMARINE,
  isRESOURCES,
} from "services/instances";
import { instanceQuery } from "components/Header/query";
import "./styles/Header.scss";
import HeaderSubInstancesUsa from "./components/HeaderSubInstancesUsa";
import useMediaQuery from "utils/hooks/useMediaQuery";

export default function Header() {
  const isDesktop = useMediaQuery("(min-width: 990px)");
  const queryVariables = { instanceId: getCurrentInstanceId() };
  const hideHeaderCartSection = isLATAM() || isMEXICO() || isMARINE();
  const hideAuthSection = isLATAM() || isMEXICO();

  return (
    <header
      className={classNames({
        "spglobal-header fixed-top": true,
        "spglobal-header--marine": isMARINE(),
        "spglobal-header--resources": isDesktop && isRESOURCES(),
      })}
    >
      {isDesktop && isMARINE() && (
        <HeaderSubInstancesUsa isDesktop={isDesktop} />
      )}
      {!hideHeaderCartSection && <HeaderCartButtonContainer />}
      <div className="container">
        <div className="row no-gutters">
          <div className="col-3 col-lg-9 order-lg-1">
            <HeaderBurgerContainer />
            <HeaderMainMenuContainer />
          </div>
          <div className="col-6 col-lg-2 order-lg-0">
            <div className="header-logo">
              <Query query={instanceQuery} variables={queryVariables}>
                {({ data }) => {
                  if (data) {
                    const {
                      instance: { logo },
                    } = data;

                    return (
                      <TranslatedLink name={HOME_PAGE}>
                        <img
                          className="img-fluid w-75"
                          src={logo}
                          alt="Spradling Group"
                          title="Spradling Group"
                        />
                      </TranslatedLink>
                    );
                  }

                  return <Skeleton height={32} width={100} />;
                }}
              </Query>
            </div>
          </div>
          <div className="header-auth-search-section col-3 col-lg-1 order-lg-2 d-flex align-items-center justify-content-end">
            <HeaderSearchCircleButtonContainer />
            {!hideAuthSection && (
              <>
                <HeaderAuthCircleButtonContainer />
                <HeaderAuthBoxContainer />
              </>
            )}
          </div>
        </div>
      </div>
      <HeaderSearchBarContainer />
      <AuthModalContainer />
    </header>
  );
}
