import gql from "graphql-tag";

const singlePatternZippedImagesQuery = gql`
    query SinglePatternPDFQuery($id: ID!, $instanceId: ID!) {
        pattern (id: $id) {
            id
            zippedImagesUrl(instanceId: $instanceId)
        }
    }
`;

export default singlePatternZippedImagesQuery;