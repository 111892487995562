import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {useMutation} from "@apollo/react-hooks"
import updateItemQuantity
  from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/QuantityInput/mutation"
import {toast} from "react-toastify"

let timeout

export default function QuantityInput({itemId, limit, initialValue}) {
  const [quantity, setQuantity] = useState(initialValue)
  const [updateQuantity] = useMutation(updateItemQuantity, {
    onError(error) {
      error.graphQLErrors.forEach(error => toast.error(error.message))
      decrease()
    }
  })
  const memoizedUpdateQuantity = useCallback(updateQuantity, [updateQuantity])

  /**
   * Keep an eye out on quantity to identify when it changes to call the mutation
   */
  useEffect(() => {
    clearTimeout(timeout)
    if (quantity <= limit){
      if (quantity !== initialValue)
        memoizedUpdateQuantity({
          variables: {
            input: {
              itemId: itemId,
              quantity
            }
          }
        })
    }
    else
      timeout = setTimeout(() => {
        setQuantity(1)
      }, 1500)
  }, [quantity, limit, itemId, initialValue, memoizedUpdateQuantity])

  /**
   * Decrease counter on minus btn click
   */
  function decrease() {
    if (quantity > 1)
      setQuantity(quantity - 1)
  }

  /**
   * Increase counter on plus btn click
   */
  function increase() {
    if (quantity < limit)
      setQuantity(quantity + 1)
  }

  /**
   * Handles change on the input
   * @param event
   */
  function handleInputChange(event) {
    const {value} = event.target
    setQuantity(parseInt(value))
  }

  return (
      <div className="input-group">
        <div className="input-group-prepend" onClick={decrease}>
          <div className="input-group-text">
            <i className="fa fa-minus"/>
          </div>
        </div>
        <input
            type="number"
            id={`quantity${itemId}`}
            name="quantity"
            className="form-control text-center"
            min={1}
            max={limit}
            value={quantity}
            onChange={handleInputChange}/>
        <div className="input-group-append" onClick={increase}>
          <div className="input-group-text">
            <i className="fa fa-plus"/>
          </div>
        </div>
      </div>
  )
}

QuantityInput.propTypes = {
  itemId: PropTypes.string,
  limit: PropTypes.number,
  initialValue: PropTypes.number,
};