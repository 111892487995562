import { useContext } from "react";
import classNames from "classnames/bind";
import "./styles/ProductHeader.scss";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import ReferencesCarousel from "modules/products/ProductDetailsPage/components/ProductHeader/components/ReferencesCarousel";
import Skeleton from "react-loading-skeleton";
import QueryParams from "services/browser-history/QueryParams";
import { QUERY_PARAM_REFERENCE } from "modules/products/ProductDetailsPage/constants";
import dropper from "./img/dropper.svg";
import DisclaimerText from "utils/components/DisclaimerText";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import ProductHeaderActionsContainer from "modules/products/ProductDetailsPage/components/ProductHeader/components/ProductHeaderActions/container";
import {
  WHITE,
  YELLOW,
} from "modules/products/ProductListPage/components/FiltersBar/constants/predominant_colors";
import { isLATAM, isMEXICO } from "services/instances";
import NewProductLabel from "utils/components/NewProductLabel";
import {
  handlePantoneCode,
  handleProductAltName,
} from "utils/commons/products";

export default function ProductHeader() {
  const location = useLocation();
  const pattern = useContext(PatternContext);
  const queryParams = new QueryParams(location.search);
  const hideStockAndPantone = isLATAM() || isMEXICO();
  let hideRequestCatalog = false;
  let hiderequestSample = false;

  let reference = undefined;
  let predominantColorIsLight = undefined;
  if (pattern) {
    const color = queryParams.find(QUERY_PARAM_REFERENCE);
    if (color === undefined) reference = pattern.products.edges[0].node;
    else
      reference = pattern.products.edges.find(
        (product) => product.node.color === color
      ).node;

    if (!pattern.cataloguesAvailable) hideRequestCatalog = true;
    if (!reference.samplesAvailable) hiderequestSample = true;

    predominantColorIsLight = !!reference.predominantColors.find(
      (element) => element === WHITE || element === YELLOW
    );
  }

  return (
    <header className="product-header">
      <div
        className="product-header__top-section"
        style={{
          backgroundImage: reference ? `url(${reference.imageThumbLarge})` : "",
        }}
      >
        <div className="container">
          <div className="row align-items-md-end py-4">
            <div className="col-12 col-md-5 order-md-1 mb-4 mb-sm-0">
              <div
                className={classNames({
                  "product-header-name white text-center text-md-left": true,
                  "charleston-green": predominantColorIsLight,
                })}
              >
                {pattern ? (
                  <>
                    <h1 className="mb-0">
                      {pattern.altName ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: handleProductAltName(pattern.altName),
                          }}
                        />
                      ) : (
                        pattern.name
                      )}
                    </h1>
                    {reference && (
                      <>
                        <p className="mb-0">{reference.color}</p>
                        {!hideStockAndPantone && (
                          <>
                            <p className="mb-0">
                              <small>{reference.stock}</small>
                            </p>
                            <p className="mb-0">
                              {handlePantoneCode({
                                pantoneCode: reference.pantone,
                                classForBox: "product-header__tooltip-box p-3 text-justify gray-light bg-white",
                              })}
                            </p>
                          </>
                        )}
                      </>
                    )}
                    {reference?.isNew && (
                      <div className="position-relative mt-1">
                        <NewProductLabel isNotAbsolute />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h1 className="mb-0">
                      <Skeleton width={140} />
                    </h1>
                    <p className="mb-0">
                      <Skeleton width={80} />
                    </p>
                    <div>
                      <h5 className="mt-3 mb-1 font-weight-bold">
                        <Skeleton width={50} />
                      </h5>
                      <p className="mb-md-0">
                        <Skeleton width={80} />
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-12 col-md-7 order-md-0">
              <ProductHeaderActionsContainer
                selectedReference={reference}
                hideRequestCatalog={hideRequestCatalog}
                hiderequestSample={hiderequestSample}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="product-header__other-references mt-3">
        <div className="container">
          <ReferencesCarousel hideStockAndPantone={hideStockAndPantone} />
        </div>
      </div>
      <div className="container pt-3 pb-3">
        <div className="row no-gutters align-items-md-center">
          <div className="col-auto text-center">
            <img src={dropper} alt="" width={24} className="mr-2 mr-md-4" />
          </div>
          <div className="col">
            <FormattedMessage
              id="ProductHeader.referencesDisclaimer"
              description="References disclaimer in ProductHeader component"
              defaultMessage="Las muestras de color son indicativas y no son un reflejo exacto del color real del producto. Debido a variaciones en los ajustes y balances de color del monitor, los colores solo son representativos y no debe considerarse que corresponden exactamente al color del producto indicado. Le recomendamos que solicite muestras físicas de los materiales antes de realizar su pedido."
            >
              {(disclaimer) => <DisclaimerText text={disclaimer} />}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </header>
  );
}
