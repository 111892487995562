import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
import storesByPatternQuery from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/query";
import { getCurrentInstanceId } from "services/instances";

const i18nMessages = defineMessages({
  placeholderDisabled: {
    id: "LocationSelect.placeholderDisabled",
    defaultMessage: "Primero, selecciona un producto",
  },
  placeholderLoading: {
    id: "LocationSelect.placeholderLoading",
    defaultMessage: "Cargando ubicaciones...",
  },
  placeholderLoaded: {
    id: "LocationSelect.placeholderLoaded",
    defaultMessage: "Selecciona una ubicación",
  },
});

// TODO Fix the first request because is not needed
// I've tried using useLazyQuery but that fucking thing for some reason is causing too many re-renders
// error in the console.
// There are many search results in Google about that
// https://stackoverflow.com/questions/57784602/uselazyquery-causing-too-many-re-renders-apollo-apollo-react-hooks

export default function LocationSelect({ patternId, onChange }) {
  const intl = useIntl();
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState(null);
  const { loading } = useQuery(storesByPatternQuery, {
    variables: {
      patternIds: [patternId],
      instanceId: getCurrentInstanceId(),
      active: true,
    },
    onCompleted(data) {
      let locations = data.stores.edges.map(({ node }) => ({
        label: node.city.name,
        value: node.city.id,
        country: node.city.country.name,
      }));
      // Remove duplicates
      locations = _.uniqBy(locations, (location) => location.value);
      // Sort alphabetically
      locations = _.sortBy(locations, (location) => location.label);
      // Group by country
      const groupedByCountry = _.groupBy(
        locations,
        (location) => location.country
      );
      // Transform into an array as <Select/> expects
      const groupedOptions = Object.keys(groupedByCountry).map((key) => ({
        label: key,
        options: groupedByCountry[key],
      }));
      setLocations(groupedOptions);
    },
  });
  const memoizedOnChange = useCallback(onChange, [onChange]);

  /**
   * Call the provided callback on every change of the value
   */
  useEffect(() => {
    if (selected) memoizedOnChange(selected);
  }, [selected, memoizedOnChange]);

  /**
   * Handles change on the <Select/> component
   * @param option {Object}
   */
  function handleSelectChange(option) {
    setSelected(option.value);
  }

  /**
   * Retrieves the right placeholder for the select input
   * @returns {string}
   */
  function getPlaceholder() {
    let placeholder = i18nMessages.placeholderDisabled;
    if (loading) placeholder = i18nMessages.placeholderLoading;
    else if (locations.length > 0) placeholder = i18nMessages.placeholderLoaded;
    return intl.formatMessage(placeholder);
  }

  return (
    <Select
      isDisabled={!patternId || loading}
      isLoading={loading}
      isClearable={true}
      isSearchable={true}
      name="location"
      options={locations}
      onChange={handleSelectChange}
      placeholder={getPlaceholder()}
    />
  );
}

LocationSelect.propTypes = {
  patternId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
