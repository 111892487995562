import {connect} from "react-redux";
import RequestSamplesBtn from "../index";
import {setAuthModalContext, toggleAuthModal} from "components/Header/actions"

const mapStateToProps = (state) => {
  return {
    cartId: state.headerReducer.cartId,
    isAuthenticated: state.authReducer.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: () => dispatch(toggleAuthModal()),
    setAuthModalContext: context => dispatch(setAuthModalContext(context))
  };
};

const RequestSamplesBtnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestSamplesBtn);

export default RequestSamplesBtnContainer;