const GENERAL_CONTACT_FOR_SAMPLES_REQUEST_MSGS = {
  id: "ProductHeader.contactForResources",
  description:
    "Label fot contact us for SP Resources action button in Product details Page",
  defaultMessage: "Contact Us",
};

export const CONTACT_FOR_SAMPLES_REQUEST_MSGS = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    id: "ProductHeader.contactForSamples",
    description:
      "Label fot contact us for samples action button in Product details Page",
    defaultMessage: "Contáctanos para solicitar catálogo",
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    id: "ProductHeader.contactForSamples.mx",
    description:
      "Label fot contact us for samples action button in Product details Page",
    defaultMessage: "Solicitar más información",
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_REQUEST_MSGS,
  [process.env.REACT_APP_SM_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_REQUEST_MSGS,
  [process.env.REACT_APP_RC_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_REQUEST_MSGS,
  [process.env.REACT_APP_EU_INSTANCE_ID]:
    GENERAL_CONTACT_FOR_SAMPLES_REQUEST_MSGS,
};
