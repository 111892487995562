import { defineMessages } from "react-intl";

export const OFFICES_BUSINESS = {
  LATAM: defineMessages({
    latam: {
      id: "OfficesList.latam",
      description:
        "Latam text in the offices list of Spradling in the world page",
      defaultMessage: "Latinoamérica",
    },
  }).latam,
  EUROPE: defineMessages({
    europe: {
      id: "OfficesList.europe",
      description:
        "Europe text in the offices list of Spradling in the world page",
      defaultMessage: "Europa",
    },
  }).europe,
  MEXICO: defineMessages({
    mexico: {
      id: "OfficesList.mexico",
      description:
        "México text in the offices list of Spradling in the world page",
      defaultMessage: "México",
    },
  }).mexico,
  USA: defineMessages({
    usa: {
      id: "OfficesList.usa",
      description:
        "Norte America text in the offices list of Spradling in the world page",
      defaultMessage: "Norte America",
    },
  }).usa,
};
