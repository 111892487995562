import gql from "graphql-tag";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

const postsQuery = gql`
  query PostsQuery (
    $first: Int,
    $last: Int,
    $afterCursor: String,
    $beforeCursor: String,
    $instances: [ID]!
  ){
    posts (
      first: $first,
      last: $last,
      after: $afterCursor,
      before: $beforeCursor,
      instances: $instances
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          title
          slug
          summary
          listingImageThumbSmall
          instances {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${pageInfoFragment}
`;

export default postsQuery;