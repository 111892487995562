import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/FiltersBox.scss";

const FiltersBox = ({title, children}) => {
  // TODO: create and use custom hook useToggle instead of isOpen
  const [isOpen, setIsOpen] = useState(false)

  function handleHeaderClick() {
    setIsOpen(!isOpen)
  }

  return (
    <div className={classNames({
      "filters-box": true,
      "filters-box--open": isOpen
    })}>
      <div
          className="filters-box__header"
          onClick={handleHeaderClick}>
        <h3>
          {title}
          {
            isOpen ?
              <i className="sp-minus"/>
            :
              <i className="sp-plus"/>
          }
        </h3>
      </div>
      <div className={classNames({
        "filters-box__body": true,
        "filters-box__body--visible": isOpen
      })}>
        <div className="filters-box-content">
          {children}
        </div>
      </div>
    </div>
  )

}

FiltersBox.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default FiltersBox