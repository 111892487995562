import { Query } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import TitleUnderlined from "utils/components/TitleUnderlined";
import brandsQuery from "modules/about/Brands/query";
import { getCurrentInstanceId } from "services/instances";
import Skeleton from "modules/about/Brands/components/Skeleton";
import Brand from "modules/about/Brands/components/Brand";
import { BRANDS_SCENE } from "services/seo/constants/scenes";
import SEO from "services/seo/components/SEO";

const Brands = () => {
  const queryVariables = {
    instanceIds: [getCurrentInstanceId()],
    isVisible: true,
  };

  return (
    <>
      <Query query={brandsQuery} variables={queryVariables}>
        {({ loading, data }) => {
          if (loading) return <Skeleton />;

          if (data && data.brands && data.brands.edges.length) {
            const { brands } = data;
            return (
              <>
                <TitleUnderlined
                  hTag={H2_TAG}
                  className="text-size-h1 text-weight-bold mb-4 pt-4 pt-lg-5 mb-lg-5"
                >
                  <FormattedMessage
                    id="WhyChooseUsPage.Brands"
                    description="Why choose us page 'brands' title"
                    defaultMessage="Nuestras marcas de tapicería"
                  />
                </TitleUnderlined>
                {brands.edges.map(({ node: brand }, index) => {
                  const reversed = index % 2 !== 0;
                  return (
                    <Brand
                      key={`key_${brand.id}`}
                      brand={brand}
                      reversed={reversed}
                    />
                  );
                })}
              </>
            );
          }

          return null;
        }}
      </Query>
      <SEO scene={BRANDS_SCENE} />
    </>
  );
};

export default Brands;
