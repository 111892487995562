import {relatedPatternsQuery} from "modules/products/ProductDetailsPage/queries";
import {getCurrentInstanceId} from "services/instances";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {H4_TAG} from "utils/components/TitleUnderlined/constants";
import {FormattedMessage} from "react-intl";
import ProductCard from "modules/products/ProductListPage/components/ProductList/components/ProductCard";
import NoResultsMessage from "utils/components/NoResultsMessage";
import {useQuery} from "react-apollo";
import {useRouteMatch} from "react-router-dom";

export default function RelatedProducts() {
  const match = useRouteMatch();
  const {loading, error, data} = useQuery(relatedPatternsQuery, {
    variables: {
      slug: match.params.productSlug,
      instanceIds: [getCurrentInstanceId()],
      productFirst: 4,
      saleable: true
    }
  })

  if (loading || error)
    return null;

  return (
      <div className="container">
        <div className="related_patterns">
          <TitleUnderlined
              hTag={H4_TAG}>
            <FormattedMessage
                id="ProductDetailsPage.relatedPatternsTitle"
                description="Title of related product secction"
                defaultMessage="Productos relacionados"/>
          </TitleUnderlined>
          <div className="row small-gutters regular-gutters-md">
            {
              (
                  data.pattern.relatedPatterns.edges.length > 0
                      ?
                      (
                          data.pattern.relatedPatterns.edges.map(item => (
                              <div
                                  key={`pattern${item.node.id}`}
                                  className="col-6 col-xl-3 mb-small-gutter mb-regular-gutter-md">
                                <ProductCard
                                    pattern={item.node}/>
                              </div>
                          ))
                      )
                      :
                      <NoResultsMessage/>
              )
            }
          </div>
        </div>
      </div>
  )
}