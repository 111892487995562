import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Spinner } from "reactstrap";
import useSearchInGlossary from "./hooks/useSearchInGlossary";
import useGenericGlossary from "./hooks/useGenericGlossary";
import useSelectedGlossary from "./hooks/useSelectedGlossary";
import GlossaryWords from "./components/GlossaryWords";
import SeeAllTermsBtn from "./components/SeeAllTermsBtn";
import GlossaryHeader from "./components/GlossaryHeader";
import "./styles/GlossarySection.scss";

const GlossarySection = ({ currentCharacteristic, handleCloseGlossary }) => {
  const [glosssaryAnim, setGlossaryAnim] = useState(true);

  const { genericGlossary } = useGenericGlossary();
  const { 
    selectedGlossary, 
    handleSelectedGlossary,
    defaultGlossaryRelatedWords,
    loadingLookupWords,
  } = useSelectedGlossary(currentCharacteristic, genericGlossary);

  const { 
    wordToSearchInGlossary, 
    handleChangeWordToSearchInGlossary, 
    fetchSearchResults,
    loadingSearchInGlossary
  } = useSearchInGlossary(selectedGlossary, handleSelectedGlossary, defaultGlossaryRelatedWords);
  
  const handleHideGlossary = () => {
    setGlossaryAnim(false);
    
    setTimeout(() => { 
      handleCloseGlossary() 
    }, 300)
  }

  return (
    <div className={classNames({
      "bg-white overflow-hidden pb-3": true,
      "glossary-section--container": glosssaryAnim,
      "glossary-section--container-hide": !glosssaryAnim
    })}>
      <GlossaryHeader 
        handleHideGlossary={handleHideGlossary}
        handleChangeWordToSearchInGlossary={handleChangeWordToSearchInGlossary}
        fetchSearchResults={fetchSearchResults}
        wordToSearchInGlossary={wordToSearchInGlossary}
      />
      <main className={classNames({
        "mx-4 px-3 py-4": true,
        "h-75": selectedGlossary.isRelatedWords,
        "glossary-words-container-height": !selectedGlossary.isRelatedWords
        })}>
        {selectedGlossary.isRelatedWords && 
          <p className="text-center mb-4 font-weight-bold">Related Words</p>}

        {loadingSearchInGlossary|| loadingLookupWords
          ? <div className="text-center pt-5">
              <Spinner className="mx-auto glossary-spinner">Loading...</Spinner> 
            </div>
          : <GlossaryWords selectedGlossary={selectedGlossary} />
        }
      </main>
      <section 
        className={classNames({
          "d-none": !selectedGlossary.isRelatedWords,
          "glossary-section--footer d-flex mx-4 overflow-hidden": selectedGlossary.isRelatedWords
        })}
      >
        {!loadingLookupWords && <SeeAllTermsBtn
              selectedGlossary={selectedGlossary}
              glossary={genericGlossary}
              handleSelectedGlossary={handleSelectedGlossary}
            />
        }
      </section>
    </div>
  )
}

GlossarySection.propTypes = {
  currentCharacteristic: PropTypes.object,
  handleCloseGlossary: PropTypes.func
}

export default GlossarySection;